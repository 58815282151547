import React, { useState, useEffect, useContext } from "react";
import { EditableCommentProps } from "./EditableComment.Interface";
import "./EditableComment.scss";
import TextareaAutosize from "react-textarea-autosize";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";

export const EditableComment = (props: EditableCommentProps) => {
	const timelineContext = useContext(TimelineContext);
	const [newValue, setNewValue] = useState<string>("");
	const [editing, setEditing] = useState<boolean>(() => (props.StartInEditing ? props.StartInEditing : false));

	useEffect(() => {
		setNewValue(props.value);
	}, [props.value]);

	function HandleLabelClick() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.SetEditingComment,
			payload: true,
		});
		setEditing(true);
	}

	function HandleOnFocus() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.SetEditingComment,
			payload: true,
		});
		setEditing(true);
	}

	function HandleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		setNewValue(e.target.value);
	}

	function HandleOnBlur() {
		if (newValue !== props.value) {
			props.setValue(newValue);
		} else {
			timelineContext.dispatch({
				type: TimelineContextDispatchActionType.SetEditingComment,
				payload: false,
			});
		}
		setEditing(false);
	}

	// Click label to turn it into text box, which allows you to edit the label text. Cancel button returns it to original value.
	// NOTE: This is not at all accessible... + (editing ? " hidden" : "")
	return (
		<section className={`comment-wrapper ${props.className || ""}${editing ? " is-editing" : ""}`}>
			<div className={"editable-content-wrapper"}>
				<div className="editable-content-container">
					{newValue || editing ? (
						<>
							<TextareaAutosize
								className="comment"
								value={newValue}
								onChange={(event) => HandleOnChange(event)}
								required={false}
								//autoFocus
								onFocus={HandleOnFocus}
								onBlur={HandleOnBlur}
								disabled={props.disableEditing}
								// This defines the max and minimum size of the textarea in rows. Use this rather than style sheets.
								// maxRows={10}
								minRows={1}
								maxRows={4}
								autoFocus={editing}
							/>
						</>
					) : (
						<>
							<button className="placeholder" onClick={HandleLabelClick}>
								Add a comment...
							</button>
						</>
					)}
					{/* <button
						className="cancel-edit-button circle sz-2 tertiary icon-only"
						onMouseDown={HandleCancel}
						title="cancel changes"
					>
						X
						<span className="sr-only">Cancel</span>
					</button> */}
				</div>
			</div>
		</section>
	);
};
