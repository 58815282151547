import { DateTime } from "luxon";
import { range } from "lodash";
import { InstanceManager } from "../Data/InstanceManager";
import { Instant } from "../Data/Instant";

export function CalcTimePosition(time: DateTime, dayStartOffsetHours: number, scale: number, dayOffset: number) {
	const minutes = time.diff(
		Instant.now()
			.startOf("day")
			.minus({ days: dayOffset })
			.plus({ hours: dayStartOffsetHours }),
		"minutes"
	).minutes;

	return minutes * scale;
}

export function CalcPositionTime(pos: number, dayStartOffsetHours: number, scale: number, dayOffset: number) {
	const minutes = pos / scale;

	return Instant.now()
		.startOf("day")
		.minus({ days: dayOffset })
		.plus({ hours: dayStartOffsetHours })
		.plus({ minutes: minutes });
}

export function SelfTest() {
	for (const hours of range(-12, 12)) {
		for (const scale of range(0.5, 5, 0.5)) {
			for (const dayOffset of range(0, -10, -1)) {
				selfTestInternal(hours, scale, dayOffset);
			}
		}
	}
}
function selfTestInternal(dayStartOffsetHours: number, scale: number, dayOffset: number) {
	const time = Instant.now();
	const pos = CalcTimePosition(time, dayStartOffsetHours, scale, dayOffset);
	const timeNew = CalcPositionTime(pos, dayStartOffsetHours, scale, dayOffset);

	if (time.diff(timeNew, "minutes").minutes > 0) throw "Time/position calculation self test failed";
}

export function CheckBuffer(timeToCheck: DateTime, bufferTime: number | undefined) {
	if (timeToCheck && bufferTime) {
		const timeDifference = Instant.now().diff(timeToCheck, "seconds").seconds;
		if (timeDifference < bufferTime) {
			return true;
		}
	}
	return false;
}
