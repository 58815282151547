import { useContext } from "react";
import { DateTime, Duration } from "luxon";
import { Guid } from "../Data/Guid";
import { TheHook } from "./TheHook/TheHook";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { TimeBuffers } from "../GlobalUtils/Constants";
import { CheckBuffer } from "../GlobalUtils/TimeUtils";
import { usePlayingTime } from "../Components/Library/Playhead/usePlayingTime";
import { ImContext } from "../Context/DbContext/DbContext";

export function useTimeControls(source: string) {
	const timelineContext = useContext(TimelineContext);
	const { isPlaying, currentTimeEntryGUID } = usePlayingTime() || {};
	const theHook = TheHook(source);
	const im = useContext(ImContext);

	async function TogglePlay(isPlayingOverride?: boolean) {
		if (isPlayingOverride ? isPlayingOverride : isPlaying) {
			PauseTime();
		} else {
			const lastEntry = im.dataLayer.TimeEntries.GetTodaysLatestEntry(
				timelineContext.state.timelineStartTimeOffsetHours
			);
			if (lastEntry && lastEntry.endedWhen && CheckBuffer(lastEntry.endedWhen, TimeBuffers.switchBufferTime)) {
				theHook.SetTimeEntry({
					...lastEntry,
					endedWhen: undefined,
				});
			} else {
				const timeEntrySet = currentTimeEntryGUID
					? im.dataLayer.TimeEntries.Get(currentTimeEntryGUID)
					: undefined;
				const groupGuid = timeEntrySet ? timeEntrySet.timeEntrySetGuid : undefined;

				await theHook.NewTimeEntry(undefined, undefined, groupGuid);
			}
		}
	}

	function PauseTime() {
		const lastEntry = im.dataLayer.TimeEntries.GetTodaysLatestEntry(
			timelineContext.state.timelineStartTimeOffsetHours
		);
		if (lastEntry && !lastEntry.endedWhen) {
			theHook.SetTimeEntry({
				...lastEntry,
				endedWhen: im.timeSource.GetLocalTime(),
			});
		}
	}

	function GetTotalGroupTime(TimeEntrySetGuid: Guid, _forSetDayOffset?: number, useRounding?: boolean): Duration {
		return im.timeCalculationHelpers.GetTotalGroupTime(
			TimeEntrySetGuid,
			timelineContext.state.timelineStartTimeOffsetHours,
			useRounding
		);
	}

	function GetTotalDayTime(forSetDayOffset?: number): Duration {
		return im.timeCalculationHelpers.GetTotalDayTime(
			forSetDayOffset ? forSetDayOffset : timelineContext.state.currentDayOffset,
			timelineContext.state.timelineStartTimeOffsetHours
		);
	}

	async function SwitchTo(taskGuid?: Guid) {
		if (taskGuid) {
			await theHook.SwitchToTask(taskGuid);
		} else {
			await theHook.SwitchToNew();
		}
	}

	function GetGroupsDayOffset(timeEntrySetGuid: Guid, currentTime: DateTime) {
		return im.timeCalculationHelpers.GetGroupsDayOffset(timeEntrySetGuid, currentTime);
	}

	return {
		TogglePlay,
		PauseTime,
		GetTotalGroupTime,
		GetTotalDayTime,
		SwitchTo,
		GetGroupsDayOffset,
	};
}
