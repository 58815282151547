import React, { useState, useContext, useEffect } from "react";
import { TimeEntryRepeaterProps } from "./TimeEntryRepeater.Interface";
import "./TimeEntryRepeater.scss";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";
import { TimeEntry } from "../../Components/Library/TimeEntry/TimeEntry";
import { useTimeEntries, IUseTimeEntries } from "../../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { EditModeContext } from "../../Context/EditModeContext/EditModeContext";
import { useGroups } from "../../Hooks/useGroups";
import { GroupTaskModalContext } from "../../Context/GroupTaskModalContext/GroupTaskModalContext";
import { GroupTaskModalContextDispatchActionType } from "../../Context/GroupTaskModalContext/GroupTaskModalContextDispatchActionType";
import { HistoryModel } from "../../Hooks/useHistory/HistoryModel";
import { useHistory } from "../../Hooks/useHistory/useHistory";
import { TheHook } from "../../Hooks/TheHook/TheHook";
import { TheHookModel } from "../../Hooks/TheHook/TheHookModel";
import { ModalContext } from "../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../Context/ModalContext/ModalContextDispatchActionType";
import { GroupTaskModal } from "../../Components/Library/GroupTaskModal/GroupTaskModal";
import { ImContext } from "../../Context/DbContext/DbContext";
import { List } from "immutable";

export const TimeEntryRepeater = (props: TimeEntryRepeaterProps) => {
	const Groups = useGroups(nameof(TimeEntryRepeater));

	const groupTaskModalContext = useContext(GroupTaskModalContext);
	const editModeContext = useContext(EditModeContext);
	const modalContext = useContext(ModalContext);

	const history: HistoryModel = useHistory(nameof(TimeEntryRepeater));
	const theHook: TheHookModel = TheHook(nameof(TimeEntryRepeater), true);
	const im = useContext(ImContext);
	const editModeTimeEntries: IUseTimeEntries = useTimeEntries(
		nameof(TimeEntryRepeater),
		undefined,
		im.editModeDataLayer.editModeTimeEntriesSubscribable
	);

	const [currentlyEditing, setCurrentlyEditing] = useState<boolean>(false)

	// This code adds a time entry that triggers the forgotten time entry view
	// HOW TO USE: Uncomment useEffect code, reload page, then comment it again and reload again.
	//
	// useEffect(()=>{
	// 	theHook.NewTimeEntry(InstanceManager.timeSource.GetTimeMom().startOf('day').subtract(4,'hour'))
	// },[])

	// ------------------------------------------------
	// MOVE THIS
	// const kairos = useKairos(nameof(TimeEntryRepeater));
	// useInterval(kairos.RunCallbacks, 1000);
	// ------------------------------------------------

	// ------------------------------------------------
	// MOVE THIS, THIS IS THE SOLE TRIGGER FOR UNDOREDO TO SET EDIT MODE

	useEffect(() => {
		if (history.state.present) {
			console.debug("present history", history.state.present);
			console.debug("past history", history.state.past);
			console.debug("future history", history.state.future);
			theHook.UndoRedoTriggered();
		}
	}, [editModeContext.state.undoRedoTrigger]);

	// ------------------------------------------------

	function ShowLinkButton() {
		// const group = Groups.Get(timeEntrySetGuid)

		// if (taskContext.state.activeTaskGuid) {
		// 	return true;
		// }
		// if (group) {
		// 	if (taskContext.state.activeGroupGuid) {
		// 		if (taskContext.state.activeGroupGuid === timeEntrySetGuid || !group.taskExternalId) {

		// 			return true;
		// 		}
		// 	}

		// }
		return false;
	}

	// function LinkToTask(TimeEntrySetGuid: string) {
	// 	props.LinkGroupToActiveTask(TimeEntrySetGuid)
	// }

	function UnlinkTimeEntrytFromGroup(timeEntry: ITimeEntry) {
		return theHook.UnlinkTimeEntryFromGroup(timeEntry.timeEntryGuid);
	}

	function SetModalGroup(TimeEntrySetGuid?: string) {
		if (TimeEntrySetGuid) {
			const group = Groups.Get(TimeEntrySetGuid);

			if (group) {
				modalContext.dispatch({
					type: ModalContextDispatchActionType.SetModalComponent,
					payload: <GroupTaskModal />,
				});

				groupTaskModalContext.dispatch({
					type: GroupTaskModalContextDispatchActionType.SetModalTask,
					payload: undefined,
				});
				groupTaskModalContext.dispatch({
					type: GroupTaskModalContextDispatchActionType.SetModalGroup,
					payload: group,
				});

				modalContext.dispatch({
					type: ModalContextDispatchActionType.OpenModal,
					payload: undefined,
				});
			}
		}
	}

	function GetEntryNeighbours(timeEntryGuid: string) {
		let previousEntry; // Time Entry found direclty before the declared Time Entry
		let nextEntry; // Time Entry found direclty after the declared Time Entry

		editModeTimeEntries
			.AllNotDeleted()
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.forEach((te: ITimeEntry, i: number, list: List<ITimeEntry>) => {
				if (te.timeEntryGuid == timeEntryGuid) {
					if (i > 0) {
						previousEntry = list.get(i - 1);
					}
					if (list.get(i + 1)) {
						nextEntry = list.get(i + 1)
					}
				}
			})

		return { previousEntry: previousEntry, nextEntry: nextEntry }
	}

	return (
		<section className="time-units-wrapper">
			{editModeTimeEntries
				.AllNotDeleted()
				.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
				.map((timeEntry) => {
					return (
						<TimeEntry
							key={"Time-Entry-" + timeEntry.timeEntryGuid}
							timeEntry={timeEntry}
							UnlinkTimeEntrytFromGroup={UnlinkTimeEntrytFromGroup}
							SetModalGroup={SetModalGroup}
							theHook={theHook}
							ShowLinkButton={ShowLinkButton}
							scrollRef={props.scrollRef}
							GetEntryNeighbours={GetEntryNeighbours}
							currentlyEditing={currentlyEditing}
							setCurrentlyEditing={setCurrentlyEditing}
						/>
					);
				})}
		</section>
	);
};
