import { Source } from "../Data/Source";

import { ITask } from "../Data/Models/ITask";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { InstanceManager } from "../Data/InstanceManager";
import { ImContext } from "../Context/DbContext/DbContext";
import { useContext } from "react";
export interface IUseTasks extends IUseSubscribableCollectionType<ITask> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTasks(source: Source, key?: string) {
	const im = useContext(ImContext);
	const subscribable = useSubscribableCollection(im.dataLayer.Tasks, source, key);
	return {
		...subscribable,
	};
}
