import { DateTime, DateTimeOptions, DateObject } from "luxon";

export class Instant extends DateTime {
	constructor() {
		super();
	}

	static fromISO(text: string, options?: DateTimeOptions) {
		return DateTime.fromISO(text, options).toUTC();
	}

	static fromJSDate(date: Date, options?: DateTimeOptions) {
		return DateTime.fromJSDate(date, options).toUTC();
	}

	static fromObject(obj: DateObject) {
		return DateTime.fromObject(obj).toUTC();
	}

	static fromFormat(text: string, format: string, opts?: DateTimeOptions) {
		return DateTime.fromFormat(text, format, opts).toUTC();
	}

	static fromMillis(ms: number, options?: DateTimeOptions) {
		return DateTime.fromMillis(ms, options).toUTC();
	}

	static fromSeconds(seconds: number, options?: DateTimeOptions) {
		return DateTime.fromSeconds(seconds, options).toUTC();
	}

	static fromHTTP(text: string, options?: DateTimeOptions) {
		return DateTime.fromHTTP(text, options).toUTC();
	}

	static fromSQL(text: string, options?: DateTimeOptions) {
		return DateTime.fromSQL(text, options).toUTC();
	}
}
