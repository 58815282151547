import React, { useContext } from "react";
import "./UnsavedAlert.scss";
import { UnsavedAlertProps } from "./UnsavedAlert.interface";
import { AlertContext } from "../../../Context/AlertContext/AlertContext";
import { AlertContextDispatchActionType } from "../../../Context/AlertContext/AlertContextDispatchActionType";

export const UnsavedAlert = (props: UnsavedAlertProps) => {
	const alertContext = useContext(AlertContext);

	function HandleSave() {
		props.onSave();
		Close();
	}

	function HandleDontSave() {
		props.onDontSave();
		Close();
	}

	function HandleCancel() {
		if (props.onCancel) {
			props.onCancel()
		};
		Close();
	}

	function Close() {
		alertContext.dispatch({
			type: AlertContextDispatchActionType.CloseAlert,
			payload: undefined,
		});
	}

	return (
		<section className="confirm-box-wrapper">
			<section className="confirm-box-container">
				<h2 className="confirmation-header">Unsaved edits!</h2>
				<div className="confirmation-message">Do you want to leave the editor without saving your changes?</div>
				<section className="confirmation-buttons">
					<button aria-label="Save" className="dont-save-button option-button pill sz-1" onClick={HandleSave}>
						{/* <span className="icon" aria-hidden="true" /> */}
						<span className="label">Save</span>
					</button>
					<button aria-label="Dont save" className="dont-save-button option-button pill sz-1" onClick={HandleDontSave}>
						{/* <span className="icon" aria-hidden="true" /> */}
						<span className="label">Don't save</span>
					</button>
					<button aria-label="Cancel" className="cancel-button option-button pill sz-1" onClick={HandleCancel}>
						{/* <span className="icon" aria-hidden="true" /> */}
						<span className="label">Cancel</span>
					</button>
				</section>
			</section>
		</section>
	);
};
