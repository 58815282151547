import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { TimeHubConnection } from "./TimeHubConnection";
import { IUpdateTimeDataRequestModel } from "./Models/IUpdateTimeDataRequestModel";
import { Instant } from "./Instant";
import { TimeHelper } from "./TimeHelper";

export class TimeApiConnection {
	constructor(private readonly timeHub: TimeHubConnection, private readonly timeHelper: TimeHelper) {}

	public async GetTimeEntrySets(from: Instant, clientId?: string) {
		const timeEntrySets = await this.timeHub.ListTimeEntrySetsAsync(from, clientId);

		return timeEntrySets.map((x) => this.timeHelper.ensureTimeEntrySetDates(x));
	}
	public async GetTimeEntrySetsV2(from: Instant) {
		const timeEntrySets = await this.timeHub.ListTimeEntrySetsAsyncV2(from);
		timeEntrySets.items.forEach((x) => this.timeHelper.ensureTimeEntrySetDates(x));
		return timeEntrySets;
	}
	public async SetTimeEntrySets(items: ITimeEntrySet[]) {
		await this.timeHub.UpsertTimeEntrySetAsync({ items });
	}
	public async GetTimeEntries(from: Instant, clientId?: string) {
		const timeEntries = await this.timeHub.ListTimeEntriesAsync(from, clientId);
		return timeEntries.map((x) => this.timeHelper.ensureTimeEntryDates(x));
	}
	public async SetTimeEntries(items: ITimeEntry[]) {
		await this.timeHub.UpsertTimeEntryAsync({ items });
	}

	public async GetTaskMetadata(from: Instant, clientId?: string) {
		const taskMetadatas = await this.timeHub.ListTaskMetadataAsync(from, clientId);
		return taskMetadatas.map((x) => this.timeHelper.ensureTaskMetadataDates(x));
	}
	public async SetTaskMetadata(items: ITaskMetadata[]) {
		await this.timeHub.UpsertMetadataAsync({ items });
	}

	public async UpdateTimeData(request: IUpdateTimeDataRequestModel) {
		await this.timeHub.UpdateTimeDataAsync({
			...request,
			taskMetadatas: request.taskMetadatas.map((x) => this.timeHelper.ensureTaskMetadataDates(x)),
			timeEntrySets: request.timeEntrySets.map((x) => this.timeHelper.ensureTimeEntrySetDates(x)),
			timeEntries: request.timeEntries.map((x) => this.timeHelper.ensureTimeEntryDates(x)),
		});
	}

	public async GetServerTime() {
		return this.timeHelper.toUtcDate(await this.timeHub.GetServerTimeAsync())!;
	}
}
