import React, { useState, useContext, useRef, useEffect } from "react";
import { ViewContext, Views } from "../Context/ViewContext/ViewContext";
import { TabMenu } from "./Library/TabMenu/TabMenu";
import "./ViewManager.scss";
import { MenuButton } from "../Components/Library/MenuButton/MenuButton";
import { Menu } from "../Components/Library/Menu/Menu";
import { DashboardTaskSummary } from "./Library/DashboardTaskSummary/DashboardTaskSummary";
import { DashboardComments } from "./Library/DashboardComments/DashboardComments";
import { useTimeEntries } from "../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../Context/ModalContext/ModalContextDispatchActionType";
import { TasklistModal } from "../Components/Library/TasklistModal/TasklistModal";
import { useGroups } from "../Hooks/useGroups";
import { Playhead } from "./Library/Playhead/Playhead";
import { useSyncDoneEvent } from "../Hooks/useSyncDoneEvent";
import { useTimeEntryChecker } from "../Hooks/useTimeEntryChecker";
import { PongSpinner } from "react-spinners-kit";
import { useMidnight } from "../Hooks/useMidnight";
import { useWindowSize } from "react-use";
import {
	useIntegrationSettings,
	DEFAULTROUNDINGINTEVAL,
	DEFAULTROUDNINGTHRESHOLD,
} from "../Hooks/useIntegrationSettings";
import { DayControls } from "./Library/DayControls/DayControls";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { useWindowFocus } from "../Hooks/useWindowFocus";
import { useCheckForForgottenTimeEntries } from "../Hooks/useCheckForForgottenTimeEntries";
import { ViewContextDispatchActionType } from "../Context/ViewContext/ViewContextDispatchActionType";

import { usePlayingTime } from "./Library/Playhead/usePlayingTime";
import { ImContext } from "../Context/DbContext/DbContext";

export function ViewManager() {
	const im = useContext(ImContext);
	const viewContext = useContext(ViewContext);
	const modalContext = useContext(ModalContext);
	const timelineContext = useContext(TimelineContext);

	const [menuIsOpen, setOpen] = useState<boolean>(false);
	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const CheckForForgottenTimeEntries = useCheckForForgottenTimeEntries(nameof(ViewManager));
	const forgottenTimeEntryChecker = useTimeEntryChecker(nameof(ViewManager));

	useMidnight(() => {
		im.dataLayer.TimeEntries.ClearCaches();
		CheckTimeEntries();
	}, timelineContext.state.timelineStartTimeOffsetHours);

	useWindowFocus(async () => {
		await im.dbAdapter.loadSubscribablesFromDb();
		im.dataLayer.ClearCaches();

		console.debug("Sync caused by window focus");
		await im.syncManager.SyncImmediately();
	});

	useSyncDoneEvent(() => {
		CheckTimeEntries();
		UpdateIntegrationSettings();
	});

	// NOTE: Part of temporary integration rounding setting code
	const IntegrationSettings = useIntegrationSettings(nameof(ViewManager));

	function CheckTimeEntries() {
		if (!CheckForForgottenTimeEntries()) {
			if (viewContext.state.viewName === Views.ForgottenTimeEntryView) {
				viewContext.dispatch({ type: ViewContextDispatchActionType.DashboardView });
			}
		} else {
			viewContext.dispatch({
				type: ViewContextDispatchActionType.ForgottenTimeEntryView,
			});
		}
		void forgottenTimeEntryChecker.CheckForRunnoverTimeEntries();
	}

	function UpdateIntegrationSettings() {
		// This is here since it's temporary and I don't want to mess with the ChronometricDB stuff
		const IntegrationGuids = new Set<{ integrationGuid: string; externalId: string }>();

		im.dataLayer.Tasks.All().forEach((task) => {
			IntegrationGuids.add({ integrationGuid: task.integrationGuid, externalId: task.externalId });
		});

		IntegrationGuids.forEach((i) => {
			const tmp = IntegrationSettings.Get(i.integrationGuid);
			if (!tmp) {
				IntegrationSettings.Set(i.integrationGuid, {
					integrationGuid: i.integrationGuid,
					roundingInterval: DEFAULTROUNDINGINTEVAL,
					roundingThreshold: DEFAULTROUDNINGTHRESHOLD,
				});
			}
		});

		console.log("ALL INTEGRATION SETTINGS: ", IntegrationSettings.All());
	}
	// ---------------------------------------------------
	const { currentTimeEntryGUID, currentTimeEntrySetGUID } = usePlayingTime() || {};
	const TimeEntries = useTimeEntries(nameof(ViewManager));
	const Groups = useGroups(nameof(ViewManager), currentTimeEntrySetGUID);

	//#region Comment Panel Auto-Scrolling
	const commentsPanelRef = useRef<HTMLDivElement>(null);
	const { height } = useWindowSize();
	const currentGroupTimeEntries = currentTimeEntrySetGUID && TimeEntries.GetForGroup(currentTimeEntrySetGUID);
	const currentTimeEntryGroup = currentTimeEntrySetGUID && Groups.Get(currentTimeEntrySetGUID);
	useEffect(() => {
		if (commentsPanelRef.current)
			commentsPanelRef.current.scrollTop =
				commentsPanelRef.current.scrollHeight - commentsPanelRef.current.clientHeight;
	}, [currentGroupTimeEntries, height]);

	function OpenMenu() {
		setOpen(true);
	}

	function CloseMenu(e?: any) {
		if (e) {
			if (menuButtonRef && menuButtonRef.current) {
				// if (e.target === menuButtonRef.current) {
				// 	return;
				// }
			} else {
				console.debug("Mouse ref doesn't exist");
			}
		}
		setOpen(false);
	}

	function checkIfDayStarted() {
		if (currentTimeEntryGUID) {
			return true;
		}
		return false;
	}

	function checkIfLinked() {
		if (currentTimeEntryGroup && currentTimeEntryGroup.taskIntegrationGuid) {
			return true;
		}

		return false;
	}

	function OpenLinkingModal() {
		if (currentTimeEntrySetGUID) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetModalComponent,
				payload: <TasklistModal timeEntrySetGuid={currentTimeEntrySetGUID} />,
			});

			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetCustomClassName,
				payload: "search-modal",
			});

			modalContext.dispatch({
				type: ModalContextDispatchActionType.OpenModal,
				payload: undefined,
			});
		}
	}

	function OpenSearchingModal() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <TasklistModal timeEntrySetGuid={currentTimeEntrySetGUID} />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomClassName,
			payload: "search-modal",
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	function needsPlayhead() {
		return viewContext.state.viewName === Views.ExportView || viewContext.state.viewName === Views.CommentsView;
	}

	function hideAside() {
		return viewContext.state.viewName === Views.LogInView || viewContext.state.viewName === Views.EditTimelineView;
	}

	function MainHeader() {
		return (
			<header
				id="dashboard-view-header"
				className={viewContext.state.viewName === Views.LogInView ? "header-wrapper full-width" : "header-wrapper"}
			>
				<MenuButton
					parent="dashboard"
					menuState={menuIsOpen}
					OpenMenu={OpenMenu}
					CloseMenu={CloseMenu}
					ref={menuButtonRef}
				/>
				<div className="logo-container">
					{/* <img src="/static/media/chronometric-logo-horizontal.svg" alt="Chronometric Logo" /> */}
				</div>
			</header>
		);
	}

	function LoginHeader() {
		return (
			<header
				className={
					viewContext.state.viewName === Views.LogInView ? "header-wrapper full-width logo-only" : "header-wrapper"
				}
			>
				<div className="logo-container">
					{/* <img src="/static/media/chronometric-logo-horizontal.svg" alt="Chronometric Logo" /> */}
				</div>
			</header>
		);
	}
	function ExportHeader() {
		return (
			<>
				<header id="export-view-header" className="header-wrapper">
					<MenuButton
						parent="timeline"
						menuState={menuIsOpen}
						OpenMenu={OpenMenu}
						CloseMenu={CloseMenu}
						ref={menuButtonRef}
					/>
					<div className="logo-container">
						{/* <img src="/static/media/chronometric-logo-horizontal.svg" alt="Chronometric Logo" /> */}
					</div>
					<button className="open-tasklist icon-only" onClick={OpenSearchingModal} />
				</header>

				{menuIsOpen ? <Menu CloseMenu={CloseMenu} /> : <></>}
			</>
		);
	}

	function CommentViewHeader() {
		return (
			<>
				<header id="comments-view-header" className={"header-wrapper" + (menuIsOpen ? " menu-showing " : "")}>
					<div className="header-left-content">
						<MenuButton
							parent="timeline"
							menuState={menuIsOpen}
							OpenMenu={OpenMenu}
							CloseMenu={CloseMenu}
							ref={menuButtonRef}
						/>
						<div className="logo-container" />
					</div>
					<DayControls />
					<div></div>
					{/* <button className="open-tasklist icon-only" onClick={OpenSearchingModal} /> */}
				</header>

				{menuIsOpen ? <Menu CloseMenu={CloseMenu} /> : <></>}
			</>
		);
	}

	function whichHeader() {
		if (viewContext.state.viewName === Views.CommentsView) {
			return <CommentViewHeader />;
		} else if (viewContext.state.viewName === Views.ExportView) {
			return <ExportHeader />;
		} else if (viewContext.state.viewName === Views.LogInView) {
			return <LoginHeader />;
		} else if (viewContext.state.viewName === Views.EditTimelineView) {
			return null;
		} else {
			return <MainHeader />;
		}
	}

	return viewContext.state.showLoadingSpinner ? (
		<main className="loading-spinner-screen">
			<div className="loading-spinner-content">
				<PongSpinner loading={true} color="#fff" />
				<span className="loading-spinner-message">{viewContext.state.loadingMessage}</span>
			</div>
		</main>
	) : (
		<main
			className={
				(checkIfLinked() ? "running-time-is-linked " : "running-time-not-linked ") +
				(checkIfDayStarted() ? "day-is-started " : "day-not-started ")
			}
		>
			{whichHeader()}
			{/* Main Menu */}
			{menuIsOpen ? <Menu CloseMenu={CloseMenu} /> : <></>}
			{/*<div className={"search-header" + (hideAside() ? "hidden" : "")}> TO DO - IMPLEMET SEARCH </div>*/}
			{/* <section id="search-list-wrapper" className={hideAside() ? "hidden" : ""}> */}
			{/* <Tasklist
					modalVersion={true}
					// timeEntrySetGuid={timeEntrySetGuid}
					// setRelinkTaskGuid={setRelinkTaskGuid}
					// relinkHightlightTask={relinkHightlightTask}
				/> */}
			{/* </section> */}
			{viewContext.state.currentView}
			<aside className={"task-comments-panel-wrapper " + (hideAside() ? "hidden" : "")}>
				<div className="task-details-wrapper">
					<DashboardTaskSummary />
				</div>
				<div className="currentTask">
					{currentTimeEntryGUID ?
						<section className="comments-panel" ref={commentsPanelRef}>
							<DashboardComments />
						</section>
						: <></>
					}

					{needsPlayhead() ? (
						<section className="playhead-wrapper">
							<Playhead />
						</section>
					) : null}
				</div>
			</aside>
			{/* {viewContext.state.showTabs ? <TabMenu /> : <></>} */}
		</main>
	);
}
