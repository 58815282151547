import { SubscribableCollectionWithBackingStore } from "./SubscribableWithBackingStore";
import { DateTime, Interval } from "luxon";
import { ITimeEntry } from "./Models/ITimeEntry";
import { Guid } from "./Guid";
import { List } from "immutable";
import { InstanceManager } from "./InstanceManager";
import { Source } from "./Source";
import { ISubscribableCollection, SubscribableCollection } from "./Subscribable";
import { IChronometricTable } from "./IChronometricDB";
import { TimeSource } from "./TimeSource";
import { Subscriber } from "./Subscriber";

export class TimeEntrySubscribable
	extends SubscribableCollectionWithBackingStore<ITimeEntry>
	implements ITimeEntrySubscribable {


	constructor(backingStore: IChronometricTable<ITimeEntry>, private readonly timeSource:TimeSource) {
		super(backingStore);
	}


	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw new Error(`Unable to load time entry with key '${key}' to remove`);
		item.lastUpdatedWhen = item.deletedWhen = this.timeSource.GetUtcTime();
		super.Set(key, item, source);
	}

	public ClearCaches(source?: Source) {
		console.debug(`TimeEntrySubscribable ClearCaches triggered by ${source || ""}`);
		this.LastAllNotDeleted = undefined;
		this.LastGetTodaysLatestEntry = undefined;
		this.LastGetTodaysEntries = undefined;
	}

	private LastAllNotDeleted: List<ITimeEntry> | undefined;
	public AllNotDeleted(): List<ITimeEntry> {
		if (this.LastAllNotDeleted) {
			return this.LastAllNotDeleted;
		}

		this.LastAllNotDeleted = List(this.All().filter((te) => !te.deletedWhen));

		return this.LastAllNotDeleted;
	}

	public GetRunningEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		// If there are more than one entry with no endedWhen, we've got bigger issues
		return this.GetTodaysEntries(dayStartOffsetHours)
			.filter((x) => !x.endedWhen)
			.first();
	}

	private LastGetTodaysLatestEntry: ITimeEntry | undefined;
	public GetTodaysLatestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		if (this.LastGetTodaysLatestEntry) return this.LastGetTodaysLatestEntry;

		const newLocal = this.GetTodaysEntries(dayStartOffsetHours).sort(
			(a, b) => a.startedWhen.diff(b.startedWhen).milliseconds
		);
		this.LastGetTodaysLatestEntry = newLocal.last();

		return this.LastGetTodaysLatestEntry;
	}

	public GetTodaysEarliestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		return this.GetTodaysEntries(dayStartOffsetHours)
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.first();
	}

	// This interacts oddly with edit mode time entries for some reason.
	private LastGetTodaysEntries: List<ITimeEntry> | undefined;
	public GetTodaysEntries(dayStartOffsetHours: number): List<ITimeEntry> {
		if (this.LastGetTodaysEntries) return this.LastGetTodaysEntries;

		const startOfDay = this.timeSource.GetLocalTime().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = this.timeSource.GetLocalTime().endOf("day").plus({ hours: dayStartOffsetHours });
		this.LastGetTodaysEntries = this.AllNotDeleted().filter(
			(timeEntry) =>
				!timeEntry.endedWhen || Interval.fromDateTimes(startOfDay, endOfDay).contains(timeEntry.startedWhen)
		);

		return this.LastGetTodaysEntries;
	}

	public GetSetDaysEntries(date: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = date.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = date.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	public GetDaysEntries(startDay: DateTime, endDay: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = startDay.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = endDay.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	private getTimeEntriesBetween(start: DateTime, end: DateTime): List<ITimeEntry> {
		return this.AllNotDeleted().filter((timeEntry) =>
			Interval.fromDateTimes(start, end).contains(timeEntry.startedWhen)
		);
	}

	public GetForGroup(timeEntrySetGuid: Guid) {
		return this.AllNotDeleted().filter((timeEntry) => timeEntry.timeEntrySetGuid === timeEntrySetGuid);
	}

	public GetFirstInGroup(timeEntrySetGuid: Guid) {
		return this.GetForGroup(timeEntrySetGuid)
			.sortBy((timeEntry) => timeEntry.startedWhen)
			.first(undefined);
	}
}

export class TimeEntryTempSubscribable extends SubscribableCollection<ITimeEntry> implements ITimeEntrySubscribable {
	constructor(subscribers: Subscriber<string>[], private readonly timeSource:TimeSource) {
		super(subscribers);
	}

	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw new Error(`Unable to load time entry with key '${key}' to remove`);
		item.lastUpdatedWhen = item.deletedWhen = this.timeSource.GetUtcTime();
		super.Set(key, item, source);
	}

	public ClearCaches(source?: Source) {
		console.debug(`TimeEntrySubscribable ClearCaches triggered by ${source || ""}`);
		this.LastAllNotDeleted = undefined;
		this.LastGetTodaysLatestEntry = undefined;
		this.LastGetTodaysEntries = undefined;
	}

	private LastAllNotDeleted: List<ITimeEntry> | undefined;
	public AllNotDeleted(): List<ITimeEntry> {
		if (this.LastAllNotDeleted) {
			return this.LastAllNotDeleted;
		}

		this.LastAllNotDeleted = List(this.All().filter((te) => !te.deletedWhen));

		return this.LastAllNotDeleted;
	}

	public GetRunningEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		// If there are more than one entry with no endedWhen, we've got bigger issues
		return this.GetTodaysEntries(dayStartOffsetHours)
			.filter((x) => !x.endedWhen)
			.first();
	}

	private LastGetTodaysLatestEntry: ITimeEntry | undefined;
	public GetTodaysLatestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		if (this.LastGetTodaysLatestEntry) return this.LastGetTodaysLatestEntry;

		const newLocal = this.GetTodaysEntries(dayStartOffsetHours).sort(
			(a, b) => a.startedWhen.diff(b.startedWhen).milliseconds
		);
		this.LastGetTodaysLatestEntry = newLocal.last();

		return this.LastGetTodaysLatestEntry;
	}

	public GetTodaysEarliestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		return this.GetTodaysEntries(dayStartOffsetHours)
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.first();
	}

	// This interacts oddly with edit mode time entries for some reason.
	private LastGetTodaysEntries: List<ITimeEntry> | undefined;
	public GetTodaysEntries(dayStartOffsetHours: number): List<ITimeEntry> {
		if (this.LastGetTodaysEntries) return this.LastGetTodaysEntries;

		const startOfDay = this.timeSource.GetLocalTime().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = this.timeSource.GetLocalTime().endOf("day").plus({ hours: dayStartOffsetHours });
		this.LastGetTodaysEntries = this.AllNotDeleted().filter(
			(timeEntry) =>
				!timeEntry.endedWhen || Interval.fromDateTimes(startOfDay, endOfDay).contains(timeEntry.startedWhen)
		);

		return this.LastGetTodaysEntries;
	}

	public GetSetDaysEntries(date: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = date.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = date.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	public GetDaysEntries(startDay: DateTime, endDay: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = startDay.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = endDay.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	private getTimeEntriesBetween(start: DateTime, end: DateTime): List<ITimeEntry> {
		return this.AllNotDeleted().filter((timeEntry) =>
			Interval.fromDateTimes(start, end).contains(timeEntry.startedWhen)
		);
	}

	public GetForGroup(timeEntrySetGuid: Guid) {
		return this.AllNotDeleted().filter((timeEntry) => timeEntry.timeEntrySetGuid === timeEntrySetGuid);
	}

	public GetFirstInGroup(timeEntrySetGuid: Guid) {
		return this.GetForGroup(timeEntrySetGuid)
			.sortBy((timeEntry) => timeEntry.startedWhen)
			.first(undefined);
	}
}

export interface ITimeEntrySubscribable extends ISubscribableCollection<ITimeEntry> {
	AllNotDeleted(): List<ITimeEntry>;

	GetRunningEntry(dayStartOffsetHours: number): ITimeEntry | undefined;

	GetTodaysLatestEntry(dayStartOffsetHours: number): ITimeEntry | undefined;

	GetTodaysEarliestEntry(dayStartOffsetHours: number): ITimeEntry | undefined;

	// This interacts oddly with edit mode time entries for some reason.
	GetTodaysEntries(dayStartOffsetHours: number): List<ITimeEntry>;

	GetSetDaysEntries(date: DateTime, dayStartOffsetHours: number): List<ITimeEntry>;

	GetDaysEntries(startDay: DateTime, endDay: DateTime, dayStartOffsetHours: number): List<ITimeEntry>;

	GetForGroup(timeEntrySetGuid: Guid): List<ITimeEntry>;

	GetFirstInGroup(timeEntrySetGuid: Guid): ITimeEntry | undefined;
}
