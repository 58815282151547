import { ISearchResult } from "../../Data/Models/ISearchResult";
import { FilterModes } from "./FilterModes";
import { TaskCardSizes } from "./TaskCardSizes";
import { Guid } from "../../Data/Guid";

export class SearchContextModel {
	public searchTerm?: string;
	public searchResults: ISearchResult[] = [];
	public newResults: ISearchResult[] = [];
	public isSearching = false;
	public taskCardSize: TaskCardSizes = TaskCardSizes.small;
	public filterMode: FilterModes = FilterModes.noFilter;
	public activeTaskKey?: Guid; // Keeps track of 'active' task, which is the one being linked/unlinked, or is currently playing.
	constructor(mcm?: Partial<SearchContextModel>) {
		Object.assign(this, mcm);
	}
}
