/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
//import { ApplicationInsights } from "@microsoft/applicationinsights-web";
//import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { Auth0Provider } from '@auth0/auth0-react';

//const reactPlugin = new ReactPlugin();
//const appInsights = new ApplicationInsights({
	//config: {
		//instrumentationKey: "%APPINSIGHTS_INSTRUMENTATIONKEY%",
		//extensions: [reactPlugin],
		//disableFetchTracking: false,
		//enableUnhandledPromiseRejectionTracking: true,
		//enableCorsCorrelation: true,
		//cookieDomain: "chronometric.app",
	//},
//});
//appInsights.loadAppInsights();
//appInsights.trackPageView();

const appWithAuth0 = <Auth0Provider
	domain={process.env.REACT_APP_AUTH0_DOMAIN || "chronometric-dev.au.auth0.com"}
	clientId={process.env.REACT_APP_AUTH0_CLIENTID || "GiVu8NORe2paUtQfQFVEhNYkKnDmyvYh"}
	redirectUri={window.location.origin}
>
	<App />
</Auth0Provider>

//export const AppWithAITracking = withAITracking(reactPlugin, appWithAuth0);

ReactDOM.render(appWithAuth0, document.getElementById("app-container"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
});


