import React, { useContext } from "react";
import "./DayControls.scss";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";
import { ImContext } from "../../../Context/DbContext/DbContext";

export const DayControls = () => {
	const timelineContext = useContext(TimelineContext);
	const im = useContext(ImContext);

	function CurrentDay() {
		let currentDay = "Today";

		if (timelineContext.state.currentDayOffset !== 0) {
			if (timelineContext.state.currentDayOffset === 1) {
				currentDay = "Yesterday";
			} else {
				currentDay = im.timeSource
					.GetLocalTime()
					.minus({ days: timelineContext.state.currentDayOffset })
					.toFormat("ccc d LLL");
			}
		}

		return currentDay;
	}

	function GoBackADay() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.SetCurrentDayOffset,
			payload: timelineContext.state.currentDayOffset ? timelineContext.state.currentDayOffset + 1 : 1,
		});
	}

	function GoForwardADay() {
	if (timelineContext.state.currentDayOffset !== 0) {
			timelineContext.dispatch({
				type: TimelineContextDispatchActionType.SetCurrentDayOffset,
				payload: timelineContext.state.currentDayOffset ? timelineContext.state.currentDayOffset - 1 : 0,
			});
		}
	}

	return (
		<div className="day-controls">
			<button aria-label="Navigate one day back in time" className="go-back-day icon-only" onClick={GoBackADay} />
			<i className="fa fa-calendar"></i>
			<div className="date-calander">{CurrentDay()}</div>
			<button
				className="go-forward-day icon-only"
				onClick={GoForwardADay}
				aria-label="Navigate one day forward in time"
				disabled={timelineContext.state.currentDayOffset === 0}
			></button>
		</div>
	);
};
