import { ConnectionManager } from "./ConnectionManager";
import { ISearchResponseModel } from "./Models/ISearchResponseModel";
import { AuthManager, ITokenIntegrationModel, IIntegration } from "./AuthManager";
import { ISearchResult } from "./Models/ISearchResult";
import { IGetTasksRequestModelV2 } from "./Models/IGetTasksRequestModelV2";
import { IGetTasksResponseModel } from "./Models/IGetTasksResponseModel";
import { ITask } from "./Models/ITask";
import { IUpdateTaskRequestModel } from "./Models/IUpdateTaskRequestModel";

import { ITaskRequestModelV2 } from "./Models/ITaskRequestModelV2";
import { DateTime } from "luxon";
import { TimeHelper } from "./TimeHelper";
import { IChronometricDB } from "./IChronometricDB";
import { EndpointManager } from "./EndpointManager";

// TODO: Split into search/tasks/export APIs
export class IntegrationApiConnection {
	constructor(
		private readonly cm: ConnectionManager,
		private readonly auth: AuthManager,
		private readonly db: IChronometricDB,
		private readonly timeHelper: TimeHelper,
		private readonly em: EndpointManager
	) { }

	public async Search(searchTerm: string) {
		const integrations = await this.auth.GetIntegrations();

		if (!integrations) {
			throw Error("jwt data unavailable");
		}

		const responses = await Promise.all(
			integrations.map(async (ig) => {
				try {
					const integrationUrl = this.em.TaskApiBase + "/" + ig.integration.integrationGuid + "/search";
					const resp = await this.cm.Fetch(integrationUrl, {
						method: "POST",
						body: JSON.stringify({ searchTerm }),
						headers: {
							"Content-type": "application/json",
						},
					});
					return (await resp.json()) as ISearchResponseModel;
				} catch (err) {
					return null;
				}
			})
		);

		return responses.reduce((acc, curr) => {
			if (curr) {
				acc = acc.concat(curr.results.map((x) => this.timeHelper.ensureSearchResultDates(x)));
			}
			return acc;
		}, [] as ISearchResult[]);
	}

	public async NewTasks() {
		const integrations = await this.auth.GetIntegrations();

		if (!integrations) {
			throw Error("jwt data unavailable");
		}

		const responses = await Promise.all(
			integrations.map(async (ig) => {
				try {
					const integrationUrl = this.em.TaskApiBase + "/" + ig.integration.integrationGuid + "/new";
					const resp = await this.cm.Fetch(integrationUrl, {
						method: "POST",
						headers: {
							"Content-type": "application/json",
						},
					});
					return (await resp.json()) as ISearchResponseModel;
				} catch (err) {
					return null;
				}
			})
		);

		return responses.reduce((acc, curr) => {
			if (curr) {
				acc = acc.concat(curr.results.map((x) => this.timeHelper.ensureSearchResultDates(x)));
			}
			return acc;
		}, [] as ISearchResult[]);
	}

	public async GetTasks(
		integration: IIntegration,
		tasks: ITaskRequestModelV2[],
		from: DateTime,
		clientId: string
	) {
		const integrationUrl = this.em.TaskApiBase + "/" + integration.integrationGuid + "/";
		const response = await this.cm.Fetch(`${integrationUrl}Tasks/ListV2`, {
			method: "POST",
			body: JSON.stringify({
				tasks,
				from: from.toUTC(),
				clientId,
			} as IGetTasksRequestModelV2),
			headers: {
				"Content-type": "application/json",
			},
		});
		const responseObj = (await response.json()) as IGetTasksResponseModel;
		responseObj.tasks = responseObj.tasks.map((x) => this.timeHelper.ensureTaskDates(x));
		responseObj.tags = responseObj.tags.map((x) => this.timeHelper.ensureTagDates(x));
		return responseObj;
	}

	public async UpdateTasks(integration: IIntegration, items: ITask[], clientId?: string) {
		for (const item of items) {
			const tagLinks = (await this.db.TaskTagLinks.getAll()).filter(
				(tt) => tt.integrationGuid === item.integrationGuid && tt.taskExternalId === item.externalId
			);
			const tags = (await this.db.Tags.getAll())
				.filter(
					(tag) =>
						tag.integrationGuid === item.integrationGuid && tagLinks.some((tl) => tl.tagExternalId === tag.externalId)
				)
				.toArray();

			// TODO: Bulk update
			const integrationUrl = this.em.TaskApiBase + "/" + integration.integrationGuid + "/";
			const response = await this.cm.Fetch(`${integrationUrl}Tasks/Update`, {
				method: "POST",
				body: JSON.stringify({
					task: item,
					tags,
					clientId,
				} as IUpdateTaskRequestModel),
				headers: {
					"Content-type": "application/json",
				},
			});
			if (!response.ok) {
				throw Error(`${response.status} ${response.statusText}`);
			}
		}
	}
}
