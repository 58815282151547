import { Source } from "../Data/Source";

import { ITimeEntrySet } from "../Data/Models/ITimeEntrySet";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { IGroupSubscribable } from "../Data/GroupSubscribable";
import { List } from "immutable";
import { SpecialCause } from "../Data/SpecialCause";
import { InstanceManager } from "../Data/InstanceManager";
import { ImContext } from "../Context/DbContext/DbContext";
import { useContext } from "react";

export interface IUseGroups extends IUseSubscribableCollectionType<ITimeEntrySet> {
	AllNotDeleted(): List<ITimeEntrySet>;
}

/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useGroups(
	source: Source,
	key?: string,
	specialCauses?: SpecialCause[],
	dataSource?: IGroupSubscribable
): IUseGroups {
	const im = useContext(ImContext);
	const ds = dataSource ?? im.dataLayer.Groups;
	
	const subscribable = useSubscribableCollection(ds, source, key, specialCauses);
	return Object.assign(
		{
			AllNotDeleted: () => ds.AllNotDeleted(),
		},
		subscribable
	);
}
