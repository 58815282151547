import React, { useEffect, useState } from "react";
import { InstanceManager, ImSingleton } from "../../Data/InstanceManager";
import { useAuth0 } from "@auth0/auth0-react";

export const ImContext = React.createContext<InstanceManager>(ImSingleton.getIm());

export const ImContextProvider = (props: JSX.ElementChildrenAttribute) => {

	const auth0Obj = useAuth0();

	const [im] = useState<InstanceManager>(() => ImSingleton.getIm())
	useEffect(() => {
		console.debug("Updating Auth0 obj", auth0Obj);
		im.setAuth0(auth0Obj);
	}, [auth0Obj.isAuthenticated, auth0Obj.isLoading, auth0Obj, im])

	return <ImContext.Provider value={im}>{props.children}</ImContext.Provider>;
};
