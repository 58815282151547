import React, { useContext, useEffect } from "react";
import "./CommentsView.scss";
import { TimelineContext, timelineNotesSortModeEnum } from "../../../Context/TimelineContext/TimelineContext";
import { CommentsPage } from "../../../Containers/CommentsPage/CommentsPage";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { AddTimeEntryModal } from "../../Library/AddTimeEntryModal/AddTimeEntryModal";
import { Timer } from "../../Library/Timer/Timer";
import { useCalculatedTimes } from "../../../Hooks/useCalculatedTimes";
import nameof from "ts-nameof.macro";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { TasklistModal } from "../../Library/TasklistModal/TasklistModal";
import { usePlayingTime } from "../../Library/Playhead/usePlayingTime";
import { useTimeControls } from "../../../Hooks/useTimeControls";

export function CommentsView() {
	const viewContext = useContext(ViewContext);
	const timelineContext = useContext(TimelineContext);
	const modalContext = useContext(ModalContext);
	const { todayTotalTime, weekTotalTime } = useCalculatedTimes();
	const theHook = TheHook(nameof(CommentsView));
	const { currentTimeEntrySetGUID } = usePlayingTime() || {};
	const timeControls = useTimeControls(nameof(CommentsView));
	const { currentTimeEntryGUID } = usePlayingTime() || {};

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.ShowTabs,
		});
	}, []);

	function ToggleNotesSortMode() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.ToggleTimelineSortMode,
			payload: null,
		});
	}

	function CreateNewTimeEntry() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <AddTimeEntryModal />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	function OpenEditMode() {
		theHook.OpenEditTimeline(timelineContext.state.currentDayOffset ? timelineContext.state.currentDayOffset : 0);
	}

	function OpenSearchingModal() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <TasklistModal timeEntrySetGuid={currentTimeEntrySetGUID} />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomClassName,
			payload: "search-modal",
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	function checkIfDayStarted() {
		if (currentTimeEntryGUID) {
			return true;
		}
		return false;
	}

	return (
		<section id="comments-view-wrapper">
			<section id="comments-view-controls-header">
				<div className="toggle-time-wrapper">
					<div
						id="comments-toggle"
						className={
							"toggle-wrapper toggle-2-state light-theme sz-md " +
							(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByTime
								? "time-view-active toggle-option-1-active"
								: "group-view-active  toggle-option-2-active")
						}
					>
						<button
							id="orderby-time"
							className={
								"toggle-option toggle-option-1 " +
								(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByTime ? "active" : "")
							}
							title="Order by time"
							aria-label="Order by time"
							onClick={ToggleNotesSortMode}
							disabled={timelineContext.state.timelineSortMode === timelineNotesSortModeEnum.sortByTime}
						/>
						<button
							id="orderby-group"
							className={
								"toggle-option toggle-option-2 " +
								(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByGroup ? "active" : "")
							}
							title="Order by task group"
							aria-label="Order by task group"
							onClick={ToggleNotesSortMode}
							disabled={timelineContext.state.timelineSortMode === timelineNotesSortModeEnum.sortByGroup}
						/>

						<div className="active-indicator" />
						<div className="toggle-background" aria-hidden="true" />
					</div>
					<p className="time-totals">
						Today: <Timer timeToDisplay={todayTotalTime} className={"halo-total-time"} isDecimalFormat={true} />
					</p>
					<p className="time-totals">
						This week: <Timer timeToDisplay={weekTotalTime} className={"halo-total-time"} isDecimalFormat={true} />
					</p>
				</div>
				<div className="time-edit-buttons-wrapper">
					<button className="icon-text add-time-entry" title="Add new time entry" onClick={CreateNewTimeEntry}>
						<span>Add new time entry</span>
					</button>
					<button className="icon-text open-timeline-editor" onClick={OpenEditMode} aria-label="Open timeline editor">
						<span>Editor</span>
					</button>
					<button className="icon-text open-tasklist" onClick={OpenSearchingModal}>
						<span>Find task</span>
					</button>
				</div>
			</section>
			{/*<TimeViewHeader SetTimeDisplayNotes={SetTimeDisplayNotes} SetTimeDisplayTimeline={SetTimeDisplayTimeline} />*/}

			{/*<Timeline LinkGroupToActiveTask={LinkGroupToActiveTask} GroupUnlink={GroupUnlink} />*/}

			{checkIfDayStarted() ? (
				<CommentsPage />
			) : (
				<div className="start-new-container">
					<p>Cute little message to pump you up for your day!</p>
					<button
						onClick={() => timeControls.TogglePlay()}
						className={"start-button-large"}
						aria-label={"Play task"}
						title={"Play task"}
					>
						Start New
					</button>
				</div>
			)}
		</section>
	);
}
