import { DateTime } from "luxon";
import { useState, useContext } from "react";
import { useInterval } from "./useInterval";
import { useWindowFocus } from "./useWindowFocus";
import { ImContext } from "../Context/DbContext/DbContext";

export function useMidnight(callback: () => unknown, dayStartOffsetHours: number) {
	const im = useContext(ImContext);
	const [newDate, setNewDate] = useState<DateTime>(() => im.timeSource.GetLocalTime()); // After reaching midnight it starts the loop again.

	const check = () => {
		if (
			im.timeSource.GetLocalTime() > newDate.toLocal().endOf("day").plus({ hours: dayStartOffsetHours })
		) {
			callback();
			setNewDate(im.timeSource.GetLocalTime());
		}
	};

	useWindowFocus(check);
	useInterval(check, 60000);
}
