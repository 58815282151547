/*
 * File generated by Interface generator (dotup.dotup-vscode-interface-generator)
 * Date: 2021-01-25 06:22:42
 */
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITask } from "./Models/ITask";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ITag } from "./Models/ITag";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { List } from "immutable";

export interface IChronometricDB {
	offChange(callback: (changes: IDatabaseChange[]) => void): void;
	onChange(callback: (changes: IDatabaseChange[]) => void): void;
	TimeEntries: ITimeEntriesTable;
	Tasks: IChronometricTable<ITask>;
	Tags: IChronometricTable<ITag>;
	TaskTagLinks: IChronometricTable<ITaskTagLink>;
	Groups: IChronometricTable<ITimeEntrySet>;
	TaskMetadatas: IChronometricTable<ITaskMetadata>;
	isReady: boolean;
	Ready(): Promise<void>;
	delete(): Promise<void>;
	waitForLeadership(): Promise<void>;
}

export interface IChronometricTable<T> {
	getAll(): Promise<List<T>>;
	put(value: T, key: string): Promise<void>;
	get(key: string): Promise<T | undefined>;
	bulkPut(values: List<T>, modifier?: (item: T) => Promise<T>): Promise<void>;
}

export interface ITimeEntriesTable extends IChronometricTable<ITimeEntry> {
	GetTodaysLatestEntry(dayStartOffsetHours: number): Promise<ITimeEntry>;
}

export enum DatabaseChangeType {
	Create = 1,
	Update = 2,
	Delete = 3,
}

export interface ICreateChange {
	type: DatabaseChangeType.Create;
	table: string;
	key: any;
	obj: any;
}

export interface IUpdateChange {
	type: DatabaseChangeType.Update;
	table: string;
	key: any;
	obj: any;
}

export interface IDeleteChange {
	type: DatabaseChangeType.Delete;
	table: string;
	key: any;
}

export type IDatabaseChange = ICreateChange | IUpdateChange | IDeleteChange;
