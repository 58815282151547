import React, { useState, useRef, useContext, useEffect } from "react";
import { ViewContextModel } from "./ViewContextModel";
import { ViewContextAction } from "./ViewContextActions";
import { ViewContextDispatchActionType } from "./ViewContextDispatchActionType";
import { ViewContextProviderProps } from "./ViewContext.Interface";
import { ForgottenTimeEntryView } from "../../Components/Views/ForgottenTimeEntryView/ForgottenTimeEntryView";
import { DashboardView } from "../../Components/Views/DashboardView/DashboardView";
import { CommentsView } from "../../Components/Views/CommentsView/CommentsView";
import { ExportView } from "../../Components/Views/ExportView/ExportView";
import { RoundingSettingsView } from "../../Components/Views/RoundingSettingsView/RoundingSettingsView";
import { useDbReadyEvent } from "../../Hooks/useDbReadyEvent";
import { useCheckForForgottenTimeEntries } from "../../Hooks/useCheckForForgottenTimeEntries";
import { Ticker } from "../../Hooks/useTime";
import nameof from "ts-nameof.macro";
import { useAuth0 } from "@auth0/auth0-react";
import { ImContext } from "../DbContext/DbContext";

export enum Views {
	None = "None",
	LogInView = "LogInView",
	ForgottenTimeEntryView = "ForgottenTimeEntryView",
	DashboardView = "DashboardView",
	CommentsView = "CommentsView",
	ExportView = "ExportView",
	EditTimelineView = "EditTimelineView",
	TasklistView = "TasklistView",
}

const TimerReducer = (state: ViewContextModel, action: ViewContextAction): ViewContextModel => {
	switch (action.type) {
		// case ViewContextDispatchActionType.setCurrentView:
		// 	return { ...state, currentView: action.payload };
		case ViewContextDispatchActionType.ForgottenTimeEntryView:
			Ticker.PauseTick();
			return {
				...state,
				currentView: <ForgottenTimeEntryView />,
				viewName: Views.ForgottenTimeEntryView,
				showLoadingSpinner: false,
			};

		// #region Tabs
		case ViewContextDispatchActionType.DashboardView:
			Ticker.ResumeTick();
			return { ...state, currentView: <DashboardView />, viewName: Views.DashboardView, showLoadingSpinner: false };
		case ViewContextDispatchActionType.CommentsView:
			Ticker.ResumeTick();
			return { ...state, currentView: <CommentsView />, viewName: Views.CommentsView, showLoadingSpinner: false };
		case ViewContextDispatchActionType.ExportView:
			Ticker.ResumeTick();
			return { ...state, currentView: <ExportView />, viewName: Views.ExportView, showLoadingSpinner: false };
		// #endregion

		case ViewContextDispatchActionType.RoundingSettingsView: // NOTE: This is temporary until the account screen is finished
			Ticker.PauseTick();
			return { ...state, currentView: <RoundingSettingsView />, viewName: Views.EditTimelineView };
		case ViewContextDispatchActionType.ShowTabs:
			return { ...state, showTabs: true };
		case ViewContextDispatchActionType.HideTabs:
			return { ...state, showTabs: false };
		case ViewContextDispatchActionType.ShowFullscreenLoadingMessage:
			return { ...state, showLoadingSpinner: true, loadingMessage: action.message };
		case ViewContextDispatchActionType.HideFullscreenLoadingMessage:
			return { ...state, showLoadingSpinner: false, loadingMessage: undefined };
		case ViewContextDispatchActionType.SetRefreshing:
			Ticker.SetTick(action.refreshing || false);
			return {
				...state,
				refreshing: action.refreshing,
				showLoadingSpinner: action.refreshing ? action.refreshing : state.refreshing,
				loadingMessage: action.refreshing ? "authenticating" : state.loadingMessage,
			};
		default:
			return { ...state };
	}
};

export const ViewContext = React.createContext({
	state: new ViewContextModel(),
	dispatch: {} as React.Dispatch<ViewContextAction>,
});

export const ViewContextProvider = (props: ViewContextProviderProps) => {
	const [state, dispatch] = React.useReducer(TimerReducer, {
		currentView: <></>,
		viewName: Views.None,
		showTabs: false,
		showLoadingSpinner: true,
		refreshing: false,
		loadingMessage: "loading",
	});

	const value = { state, dispatch };

	const [dbReady, setDbReady] = useState(false);
	useDbReadyEvent(() => setDbReady(true));

	const CheckForForgottenTimeEntries = useCheckForForgottenTimeEntries(nameof(ViewContextProvider));

	const loggingIn = useRef(false);
	const [isAuthenticatedPrevState, setIsAuthenticatedPrevState] = useState(false);
	const { isAuthenticated, isLoading } = useAuth0();
	useEffect(() => {
		if (!isLoading && isAuthenticated && dbReady && !isAuthenticatedPrevState) {
			setIsAuthenticatedPrevState(true)
			LogIn();
		}
	}, [isAuthenticated, isLoading, LogIn, dbReady])
	const im = useContext(ImContext);

	function LogIn() {
		if (loggingIn.current) return;

		loggingIn.current = true;

		if (navigator.storage && navigator.storage.persist)
			navigator.storage
				.persist()
				.then((result) => {
					if (result) console.log("Storage persisted 🥳🎉");
					else console.log("Storage not persisted 😭");
				})
				.catch((err) => {
					console.error("Storage persist request failed 🙅", err);
				});

		dispatch({
			type: ViewContextDispatchActionType.ShowFullscreenLoadingMessage,
			message: "syncing",
		});

		console.debug("Sync caused by login");
		void im.syncManager.SyncImmediately().then(() => {
			if (!CheckForForgottenTimeEntries()) {
				dispatch({
					type: ViewContextDispatchActionType.CommentsView,
				});
			} else {
				dispatch({
					type: ViewContextDispatchActionType.ForgottenTimeEntryView,
				});
			}

			loggingIn.current = false;
		});
	}

	return <ViewContext.Provider value={value}>{props.children}</ViewContext.Provider>;
};
