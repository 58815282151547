import { TaskSubscribable } from "./TaskSubscribable";
import { TimeEntrySubscribable } from "./TimeEntrySubscribable";
import { GroupSubscribable } from "./GroupSubscribable";
import { TagSubscribable } from "./TagSubscribable";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { SubscribableCollection } from "./Subscribable";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { IIntegrationSetting } from "./Models/IIntegrationSetting";
import { SubscribableCollectionWithBackingStore } from "./SubscribableWithBackingStore";
import { IChronometricDB } from "./IChronometricDB";
import { TimeSource } from "./TimeSource";

// tslint:disable: no-object-literal-type-assertion
export class DataLayer {
	constructor(private readonly db: IChronometricDB, private readonly timeSource: TimeSource) {
		void this.db.Ready().then(() => {
			this.Tasks = new TaskSubscribable(this.db.Tasks);
			this.Groups = new GroupSubscribable(this.db.Groups, this.timeSource);
			this.Tags = new TagSubscribable(this.db.Tags);
			this.TaskTagLinks = new SubscribableCollectionWithBackingStore<ITaskTagLink>(this.db.TaskTagLinks);
			this.TimeEntries = new TimeEntrySubscribable(this.db.TimeEntries, this.timeSource);
			this.TaskMetadatas = new SubscribableCollectionWithBackingStore<ITaskMetadata>(this.db.TaskMetadatas);
		});
	}
	public Tasks = new TaskSubscribable(this.db.Tasks);
	public Tags = new TagSubscribable(this.db.Tags);
	// TODO: Add better support for link types - instead of having a {keyA, keyB} type stored at [keyA, keyB], make the key the value
	public TaskTagLinks = new SubscribableCollectionWithBackingStore<ITaskTagLink>(this.db.TaskTagLinks);
	public TimeEntries = new TimeEntrySubscribable(this.db.TimeEntries, this.timeSource);

	public Groups = new GroupSubscribable(this.db.Groups, this.timeSource);

	public TaskMetadatas = new SubscribableCollectionWithBackingStore<ITaskMetadata>(this.db.TaskMetadatas);

	public IntegrationSettings = new SubscribableCollection<IIntegrationSetting>([]);

	public ClearCaches(): void {
		this.TimeEntries.ClearCaches();
		this.Groups.ClearCaches();
	}
}
