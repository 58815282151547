import React, { useContext } from "react";
import { Guid } from "../../Data/Guid";
import { EditModeContext } from "../../Context/EditModeContext/EditModeContext";
import { DateTime, Duration } from "luxon";
import { TimelineContext } from "../../Context/TimelineContext/TimelineContext";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";
import { useHistory, EditHistoryModel } from "../useHistory/useHistory";
import { TheHookModel } from "./TheHookModel";
import { v4 as uuid } from "uuid";
import { ITimeEntrySet } from "../../Data/Models/ITimeEntrySet";
import { SearchContext } from "../../Context/SearchContext/SearchContext";
import { KeyHelper } from "../../Data/KeyHelper";
import { EditModeContextDispatchActionType } from "../../Context/EditModeContext/EditModeContextDispatchActionType";

import { ISearchResult } from "../../Data/Models/ISearchResult";
import { ITask } from "../../Data/Models/ITask";
import { GlobalSettingsContext } from "../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { TimelineContextDispatchActionType } from "../../Context/TimelineContext/TimelineContextDispatchActionType";
import { ModalContext } from "../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../Context/ModalContext/ModalContextDispatchActionType";
import { EditModeTimeline } from "../../Containers/EditModeTimeline/EditModeTimeline";
import { TimeBuffers } from "../../GlobalUtils/Constants";
import { CheckBuffer } from "../../GlobalUtils/TimeUtils";
import { ITimeEntrySubscribable } from "../../Data/TimeEntrySubscribable";
import { SubscribableCollection } from "../../Data/Subscribable";
import { usePlayingTime } from "../../Components/Library/Playhead/usePlayingTime";
import { ImContext } from "../../Context/DbContext/DbContext";
// Need to work out a way for groups to be correctly modified with the undo/redo stacks.

export function TheHook(source: string, editMode = false): TheHookModel {
	enum AlertStatus {
		keepChanges = "KEEP",
		cancelChanges = "CANCEL",
		undecided = "UNDECIDED",
	}

	const timelineContext = useContext(TimelineContext);
	const editModeContext = useContext(EditModeContext);
	const searchContext = useContext(SearchContext);
	const globalSettingsContext = useContext(GlobalSettingsContext);
	const modalContext = useContext(ModalContext);
	const { currentTimeEntryGUID } = usePlayingTime() || {};
	const history = useHistory(source);
	const im = useContext(ImContext);

	const TimeEntries = im.dataLayer.TimeEntries;
	const Groups = im.dataLayer.Groups;
	const Tasks = im.dataLayer.Tasks;
	const taskMetadatas = im.dataLayer.TaskMetadatas;
	const TaskTagLinks = im.dataLayer.TaskTagLinks;
	const Tags = im.dataLayer.Tags;

	// TheTest is an empty subscribable
	const editModeTimeEntries = im.editModeDataLayer.editModeTimeEntriesSubscribable;
	const editModeGroups = im.editModeDataLayer.editModeGroupSubscribable;

	let cancelChanges: AlertStatus = AlertStatus.undecided;

	// NEXT STEP, MAKE SURE ALL FUNCTIONS USE EDIT MODE GROUPS WHILE IN EDIT MODE

	// go back to creating a new IUseTimeEntries, when using the functions

	function OpenEditTimeline(dayOffset: number, activeTimeEntry?: ITimeEntry) {
		editModeContext.dispatch({
			type: EditModeContextDispatchActionType.setEditModeDayOffset,
			payload: dayOffset,
		});

		if (activeTimeEntry) {
			timelineContext.dispatch({
				type: TimelineContextDispatchActionType.ScrollToTimeEntry,
				payload: activeTimeEntry,
			});
		}

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: (
				<>
					<EditModeTimeline />
					{/* <ContextMenu /> */}
				</>
			),
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomClassName,
			payload: " timeline-modal",
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: true,
		});
	}

	function SetupEditMode() {
		history.clear();

		const editingDaysTimeEntries = TimeEntries.GetSetDaysEntries(
			im.timeSource.GetLocalTime().minus({ days: editModeContext.state.editModeDayOffset }),
			timelineContext.state.timelineStartTimeOffsetHours
		);

		const editingDaysGroupsGuid = new Set<Guid>(); // Using a set to ignore duplicates

		if (editingDaysTimeEntries) {
			editModeTimeEntries.RemoveAll();
			editModeGroups.RemoveAll();

			editingDaysTimeEntries.forEach((timeEntry: ITimeEntry) => {
				editModeTimeEntries.Set(timeEntry.timeEntryGuid, { ...timeEntry }, source);
				editingDaysGroupsGuid.add(timeEntry.timeEntrySetGuid);
			});

			editingDaysGroupsGuid.forEach((groupGuid) => {
				const group = Groups.Get(groupGuid);
				if (group) {
					editModeGroups.Set(group.timeEntrySetGuid, { ...group }, source);
				}
			});

			StoreChangesInHistory();
		}
	}

	function SaveEditMode() {
		MergeEditModeIntoClaw();
		editModeTimeEntries.RemoveAll();
		editModeGroups.RemoveAll();
		history.clear();
	}

	function CancelEditMode() {
		editModeTimeEntries.RemoveAll();
		editModeGroups.RemoveAll();
		history.clear();
	}

	function SetTimeEntry(timeEntry: ITimeEntry) {
		SubscribableCollection.StartTransaction();
		try {
			console.debug("set time entry");
			if (editMode) {
				//  SetEditModeTimeEntriesToHistory()
				editModeTimeEntries.Set(
					timeEntry.timeEntryGuid,
					{
						...timeEntry,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);

				StoreChangesInHistory();
			} else {
				TimeEntries.Set(
					timeEntry.timeEntryGuid,
					{
						...timeEntry,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			}

			const group = (editMode ? editModeGroups : Groups).Get(timeEntry.timeEntrySetGuid);
			if (group) {
				SetGroup(group);
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function NewTimeEntry(
		StartedWhen: DateTime = im.timeSource.GetLocalTime(),
		EndedWhen?: DateTime,
		TimeEntrySetGuid?: Guid,
		TaskGuid?: Guid
	) {
		SubscribableCollection.StartTransaction();
		try {
			console.debug("new time entry");
			let newTimeEntry;
			if (editMode) {
				// let test = HistoryAsSubscribable()
				//  SetEditModeTimeEntriesToHistory()
				newTimeEntry = await CreateNewTimeEntry(editModeTimeEntries, TimeEntrySetGuid, TaskGuid, StartedWhen, EndedWhen);

				StoreChangesInHistory();
			} else {
				newTimeEntry = await CreateNewTimeEntry(TimeEntries, TimeEntrySetGuid, TaskGuid, StartedWhen, EndedWhen);
			}

			if (newTimeEntry) {
				const group = (editMode ? editModeGroups : Groups).Get(newTimeEntry.timeEntrySetGuid);
				if (group) {
					SetGroup(group);
				}
			}
			return newTimeEntry;
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function RemoveTimeEntry(TimeEntryGuid: Guid) {
		SubscribableCollection.StartTransaction();
		try {
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			const didDelete = DeleteTimeEntry(myTimeEntries, TimeEntryGuid);

			if (didDelete && editMode) {
				StoreChangesInHistory();
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function SplitTimeEntry(TimeEntryGuid: Guid, splitTime: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			console.debug("insert break");
			const timeEntry = myTimeEntries.Get(TimeEntryGuid);

			if (!timeEntry) throw "Unable to load time entry to split";

			await CreateNewTimeEntry(
				myTimeEntries,
				undefined,
				undefined,
				splitTime,
				timeEntry.endedWhen ? timeEntry.endedWhen : undefined
			);

			myTimeEntries.Set(
				timeEntry.timeEntryGuid,
				{
					...timeEntry,
					endedWhen: splitTime.minus({ second: 1 }).toLocal(),
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				},
				source
			);

			if (editMode) {
				StoreChangesInHistory();
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function InsertBreakInTimeEntry(TimeEntryGuid: Guid, splitTime: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			const defaultMinutesInBreak = globalSettingsContext.state.defaultMinutesInBreak
				? globalSettingsContext.state.defaultMinutesInBreak
				: 5;
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			console.debug("insert break");
			const timeEntry = myTimeEntries.Get(TimeEntryGuid);
			if (!timeEntry) throw "Unable to load time entry to break";

			const endedDateTime = timeEntry.endedWhen || DateTime.local();
			const lengthOfNewEntry = endedDateTime.diff(splitTime, "minutes").minutes;

			await CreateNewTimeEntry(
				myTimeEntries,
				timeEntry.timeEntrySetGuid,
				undefined,
				lengthOfNewEntry < defaultMinutesInBreak
					? splitTime.plus({ minutes: lengthOfNewEntry > 1 ? lengthOfNewEntry - 1 : 0 })
					: splitTime.plus({ minutes: defaultMinutesInBreak }),
				endedDateTime
			);

			myTimeEntries.Set(
				timeEntry.timeEntryGuid,
				{
					...timeEntry,
					endedWhen: splitTime.toLocal(),
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				},
				source
			);

			if (editMode) {
				StoreChangesInHistory();
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function InsertNewTimeEntry(startedWhen: DateTime, endedWhen: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			console.debug("insert new time entry");

			if (startedWhen > endedWhen) throw "Can't add time entry with end time before start time";

			// let test = HistoryAsSubscribable()
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			const postCollisionTimes = await CollisionNonDestructive(startedWhen, endedWhen);

			await CreateNewTimeEntry(
				myTimeEntries,
				undefined,
				undefined,
				postCollisionTimes.StartedWhen,
				postCollisionTimes.EndedWhen
			);

			if (editMode) {
				StoreChangesInHistory();
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function AddComment(timeEntry: ITimeEntry, comment: string) {
		SubscribableCollection.StartTransaction();
		try {
			let newComment;
			if (timeEntry.comment && timeEntry.comment.length !== 0) {
				newComment = timeEntry.comment + "\n" + comment;
			} else {
				newComment = comment;
			}

			const newTimeEntry = {
				...timeEntry,
				comment: newComment,
			};
			SetTimeEntry(newTimeEntry);

			const group = Groups.Get(timeEntry.timeEntrySetGuid);

			if (group) {
				const newGroup = {
					...group,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				};
				SetGroup(newGroup);
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function SetComment(timeEntry: ITimeEntry, newComment: string) {
		SubscribableCollection.StartTransaction();
		try {
			const newTimeEntry = {
				...timeEntry,
				comment: newComment,
			};
			SetTimeEntry(newTimeEntry);

			const group = Groups.Get(timeEntry.timeEntrySetGuid);

			if (group) {
				const newGroup = {
					...group,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				};
				SetGroup(newGroup);
			}

			timelineContext.dispatch({
				type: TimelineContextDispatchActionType.SetEditingComment,
				payload: false,
			});
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function InsertCollision(StartedWhen: DateTime, EndedWhen: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			console.debug("non-destructive drag");
			// let test = HistoryAsSubscribable()
			//  SetEditModeTimeEntriesToHistory()
			await CollisionNonDestructive(StartedWhen, EndedWhen);

			StoreChangesInHistory();
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function DragCollision(TimeEntryGuid: Guid, StartedWhen: DateTime, EndedWhen?: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			console.debug("destructive drag");
			// let test = HistoryAsSubscribable()
			//  SetEditModeTimeEntriesToHistory()
			const timeEntry = (editMode ? editModeTimeEntries : TimeEntries).Get(TimeEntryGuid);

			if (!timeEntry) throw "Unable to load time entry";

			const myComment = await CollisionDestructive(timeEntry, StartedWhen, EndedWhen);

			SetTimeEntry({
				...timeEntry,
				startedWhen: StartedWhen.toLocal(),
				endedWhen: EndedWhen && EndedWhen.toLocal(),
				comment: myComment,
				lastUpdatedWhen: im.timeSource.GetUtcTime(),
			});
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function CollisionDestructive(timeEntry: ITimeEntry, StartedWhen: DateTime, EndedWhen?: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			let newComment = timeEntry.comment;

			const group = Groups.Get(timeEntry.timeEntrySetGuid);

			if (group) {
				const newGroup = {
					...group,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				};
				SetGroup(newGroup);
			}

			// TODO: Make this only use today's entries (currently it stops collision working)
			// Shouldn't be much of an issue since the only time it's used at the DateTime is with edit mode time entries, which only contains today's entries
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			for (const te of myTimeEntries
				.GetSetDaysEntries(
					im.timeSource.GetLocalTime().minus({ days: editModeContext.state.editModeDayOffset }),
					timelineContext.state.timelineStartTimeOffsetHours
				)
				.filter((te) => te.timeEntryGuid !== timeEntry.timeEntryGuid)) {
				let entryStartMom = te.startedWhen;
				let entryEndMom = te.endedWhen ? te.endedWhen : im.timeSource.GetLocalTime();
				let edited = false;
				let toDelete = false;

				if (EndedWhen) {
					if (entryStartMom > StartedWhen && entryEndMom < EndedWhen) {
						toDelete = true;
					} else if (entryStartMom < StartedWhen && entryEndMom > EndedWhen) {
						// Split time entry into 2
						await CreateNewTimeEntry(
							myTimeEntries,
							te.timeEntrySetGuid,
							undefined,
							entryStartMom,
							StartedWhen.minus({ second: 1 })
						);

						entryStartMom = EndedWhen.plus({ second: 1 });
						edited = true;
					} else if (entryStartMom < StartedWhen && entryEndMom > StartedWhen) {
						entryEndMom = StartedWhen.minus({ second: 1 });
						edited = true;
					} else if (entryStartMom < EndedWhen && entryEndMom > EndedWhen) {
						entryStartMom = EndedWhen.plus({ second: 1 });
						edited = true;
					}
				} else {
					if (entryStartMom < StartedWhen && entryEndMom > StartedWhen) {
						entryEndMom = StartedWhen.minus({ second: 1 });
						edited = true;
					} else if (entryStartMom > StartedWhen) {
						toDelete = true;
					}
				}

				if (edited) {
					SetTimeEntry({
						...te,
						startedWhen: entryStartMom.toLocal(),
						endedWhen: te.endedWhen ? entryEndMom.toLocal() : undefined,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					});
				} else if (toDelete) {
					// If they've selected to cancel changes, don't prompt again.
					if (editMode && cancelChanges === AlertStatus.undecided) {
						cancelChanges = window.confirm("Do you want to delete that time entry?")
							? AlertStatus.keepChanges
							: AlertStatus.cancelChanges;
					}
					// Need to add a checkbox to the confirm alert to check if the user wants to merge comments.

					newComment =
						timeEntry.startedWhen < te.startedWhen ? newComment + "\n" + te.comment : te.comment + "\n" + newComment;

					console.log("Merged comments");

					DeleteTimeEntry(myTimeEntries, te.timeEntryGuid, true);
				}
			}

			return newComment;
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function CollisionNonDestructive(StartedWhen: DateTime, EndedWhen: DateTime) {
		SubscribableCollection.StartTransaction();
		try {
			const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;

			for (const timeEntry of myTimeEntries.GetSetDaysEntries(
				im.timeSource.GetLocalTime().minus({ days: editModeContext.state.editModeDayOffset }),
				timelineContext.state.timelineStartTimeOffsetHours
			)) {
				const entryStartMom = timeEntry.startedWhen;
				const entryEndMom = timeEntry.endedWhen || DateTime.local();
				if (entryStartMom < StartedWhen && entryEndMom > StartedWhen && entryEndMom < EndedWhen) {
					StartedWhen = entryEndMom;
				} else if (
					entryStartMom < EndedWhen &&
					!(entryStartMom < StartedWhen) &&
					(!entryEndMom || entryEndMom > EndedWhen)
				) {
					EndedWhen = entryStartMom;
				} else if (entryStartMom > StartedWhen && entryEndMom < EndedWhen) {
					EndedWhen = entryStartMom.minus({ second: 1 });
				} else if (entryStartMom < StartedWhen && (!entryEndMom || entryEndMom > EndedWhen)) {
					// Split time entry into 2
					await CreateNewTimeEntry(myTimeEntries, timeEntry.timeEntrySetGuid, undefined, entryStartMom, StartedWhen);

					myTimeEntries.Set(
						timeEntry.timeEntryGuid,
						{
							...timeEntry,
							startedWhen: EndedWhen.toLocal(),
							lastUpdatedWhen: im.timeSource.GetUtcTime(),
						},
						source
					);

					if (timeEntry) {
						const group = Groups.Get(timeEntry.timeEntrySetGuid);

						if (group) {
							const newGroup = {
								...group,
								lastUpdatedWhen: im.timeSource.GetUtcTime(),
							};
							SetGroup(newGroup);
						}
					}
				}
			}

			return { StartedWhen, EndedWhen };
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function DeleteTimeEntry(myTimeEntries: ITimeEntrySubscribable, TimeEntryGuid: Guid, skipConfirm?: boolean) {
		SubscribableCollection.StartTransaction();
		try {
			if (skipConfirm || window.confirm("Delete this time entry?")) {
				const timeEntry = myTimeEntries.Get(TimeEntryGuid);

				if (timeEntry) {
					myTimeEntries.Remove(TimeEntryGuid, source);

					const group = (editMode ? editModeGroups : Groups).Get(timeEntry.timeEntrySetGuid);

					if (group) {
						GroupLostMember(group);
					}

					return true;
				}
			}

			return false;
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function CreateNewTimeEntry(
		myTimeEntries: ITimeEntrySubscribable,
		TimeEntrySetGuid?: Guid,
		TaskGuid?: Guid,
		StartedWhen: DateTime = im.timeSource.GetLocalTime(),
		EndedWhen?: DateTime
	) {
		SubscribableCollection.StartTransaction();
		try {
			const thisTimeEntrySetGuid = TimeEntrySetGuid ? TimeEntrySetGuid : uuid();
			const newTimeEntryGuid = uuid();

			const localTime = im.timeSource.GetLocalTime();

			let createNewGroup = true;
			if (TimeEntrySetGuid) {
				const group = Groups.Get(TimeEntrySetGuid);
				if (group) {
					const firstInGroup = myTimeEntries.GetFirstInGroup(TimeEntrySetGuid);

					if (firstInGroup?.startedWhen.toLocal().hasSame(StartedWhen, "day")) {
						createNewGroup = false;
					}
				}
			}

			if (!EndedWhen && !editMode) {
				const runningTimeEntry = TimeEntries.GetRunningEntry(timelineContext.state.timelineStartTimeOffsetHours);
				if (runningTimeEntry && !runningTimeEntry.endedWhen) {
					// TODO: Run a proper destructive collision check when creating any new time entry
					SetTimeEntry({
						...runningTimeEntry,
						endedWhen: StartedWhen.minus({ second: 1 }).toLocal(),
					});
				}
			}

			if (createNewGroup) await NewGroup(thisTimeEntrySetGuid);

			if (TaskGuid && TaskGuid !== "undefined|undefined") {
				void LinkGroup(thisTimeEntrySetGuid, TaskGuid);
			}

			const newEntry: ITimeEntry = {
				timeEntryGuid: newTimeEntryGuid,
				timeEntrySetGuid: thisTimeEntrySetGuid,
				startedWhen: StartedWhen.toLocal(),
				endedWhen: EndedWhen?.toLocal(),
				lastUpdatedWhen: im.timeSource.GetUtcTime(),
				comment: "",
				timeZone: localTime.zoneName,
				timeOffsetMinutes: localTime.offset,
			};

			myTimeEntries.Set(newEntry.timeEntryGuid, newEntry, source);

			return newEntry;
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function SwitchToNew() {
		SubscribableCollection.StartTransaction();
		try {
			let currentTimeEntry: ITimeEntry | undefined;
			let originalGroup: ITimeEntrySet | undefined;
			const group = await NewGroup();

			if (currentTimeEntryGUID) {
				currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);

				if (currentTimeEntry) {
					originalGroup = Groups.Get(currentTimeEntry.timeEntrySetGuid);
				}
			}

			if (group) {
				let newEntry: ITimeEntry;

				if (currentTimeEntry) {
					// If you press switch to empty, then it will always make a new entry if the current is a linked entry
					if (
						originalGroup &&
						originalGroup.taskIntegrationGuid &&
						originalGroup.taskExternalId &&
						CheckBuffer(currentTimeEntry.startedWhen, TimeBuffers.switchBufferTime)
					) {
						newEntry = {
							...currentTimeEntry,
							startedWhen: im.timeSource.GetLocalTime(),
							endedWhen: undefined,
							timeEntrySetGuid: group.timeEntrySetGuid,
						};
						SetTimeEntry(newEntry);
					} else {
						if (!currentTimeEntry.endedWhen) {
							SetTimeEntry({
								...currentTimeEntry,
								endedWhen: im.timeSource.GetLocalTime(),
							});
						}
						newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
					}
				} else {
					newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
				}
			} else {
				console.debug("Could not create a new group for switch.");
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	// Not correctly losing groups members onswitch.
	// TODO: Don't switch if the task being switched to is the same as the currently running task
	async function SwitchToTask(taskGuid: string) {
		SubscribableCollection.StartTransaction();
		try {
			const currentTimeEntry = currentTimeEntryGUID ? TimeEntries.Get(currentTimeEntryGUID) : undefined;
			const originalGroup = currentTimeEntry ? Groups.Get(currentTimeEntry.timeEntrySetGuid) : undefined;
			const group = GetTodaysGroups().find((g) => KeyHelper.GetTimeEntrySetTaskKey(g) === taskGuid) || await NewGroup();

			let newEntry: ITimeEntry;

			if (currentTimeEntry) {
				// If you press switch to empty, then it will always make a new entry if the current is a linked entry
				if (
					((originalGroup && originalGroup.taskIntegrationGuid && taskGuid) ||
						(originalGroup && !originalGroup.taskIntegrationGuid)) &&
					CheckBuffer(currentTimeEntry.startedWhen, TimeBuffers.switchBufferTime)
				) {
					newEntry = {
						...currentTimeEntry,
						startedWhen: im.timeSource.GetLocalTime(),
						endedWhen: undefined,
						timeEntrySetGuid: group.timeEntrySetGuid,
					};
					SetTimeEntry(newEntry);
				} else {
					if (!currentTimeEntry.endedWhen) {
						SetTimeEntry({
							...currentTimeEntry,
							endedWhen: im.timeSource.GetLocalTime(),
						});
					}
					newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
				}
			} else {
				newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
			}

			// By linking here, the createNewTimeEntry's don't need to include the taskGuid
			void LinkGroup(group.timeEntrySetGuid, taskGuid);
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function SwitchToGroup(timeEntrySetGuid: string) {
		if (im.timeCalculationHelpers.GroupInSameDay(timeEntrySetGuid, im.timeSource.GetLocalTime())) {
			await SwitchToGroupInternal(timeEntrySetGuid);
		} else {
			const group = Groups.Get(timeEntrySetGuid);
			if (group?.taskExternalId) {
				await SwitchToTask(KeyHelper.GetTimeEntrySetTaskKey(group));
			} else {
				await SwitchToNew();
			}
		}
	}

	async function SwitchToGroupInternal(timeEntrySetGuid: string) {
		let currentTimeEntry: ITimeEntry | undefined;
		let originalGroup: ITimeEntrySet | undefined;
		let group = Groups.Get(timeEntrySetGuid);
		const taskGuid = group ? KeyHelper.GetTimeEntrySetTaskKey(group) : undefined;

		if (currentTimeEntryGUID) {
			currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);

			if (currentTimeEntry) {
				originalGroup = Groups.Get(currentTimeEntry.timeEntrySetGuid);
			}
		}

		if (!group) {
			group = await NewGroup();

			if (group && taskGuid) {
				// By linking here, the createNewTimeEntry's don't need to include the taskGuid
				void LinkGroup(group.timeEntrySetGuid, taskGuid);
			}
		}

		if (group) {
			let newEntry: ITimeEntry;

			if (currentTimeEntry) {
				// If you press switch to empty, then it will always make a new entry if the current is a linked entry
				if (
					((originalGroup && originalGroup.taskIntegrationGuid && taskGuid) ||
						(originalGroup && !originalGroup.taskIntegrationGuid)) &&
					CheckBuffer(currentTimeEntry.startedWhen, TimeBuffers.switchBufferTime)
				) {
					newEntry = {
						...currentTimeEntry,
						startedWhen: im.timeSource.GetLocalTime(),
						endedWhen: undefined,
						timeEntrySetGuid: group.timeEntrySetGuid,
					};
					SetTimeEntry(newEntry);
				} else {
					if (!currentTimeEntry.endedWhen) {
						SetTimeEntry({
							...currentTimeEntry,
							endedWhen: im.timeSource.GetLocalTime(),
						});
					}
					newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
				}
			} else {
				newEntry = await CreateNewTimeEntry(TimeEntries, group.timeEntrySetGuid);
			}
		} else {
			console.debug("Could not create a new group for switch.");
		}
	}

	// Dont' disable switch if timer is paused

	// ------ Group Functions ------

	async function NewGroup(TimeEntrySetGuid?: Guid) {
		const userId = await im.auth.GetUserId()
		if (userId) {
			// TODO: Fix new group values

			const newGroup: ITimeEntrySet = {
				name: GetUnlinkedGroupName(),
				timeEntrySetGuid: TimeEntrySetGuid ? TimeEntrySetGuid : uuid(),
				createdWhen: im.timeSource.GetUtcTime(),
				lastUpdatedWhen: im.timeSource.GetUtcTime(),
				userId,
				timeEntrySetStatusId: 0, // ??
				timeEntrySetBillingStatusId: 0, // ??
				metadata: "", // ??
				exportMaxAttemptsReached: false,
				exportAttemptCount: 0,
			};

			SetGroup(newGroup);

			return newGroup;
		} else throw new Error("No user ID");
	}

	function GetUnlinkedGroupName() {
		let groupName = globalSettingsContext.state.defaultGroupName;
		const todaysUnlinkedGroups = GetTodaysGroups().filter((g) => !g.taskExternalId && !g.taskIntegrationGuid);
		let unusedNameCheck = false;

		// Couldn't think of a better way to iterate through the alphabet.
		// Since I can't be bothered to make AA -> ZZ this gives us 26 group names before it defaults to Unlinked
		const dumbAlphabet = [
			"A",
			"B",
			"C",
			"D",
			"E",
			"F",
			"G",
			"H",
			"I",
			"J",
			"K",
			"L",
			"M",
			"N",
			"O",
			"P",
			"Q",
			"R",
			"S",
			"T",
			"U",
			"V",
			"W",
			"X",
			"Y",
			"Z",
		];
		let letterIndex = 0;

		console.log("todaysUnlinkedGroups: ", todaysUnlinkedGroups);

		while (unusedNameCheck === false) {
			unusedNameCheck = true;

			const unlinkedNameRegex = new RegExp(
				globalSettingsContext.state.defaultGroupName + " " + dumbAlphabet[letterIndex],
				"i"
			);

			console.log("REGEX: ", unlinkedNameRegex);

			for (let i = 0; i <= todaysUnlinkedGroups.length - 1; i++) {
				const name = todaysUnlinkedGroups[i].name;

				console.log("name: ", name);
				if (unlinkedNameRegex.test(name ? name : "")) {
					console.log("NAME MATCH");
					unusedNameCheck = false;
					todaysUnlinkedGroups.splice(i, 1); // Remove group from groups to check in order to reduce future loops.
					break; // Break loop since it needs to check next letter (and array will be modified due to splice)
				}
			}

			if (unusedNameCheck) {
				groupName = globalSettingsContext.state.defaultGroupName + " " + dumbAlphabet[letterIndex];
			}

			letterIndex = letterIndex + 1;

			if (letterIndex > dumbAlphabet.length) {
				unusedNameCheck = true;
				groupName = globalSettingsContext.state.defaultGroupName;
			}
		}

		return groupName;
	}

	function SetGroup(Group: ITimeEntrySet) {
		// Check that group is not empty

		if (editMode) {
			editModeGroups.Set(
				Group.timeEntrySetGuid,
				{
					...Group,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				},
				source
			);
		} else {
			Groups.Set(
				Group.timeEntrySetGuid,
				{ ...Group, lastUpdatedWhen: im.timeSource.GetUtcTime() },
				source
			);
		}
	}

	function GetTodaysGroups() {
		const myGroups = editMode ? editModeGroups : Groups;
		const myTimeEntries = editMode ? editModeTimeEntries : TimeEntries;
		const todaysGroups: Set<ITimeEntrySet> = new Set();

		myTimeEntries.GetTodaysEntries(timelineContext.state.timelineStartTimeOffsetHours).forEach((te) => {
			const group = myGroups.Get(te.timeEntrySetGuid);
			if (group) {
				todaysGroups.add(group);
			}
		});

		return Array.from(todaysGroups);
	}

	function GetOffestDaysGroups(dayOffset: number) {
		const todaysGroups: Set<ITimeEntrySet> = new Set();

		TimeEntries.GetSetDaysEntries(
			im.timeSource.GetLocalTime().minus({ days: dayOffset }),
			timelineContext.state.timelineStartTimeOffsetHours
		).forEach((te) => {
			const group = Groups.Get(te.timeEntrySetGuid);
			if (group) {
				todaysGroups.add(group);
			}
		});

		return Array.from(todaysGroups);
	}

	function RemoveGroup(timeEntrySetGuid: Guid) {
		const myGroups = editMode ? editModeGroups : Groups;

		console.debug("Remove group: " + timeEntrySetGuid);

		myGroups.Remove(timeEntrySetGuid, source);
	}

	async function LinkGroup(timeEntrySetGuid: Guid, taskGuid: Guid) {
		const linkingGroup = Groups.Get(timeEntrySetGuid);
		let task = Tasks.Get(taskGuid);

		// TODO: Check if task is already linked to a group. If so then merge them.
		if (linkingGroup) {
			if (!task) {
				console.debug("search results to look through ", searchContext.state.searchResults);
				const searchResult = searchContext.state.searchResults.find(
					(sr) => KeyHelper.GetSearchResultTaskKey(sr) === taskGuid
				);
				console.debug(`Key ${taskGuid} found this search result`, searchResult);
				if (searchResult) {
					task = await im.taskHelper.TaskifySearchResult(searchResult);
				}
			}

			if (task) {
				const groupDayOffset = im.timeCalculationHelpers.GetGroupsDayOffset(
					timeEntrySetGuid,
					im.timeSource.GetLocalTime()
				);
				const taskGroup = GetOffestDaysGroups(groupDayOffset).find(
					(g) => KeyHelper.GetTimeEntrySetTaskKey(g) === KeyHelper.GetTaskKey(task!)
				);

				if (taskGroup && taskGroup.timeEntrySetGuid !== linkingGroup.timeEntrySetGuid) {
					MergeGroups(taskGroup.timeEntrySetGuid, linkingGroup.timeEntrySetGuid);
				} else {
					SetGroup({
						...linkingGroup,
						name: linkingGroup.name ? linkingGroup.name : task.name,
						taskIntegrationGuid: task.integrationGuid,
						taskExternalId: task.externalId,
					});
				}

				Tasks.Set(
					KeyHelper.GetTaskKey(task),
					{
						...task,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			} else {
				throw new Error("Cannot link to nothing");
			}
		} else {
			console.debug("could not find group for linking");
		}
	}

	function UnlinkGroup(timeEntrySetGuid: Guid, taskGuid?: Guid) {
		const group = Groups.Get(timeEntrySetGuid);

		if (group) {
			const task = taskGuid ? Tasks.Get(taskGuid) : Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(group));

			if (task) {
				Tasks.Set(
					taskGuid ? taskGuid : KeyHelper.GetTaskKey(task),
					{
						...task,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			}

			Groups.Set(
				timeEntrySetGuid,
				{
					...group,
					taskIntegrationGuid: undefined,
					taskExternalId: undefined,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				},
				source
			);
		}
	}

	async function LinkTimeEntriesToTask(timeEntries: ITimeEntry[], taskGuid: Guid) {
		SubscribableCollection.StartTransaction();
		try {
			const originalGroup = Groups.Get(timeEntries[0].timeEntrySetGuid);
			const task = Tasks.Get(taskGuid);

			if (task) {
				Tasks.Set(
					taskGuid ? taskGuid : KeyHelper.GetTaskKey(task),
					{
						...task,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);

				const newGroup = await NewGroup();

				if (newGroup) {
					timeEntries.forEach((te) => {
						SetTimeEntry({ ...te, timeEntrySetGuid: newGroup.timeEntrySetGuid });
					});

					void LinkGroup(newGroup.timeEntrySetGuid, KeyHelper.GetTaskKey(task));

					if (originalGroup) {
						GroupLostMember(originalGroup);
					}

					return newGroup;
				} else {
					console.debug("Failed to create new group");
				}
			} else {
				console.debug("LinkTimeEntrisToTask task was not found");
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	async function UnlinkTimeEntriesFromTask(timeEntries: ITimeEntry[], taskGuid?: Guid) {
		SubscribableCollection.StartTransaction();

		try {
			const originalGroup = Groups.Get(timeEntries[0].timeEntrySetGuid);

			if (taskGuid) {
				const task = Tasks.Get(taskGuid);

				if (task) {
					Tasks.Set(
						taskGuid ? taskGuid : KeyHelper.GetTaskKey(task),
						{
							...task,
							lastUpdatedWhen: im.timeSource.GetUtcTime(),
						},
						source
					);
				}
			}

			const newGroup = await NewGroup();

			if (newGroup) {
				timeEntries.forEach((te) => {
					SetTimeEntry({ ...te, timeEntrySetGuid: newGroup.timeEntrySetGuid });
				});

				if (originalGroup) {
					GroupLostMember(originalGroup);
				}

				return newGroup;
			} else {
				console.debug("Failed to create new group");
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function MergeGroups(activetimeEntrySetGuid: Guid, mergingtimeEntrySetGuid: Guid) {
		SubscribableCollection.StartTransaction();

		try {
			const activeGroup = Groups.Get(activetimeEntrySetGuid);
			const mergingGroup = Groups.Get(mergingtimeEntrySetGuid);

			console.debug("merging group");

			if (activeGroup && mergingGroup && activeGroup.timeEntrySetGuid !== mergingGroup.timeEntrySetGuid) {
				// Need to re-assign all time entries from merging group into active group
				const daysTimeEntries = TimeEntries.GetSetDaysEntries(
					im.timeSource.GetLocalTime().minus({ days: timelineContext.state.currentDayOffset }),
					timelineContext.state.timelineStartTimeOffsetHours
				);

				daysTimeEntries.forEach((timeEntry) => {
					if (timeEntry.timeEntrySetGuid === mergingGroup.timeEntrySetGuid) {
						TimeEntries.Set(
							timeEntry.timeEntryGuid,
							{
								...timeEntry,
								timeEntrySetGuid: activeGroup.timeEntrySetGuid,
								lastUpdatedWhen: im.timeSource.GetUtcTime(),
							},
							source
						);
					}
				});

				activeGroup.lastUpdatedWhen = im.timeSource.GetUtcTime();
				SetGroup(activeGroup);

				RemoveGroup(mergingGroup.timeEntrySetGuid);
			}
		} finally {
			SubscribableCollection.FinishTransaction();
		}
	}

	function GroupLostMember(group: ITimeEntrySet, amount = 0) {
		// Amount is for when the time entry hasn't been removed or edited yet.

		const myGroups = editMode ? editModeGroups : Groups;
		const myTimeEntries = editMode ? editModeTimeEntries.AllNotDeleted() : TimeEntries.AllNotDeleted(); // TODO: Change this to calculate the groups' start date and use that to calculate the specific day

		const numTimeEntriesInGroup = myTimeEntries
			.filter((timeEntry) => timeEntry.timeEntrySetGuid === group.timeEntrySetGuid)
			.count();

		if (numTimeEntriesInGroup - amount > 0) {
			if (numTimeEntriesInGroup - amount === 1) {
				myGroups.Set(
					group.timeEntrySetGuid,
					{
						...group,
						timeEntrySetIconId: undefined,
						timeEntrySetColourId: undefined,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			}
		} else {
			RemoveGroup(group.timeEntrySetGuid);
		}
	}

	function LinkTimeEntryToGroup(timeEntryGuid: Guid, newTimeEntrySetGuid: Guid) {
		const timeEntry = TimeEntries.Get(timeEntryGuid);
		const group = Groups.Get(newTimeEntrySetGuid);

		if (timeEntry && group) {
			TimeEntries.Set(
				timeEntry.timeEntryGuid,
				{
					...timeEntry,
					timeEntrySetGuid: newTimeEntrySetGuid,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
				},
				source
			);
			SetGroup(group);
		}
	}

	async function UnlinkTimeEntryFromGroup(timeEntryGuid: Guid) {
		const timeEntry = TimeEntries.Get(timeEntryGuid);

		if (timeEntry) {
			const group = Groups.Get(timeEntry.timeEntrySetGuid);

			if (group) {
				const newtimeEntrySet = await NewGroup();

				if (newtimeEntrySet) {
					TimeEntries.Set(
						timeEntry.timeEntryGuid,
						{
							...timeEntry,
							timeEntrySetGuid: newtimeEntrySet.timeEntrySetGuid,
							lastUpdatedWhen: im.timeSource.GetUtcTime(),
						},
						source
					);

					GroupLostMember(group);

					return newtimeEntrySet.timeEntrySetGuid;
				}
			}
		}
	}

	function MarkGroupForExport(groupGuid: Guid, groupTimeInSeconds: Duration) {
		const group = Groups.Get(groupGuid);

		if (group) {
			Groups.Set(
				group.timeEntrySetGuid,
				{
					...group,
					secondsToExport: groupTimeInSeconds.as("seconds"),
					queuedForExportWhen: im.timeSource.GetUtcTime(),
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
					lastExportErrorData: undefined,
					lastExportErrorOccurredWhen: undefined,
					lastExportedWhen: undefined,
					lastExportErrorTypeCodeName: undefined,
					exportMaxAttemptsReached: false,
					exportAttemptCount: 0,
				},
				source
			);

			return true;
		}
		return false;
	}

	function MarkGroupAsExported(groupGuid: Guid) {
		const group = Groups.Get(groupGuid);

		if (group) {
			Groups.Set(
				group.timeEntrySetGuid,
				{
					...group,
					lastUpdatedWhen: im.timeSource.GetUtcTime(),
					lastExportedWhen: im.timeSource.GetUtcTime(),
					queuedForExportWhen: undefined,
					lastExportErrorData: undefined,
					lastExportErrorOccurredWhen: undefined,
					lastExportErrorTypeCodeName: undefined,
					exportMaxAttemptsReached: false,
					exportAttemptCount: 0,
				},
				source
			);

			return true;
		}
		return false;
	}

	async function FavouriteSearchResult(searchResult: ISearchResult) {
		await SetIsFavorite(searchResult, true);

		console.log("favourited task");
	}

	async function UnfavouriteSearchResult(searchResult: ISearchResult) {
		await SetIsFavorite(searchResult, false);

		console.log("favourited task");
	}

	async function SetIsFavorite(searchResult: ISearchResult, isFavorite: boolean) {
		const task = await im.taskHelper.TaskifySearchResult(searchResult);
		const key = KeyHelper.GetTaskKey(task);
		const metaData = im.dataLayer.TaskMetadatas.Get(key);

		if (!metaData) throw `Unable to load metadata with key '${key}' to update`;

		taskMetadatas.Set(
			key,
			{
				...metaData,
				isFavorite: isFavorite,
				lastUpdatedWhen: im.timeSource.GetUtcTime(),
			},
			source
		);
	}

	function GetFavouriteTasks() {
		return im.dataLayer.TaskMetadatas.All()
			.filter((tm) => tm.isFavorite)
			.map((tm) => Tasks.Get(KeyHelper.GetTaskKey(tm)));
	}

	function GetTaskTags(task: ITask) {
		return TaskTagLinks.All()
			.filter((ttl) => KeyHelper.GetTaskKey(task) === KeyHelper.GetTaskTagLinkTaskKey(ttl))
			.map((ttl) => Tags.Get(KeyHelper.GetTagKeyFromTaskTagLink(ttl)))
			.filter((ttl) => !!ttl)
			.map((ttl) => ttl!);
	}

	function CheckGroupIsExportable(group: ITimeEntrySet) {
		const task = Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(group));

		// Only groups with tasks can be exported
		// Groups that have been exported can be exported again if they have been updated since, or if there was an error during their export.
		if (task) {
			if (
				!group.queuedForExportWhen ||
				group.queuedForExportWhen < group.lastUpdatedWhen ||
				(group.lastExportedWhen && group.lastExportedWhen < group.lastUpdatedWhen) ||
				(group.lastExportErrorOccurredWhen && group.queuedForExportWhen < group.lastExportErrorOccurredWhen)
			) {
				// Currently running groups cannot be exported
				const currentTimeEntry = currentTimeEntryGUID ? TimeEntries.Get(currentTimeEntryGUID) : undefined;
				if (
					!currentTimeEntry ||
					currentTimeEntry.endedWhen ||
					currentTimeEntry.timeEntrySetGuid !== group.timeEntrySetGuid
				) {
					return true;
				}
			}
		}
		return false;
	}

	// ------ Edit Mode specific functions ------

	function UndoRedoTriggered() {
		if (history.state && history.state.present) {
			editModeTimeEntries.RemoveAll();
			editModeGroups.RemoveAll();

			// make copies of history values then set them to edit mode values
			history.state.present.presentTimeEntries.forEach((historyTimeEntry: ITimeEntry) => {
				const editModeTimeEntry = { ...historyTimeEntry };
				editModeTimeEntries.Set(editModeTimeEntry.timeEntryGuid, editModeTimeEntry, source);
			});

			history.state.present.presentGroups.forEach((historyGroup: ITimeEntrySet) => {
				const editModeGroup = { ...historyGroup };
				editModeGroups.Set(editModeGroup.timeEntrySetGuid, editModeGroup, source);
			});
		}
	}

	function MergeEditModeIntoClaw() {
		if (TimeEntries && editModeTimeEntries) {
			editModeGroups.All().forEach((editModeGroup) => {
				Groups.Set(
					editModeGroup.timeEntrySetGuid,
					{
						...editModeGroup,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			});

			//  Add or modify any time entries that were added or changed
			editModeTimeEntries.All().forEach((editModeEntry: ITimeEntry) => {
				TimeEntries.Set(
					editModeEntry.timeEntryGuid,
					{
						...editModeEntry,
						lastUpdatedWhen: im.timeSource.GetUtcTime(),
					},
					source
				);
			});
		}
	}

	function StoreChangesInHistory() {
		console.debug("Edit Mode Time Entries", editModeTimeEntries.All());

		const histroyValues: EditHistoryModel = {
			presentTimeEntries: editModeTimeEntries.All().map((x) => Object.assign(x) as ITimeEntry),
			presentGroups: editModeGroups.All().map((x) => Object.assign(x) as ITimeEntrySet),
		};

		console.debug("history values", histroyValues);
		console.log("remove this log in 5 seconds.... altea rideigo");
		history.set(histroyValues);

		if (cancelChanges === AlertStatus.cancelChanges) {
			if (history.canUndo) {
				history.undo();
			} else {
				history.forceUndo();
			}

			cancelChanges = AlertStatus.undecided;
		}
	}

	return {
		OpenEditTimeline,
		SetupEditMode,
		SaveEditMode,
		CancelEditMode,
		SetTimeEntry,
		NewTimeEntry,
		RemoveTimeEntry,
		InsertNewTimeEntry,
		InsertBreakInTimeEntry,
		SplitTimeEntry,
		InsertCollision,
		DragCollision,
		SwitchToNew,
		SwitchToTask,
		SwitchToGroup,
		MergeEditModeIntoClaw,
		UndoRedoTriggered,
		NewGroup,
		SetGroup,
		RemoveGroup,
		LinkGroup, //
		UnlinkGroup, //
		MergeGroups,
		LinkTimeEntryToGroup,
		UnlinkTimeEntryFromGroup,
		GetTodaysGroups,
		GetOffestDaysGroups,
		AddComment,
		SetComment,
		MarkGroupForExport,
		MarkGroupAsExported,
		FavouriteSearchResult,
		UnfavouriteSearchResult,
		GetFavouriteTasks,
		GetTaskTags,
		LinkTimeEntriesToTask,
		UnlinkTimeEntriesFromTask,
		CheckGroupIsExportable,
	};
}
