/* eslint-disable no-case-declarations */

import { KeyHelper } from "./KeyHelper";
import { ITag } from "./Models/ITag";
import { ITask } from "./Models/ITask";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { Map } from "immutable";
import { DataLayer } from "./TheClaw";
import { IChronometricDB, ICreateChange, IUpdateChange } from "./IChronometricDB";

export enum DatabaseChangeType {
	Create = 1,
	Update = 2,
	Delete = 3,
}

export class SubscribableDbAdapter {
	private readonly groupSubscriberSource = "ChronometricDB_Groups";
	private readonly taskSubscriberSource = "ChronometricDB_Tasks";
	private readonly tagSubscriberSource = "ChronometricDB_Tags";
	private readonly taskTagLingSubscriberSource = "ChronometricDB_TaskTagLinks";
	private readonly timeEntrySubscriberSource = "ChronometricDB_TimeEntries";
	private readonly taskMetadataSubscriberSource = "ChronometricDB_TaskMetadatas";

	constructor(private db: IChronometricDB, private readonly dataLayer: DataLayer) {
		console.debug("Loading subscribables from DB");
		void this.db
			.Ready()
			.then(() => this.loadSubscribablesFromDb())
			.then(() => {
				console.debug("DB ready");
				window.dispatchEvent(new Event("dbready"));
			})
			.then(() => {
				db.onChange((changes) => {
					changes.forEach((value) => {
						const tablename = value.table.toLowerCase().replaceAll("_", "");
						console.debug(tablename + " DB changes being applied to subscribables");
						switch (tablename) {
							case "timeentries":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.TimeEntries.Load(value.key, fef.obj as ITimeEntry);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.TimeEntries.Delete(value.key);
										break;
								}
								break;
							case "groups":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.Groups.Load(value.key, fef.obj as ITimeEntrySet);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.Groups.Delete(value.key);
										break;
								}
								break;
							case "taskmetadatas":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.TaskMetadatas.Load(value.key, fef.obj as ITaskMetadata);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.TaskMetadatas.Delete(value.key);
										break;
								}
								break;
							case "tags":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.Tags.Load(value.key, fef.obj as ITag);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.Tags.Delete(value.key);
										break;
								}
								break;
							case "tasktaglinks":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.TaskTagLinks.Load(value.key, fef.obj as ITaskTagLink);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.TaskTagLinks.Delete(value.key);
										break;
								}
								break;
							case "tasks":
								switch (value.type as number) {
									case DatabaseChangeType.Create:
									case DatabaseChangeType.Update:
										const fef = (value as unknown) as ICreateChange | IUpdateChange;
										this.dataLayer.Tasks.Load(value.key, fef.obj as ITask);
										break;
									case DatabaseChangeType.Delete:
										this.dataLayer.Tasks.Delete(value.key);
										break;
								}
								break;
							default:
								console.error("Unknown table: " + tablename);
								break;
						}
					});
				});
			});
	}

	public async loadSubscribablesFromDb() {
		await Promise.all([
			this.db.Tasks.getAll().then((tasks) => {
				this.dataLayer.Tasks.RemoveAll(this.taskSubscriberSource);
				this.dataLayer.Tasks.BulkSet(
					tasks.reduce((acc, curr) => {
						return acc.set(KeyHelper.GetTaskKey(curr), curr);
					}, Map<string, ITask>()),
					this.taskSubscriberSource,
					true
				);
			}),
			this.db.Tags.getAll().then((tags) => {
				this.dataLayer.Tags.RemoveAll(this.tagSubscriberSource);
				this.dataLayer.Tags.BulkSet(
					tags.reduce((acc, curr) => {
						return acc.set(KeyHelper.GetTagKey(curr), curr);
					}, Map<string, ITag>()),
					this.tagSubscriberSource,
					true
				);
			}),
			this.db.TaskTagLinks.getAll().then((tags) => {
				this.dataLayer.TaskTagLinks.RemoveAll(this.taskTagLingSubscriberSource);
				this.dataLayer.TaskTagLinks.BulkSet(
					tags.reduce(
						(acc, curr) => {
							return acc.set(KeyHelper.GetTaskTagLinkKey(curr), curr);
						},

						Map<string, ITaskTagLink>()
					),
					this.taskTagLingSubscriberSource,
					true
				);
			}),
			this.db.TimeEntries.getAll().then((tasks) => {
				this.dataLayer.TimeEntries.RemoveAll(this.timeEntrySubscriberSource);
				this.dataLayer.TimeEntries.BulkSet(
					tasks
						.filter((task) => !task.deletedWhen)
						.reduce((acc, curr) => {
							return acc.set(curr.timeEntryGuid, curr);
						}, Map<string, ITimeEntry>()),
					this.timeEntrySubscriberSource,
					true
				);
			}),
			this.db.Groups.getAll().then((tasks) => {
				this.dataLayer.Groups.RemoveAll(this.groupSubscriberSource);
				this.dataLayer.Groups.BulkSet(
					tasks
						.filter((task) => !task.deletedWhen)
						.reduce((acc, curr) => {
							return acc.set(curr.timeEntrySetGuid, curr);
						}, Map<string, ITimeEntrySet>()),
					this.groupSubscriberSource,
					true
				);
			}),
			this.db.TaskMetadatas.getAll().then((tasks) => {
				this.dataLayer.TaskMetadatas.RemoveAll(this.taskMetadataSubscriberSource);
				this.dataLayer.TaskMetadatas.BulkSet(
					tasks.reduce((acc, curr) => {
						return acc.set(KeyHelper.GetTaskKey(curr), curr);
					}, Map<string, ITaskMetadata>()),
					this.taskMetadataSubscriberSource,
					true
				);
			}),
		]);
	}
}
