import { useEffect, useState } from "react";
import { ISubscribableCollection } from "../Data/Subscribable";
import { Source } from "../Data/Source";
import { Subscriber } from "../Data/Subscriber";
import { useForceUpdateWithTimestamp } from "./useForceUpdate";
import { v4 as uuid } from "uuid";
import { List } from "immutable";
import { DateTime } from "luxon";
import { SpecialCause } from "../Data/SpecialCause";

export interface IUseSubscribableCollectionType<T> {
	Get(key: string): T | undefined;
	All(): List<T>;
	Set(key: string, value: T): void;
	Remove(key: string): void;
	RemoveAll(): void;
	timestamp: DateTime;
}

export function useSubscribableCollection<T>(
	subscribable: ISubscribableCollection<T>,
	source: Source,
	key?: string,
	includeSpecialCauses?: SpecialCause[]
): IUseSubscribableCollectionType<T> {
	const { forceUpdate, timestamp } = useForceUpdateWithTimestamp();
	const [internalSource] = useState(() => source || `unnamed${uuid()}`);

	useEffect(() => {
		const subscriber: Subscriber<string> = {
			callback: forceUpdate,
			source: internalSource,
			includeSpecialCauses,
			key,
		};

		console.debug(`Subscribing ${internalSource} to ${subscribable.constructor.name}: ${key || "all"}`);

		subscribable.Subscribe(subscriber);
		return () => {
			console.debug(`Un-subscribing ${internalSource} from ${subscribable.constructor.name}: ${key || "all"}`);

			subscribable.UnSubscribe(subscriber);
		};
	}, [subscribable, internalSource, key, includeSpecialCauses]);

	return {
		Get: (key: string) => subscribable.Get(key),
		All: () => subscribable.All(),
		Set: (key: string, value: T) => subscribable.Set(key, value, internalSource),
		Remove: (key: string) => subscribable.Remove(key, internalSource),
		RemoveAll: () => subscribable.RemoveAll(internalSource),
		timestamp,
	};
}
