import { Subscriber, SubscriberCallback } from "./Subscriber";
import { Source } from "./Source";
import { Map, List, Set } from "immutable";
import { DateTime } from "luxon";
import { SpecialCause } from "./SpecialCause";
import nameof from "ts-nameof.macro";
export type SubscribableKey = string; // | string[];

export class SubscribableCollection<TModel> implements ISubscribableCollection<TModel> {
	protected static inBatch = 0;
	protected static notificationBatch: Map<
		string,
		{
			callback: SubscriberCallback<string>;
			action: DataActionEnum;
			keys: Set<string>;
			source: Source;
		}
	> = Map();
	/**
	 * StartTransaction
	 *
	 */
	public static StartTransaction() {
		this.inBatch++;
	}

	/**
	 * FinishTransaction
	 */
	public static FinishTransaction() {
		this.inBatch--;

		if (this.inBatch < 0)
			throw new Error(
				`${nameof(this.FinishTransaction)} called without corresponding call to ${nameof(this.StartTransaction)}`
			);

		if (this.inBatch === 0) {
			for (const item of this.notificationBatch.values()) {
				console.debug(`Notifying ${item.source}`);

				item.callback(item.action, item.keys);
			}
			this.notificationBatch = Map();
		}
	}
	protected Subscribers: Set<Subscriber<string>>;
	protected Values: Map<string, TModel>;
	protected LastUpdatedWhen: DateTime;

	constructor(initialSubscribers: Subscriber<string>[], initalValues: Map<string, TModel> = Map()) {
		this.Subscribers = Set(initialSubscribers);
		this.Values = initalValues;
		this.LastUpdatedWhen = DateTime.utc();
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public ClearCaches(source?: Source): void {}

	public All(): List<TModel> {
		return this.Values.toList();
	}
	public Get(key: string): TModel | undefined {
		return this.Values.get(key, undefined);
	}
	public Set(key: string, value: TModel, source: Source, cause?: SpecialCause): void {
		const isUpdate = this.Values.has(key);
		this.Values = this.Values.set(key, value);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		this.Notify(source, isUpdate ? DataActionEnum.Update : DataActionEnum.Create, Set([key]), cause);
	}
	public Remove(key: string, source: Source, cause?: SpecialCause): void {
		console.warn(`PROPER DELETION of key: ${key} initiated by source: ${source}`);
		this.Values = this.Values.delete(key);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		this.Notify(source, DataActionEnum.Delete, Set([key]), cause);
	}
	public RemoveAll(source?: Source): void {
		this.Values = Map();
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		if (source) this.Notify(source, DataActionEnum.DeleteAll);
	}

	public BulkSet(data: Map<string, TModel>, source?: Source, notify?: boolean, specialCause?: SpecialCause) {
		const keys = data.keySeq().toSet();
		if (keys.count() === 0) return;
		this.Values = this.Values.merge(data);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		if (source && notify) {
			this.Notify(source, DataActionEnum.BulkSet, keys, specialCause);
		}
	}

	public Subscribe(subscriber: Subscriber<string>) {
		this.Subscribers = this.Subscribers.add(subscriber);

		this.scheduleDuplicateSourceCheck();
	}

	private duplicateSourceCheckTimeout: number | undefined;
	private scheduleDuplicateSourceCheck() {
		//		clearTimeout(this.duplicateSourceCheckTimeout);
		//	this.duplicateSourceCheckTimeout = setTimeout(() => {
		const duplicateSources = this.Subscribers.filter((a) =>
			this.Subscribers.some((b) => a !== b && a.source === b.source)
		);
		if (duplicateSources.count() > 0) {
			console.error(
				`Multiple subscribers to ${this.constructor.name} with the same source. This can lead to stale data being used if they aren't the same instance`,
				duplicateSources.map((x) => x.source).toArray()
			);
		}
		//});
	}

	public UnSubscribe(toUnSubscribe: Subscriber<string>) {
		this.Subscribers = this.Subscribers.delete(toUnSubscribe);
	}
	public Notify(
		source: Source,
		action: DataActionEnum,
		keys: Set<string> = Set<string>(),
		specialCause?: SpecialCause
	) {
		this.Subscribers.forEach((subscriber) => {
			if (
				subscriber.source !== source &&
				(!Array.isArray(source) || !source.includes(subscriber.source)) &&
				(!subscriber.key || keys?.includes(subscriber.key)) &&
				(specialCause === undefined ||
					subscriber.allCauses ||
					subscriber.includeSpecialCauses?.includes(specialCause)) &&
				(!specialCause || !subscriber.excludeSpecialCauses?.includes(specialCause))
			) {
				if (SubscribableCollection.inBatch)
					SubscribableCollection.notificationBatch = SubscribableCollection.notificationBatch.set(subscriber.source, {
						callback: subscriber.callback,
						action,
						keys,
						source: subscriber.source,
					});
				else {
					console.debug(`Notifying ${subscriber.source}`);
					subscriber.callback(action, keys);
				}
			}
		});
	}
}

export enum DataActionEnum {
	Create,
	Update,
	Delete,
	DeleteAll,
	BulkSet,
}

export interface ISubscribableCollection<TModel> {
	ClearCaches(source?: Source): void;
	All(): List<TModel>;
	Get(key: string): TModel | undefined;
	Set(key: string, value: TModel, source: Source, cause?: SpecialCause): void;
	Remove(key: string, source: Source, cause?: SpecialCause): void;
	RemoveAll(source?: Source): void;
	BulkSet(data: Map<string, TModel>, source?: Source, notify?: boolean, specialCause?: SpecialCause): void;
	Subscribe(subscriber: Subscriber<string>): void;
	UnSubscribe(toUnSubscribe: Subscriber<string>): void;
	Notify(source: Source, action: DataActionEnum, keys: Set<string>, specialCause?: SpecialCause): void;
}
