export enum FilterModes {
	noFilter = "mode-search",
	search = "mode-search",
	recent = "mode-orderby-recent",
	mostUsed = "mode-orderby-most-used",
	dueDate = "mode-orderby-due-date",
	new = "mode-orderby-new",
	favourites = "mode-filter-favourites",
	todo = "mode-orderby-todo",
	ascending = "mode-orderby-task-asc",
	descending = "mode-orderby-task-desc",
}
