import { HostNameHelper, HostType } from "./HostNameHelper";

export class EndpointManager {
	public AuthApiBase =
		HostNameHelper.GetHost(process.env.REACT_APP_HOSTNAME_OVERRIDE ?? window.location.hostname, HostType.Auth) + "/api";

	public TimeApiBase =
		HostNameHelper.GetHost(process.env.REACT_APP_HOSTNAME_OVERRIDE ?? window.location.hostname, HostType.Time) + "/api";

	public TimeHubBase =
		HostNameHelper.GetHost(process.env.REACT_APP_HOSTNAME_OVERRIDE ?? window.location.hostname, HostType.Time) + "/hub";

	public TaskApiBase =
		HostNameHelper.GetHost(process.env.REACT_APP_HOSTNAME_OVERRIDE ?? window.location.hostname, HostType.Task) + "/api";
}
