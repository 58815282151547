import React, { useState, useEffect, useContext, useRef } from "react";
import { TimeEntrySummaryProps } from "./TimeEntrySummary.Interface";
import "./TimeEntrySummary.scss";
import { DateTime } from "luxon";
import { TimeEntryComment } from "../TimeEntryComment/TimeEntryComment";
import { TasklistModal } from "../TasklistModal/TasklistModal";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { Guid } from "../../../Data/Guid";
import { EditTimeModal } from "../EditTimeModal/EditTimeModal";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { useContextMenuParams, ContextMenu } from "../ContextMenu/ContextMenu";
import { GetRelativeClickCoordinates } from "../../../GlobalUtils/PositionUtils";
import { LiveTimeLabelForEntries } from "../LiveTimeLabel/LiveTimeLabelForEntries";
import { ContextMenuOrigin } from "../ContextMenu/ContextMenu.interface";

export const TimeEntrySummary = (props: TimeEntrySummaryProps) => {
	const modalContext = useContext(ModalContext);
	const timelineContext = useContext(TimelineContext);
	const initialRelinkTaskGuid = " ";
	const [isLinked, setIsLinked] = useState<boolean>(false);
	const [relinkTaskGuid, setRelinkTaskGuid] = useState<Guid | undefined>(initialRelinkTaskGuid);

	useEffect(() => {
		setIsLinked(CheckIsLinked());
	}, [props.MyGroup && props.MyGroup.taskExternalId]);

	useEffect(() => {
		if (relinkTaskGuid !== initialRelinkTaskGuid) {
			void LinkTImeEntryToRelinkTask();
		}
	}, [relinkTaskGuid]);

	function FormatTime(time?: DateTime) {
		return time ? time.toLocaleString(DateTime.TIME_SIMPLE) : "NOW";
	}

	function CheckIsLinked() {
		return props.MyGroup && props.MyGroup.taskExternalId ? true : false;
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams(
		ContextMenuOrigin.TopRight
	);
	const scrollRef = useRef<HTMLDivElement>(null);

	function OpenContextMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();

		if (!scrollRef.current) return;

		const menuCoords = GetRelativeClickCoordinates(e.nativeEvent, scrollRef.current);

		const menuOptions = [
			{ display: CheckIsLinked() ? "Relink" : "Link", callback: () => RelinkTimeEntry() },
			{ display: "Edit", callback: OpenEditTimeModal, disabled: false },
			{ display: "Edit in Timeline", callback: () => OpenTimelineAtTimeEntry(), disabled: false },
			{ display: "Delete", callback: () => DeleteTimeEntry() },
		];

		setMenuCoords(menuCoords);
		setMenuOptions(menuOptions);
		setShowContextMenu(true);
	}

	function RelinkTimeEntry() {
		OpenTasklistModal();
	}

	function DeleteTimeEntry() {
		props.compressedTimeEntry.forEach((te) => {
			props.theHook.RemoveTimeEntry(te.timeEntryGuid);
		});
	}

	function OpenTasklistModal() {
		if (props.MyGroup) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetModalComponent,
				payload: (
					<TasklistModal
						timeEntrySetGuid={props.MyGroup.timeEntrySetGuid}
						linkingTimeEntries={props.compressedTimeEntry}
					/>
				),
			});

			modalContext.dispatch({
				type: ModalContextDispatchActionType.OpenModal,
				payload: undefined,
			});
		}
	}

	async function LinkTImeEntryToRelinkTask() {
		const group = await props.theHook.NewGroup();
		if (group) {
			props.theHook.LinkTimeEntryToGroup(props.compressedTimeEntry[0].timeEntryGuid, group.timeEntrySetGuid);

			if (relinkTaskGuid && relinkTaskGuid !== initialRelinkTaskGuid) {
				await props.theHook.LinkGroup(group.timeEntrySetGuid, relinkTaskGuid);
			}
		}
		setRelinkTaskGuid(initialRelinkTaskGuid);
	}

	function OpenTimelineAtTimeEntry() {
		props.theHook.OpenEditTimeline(
			timelineContext.state.currentDayOffset ? timelineContext.state.currentDayOffset : 0,
			props.compressedTimeEntry[0]
		);
	}

	function OpenEditTimeModal() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <EditTimeModal timeEntry={props.compressedTimeEntry[0]} />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	// NEED TO ACTUALLY CALCULATE TOTAL TIME IN THE SUMMARY, SINCE CURRENTLY ANY BREAKS ARE DISPLAYED AS RECORDED TIME

	return (
		<div
			ref={scrollRef}
			key={props.compressedTimeEntry[0].timeEntryGuid}
			className={"time-entry-summary " + (isLinked ? " linked" : " unlinked")}
		>
			<button className="time-wrapper" onClick={OpenEditTimeModal}>
				<div className={"start-end-time"}>
					<span className="start-time">
						{FormatTime(props.compressedTimeEntry[0].startedWhen)} -{" "}
						{FormatTime(props.compressedTimeEntry[props.compressedTimeEntry.length - 1].endedWhen)}
					</span>
				</div>
				<div className={"time-in-hours"}>
					<span className="time-value">
						<LiveTimeLabelForEntries compressedTimeEntry={props.compressedTimeEntry} />
					</span>
					{/* <span className="is-running-icon"></span> */}
				</div>
			</button>
			<TimeEntryComment MyTimeEntry={props.compressedTimeEntry[0]} editable={true} />
			{/* {props.showGroupIndicator ?
				<section className="group-indicator-wrapper">
					<button
						className={
							"group-indicator group-indicator-" +
							GetGroupIndicator().colourId +
							" time-unit-summary-group-indicator"
						}
						title={"Time entry group " + props.MyTimeEntry.timeEntrySetGuid}
						onClick={OpenModalGroup}
					/>
				</section>
				:
				<></>
			} */}
			<button className="time-entry-context-menu" onClick={OpenContextMenu}>
				...
			</button>{" "}
			{/* TODO: Replace with context menu vertical dots icon */}
			{ContextMenu(contextMenuParams)}
		</div>
	);
};
