import {
	DatabaseChangeType,
	IChronometricDB,
	IChronometricTable,
	IDatabaseChange,
	ITimeEntriesTable,
} from "./IChronometricDB";
import { ITag } from "./Models/ITag";
import { ITask } from "./Models/ITask";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";

import { addRxPlugin, createRxDatabase, RxChangeEvent, RxCollection, RxDatabase } from "rxdb";
import { List, Set } from "immutable";
import { ITimeEntry } from "./Models/ITimeEntry";
import { DateTime } from "luxon";
import { Instant } from "./Instant";

declare global{
	interface Number {
		cmAsInt(): number;
	}
}

Number.prototype.cmAsInt = function(): number{
	return Math.floor(this as number);
}

export class ChronometricRxDB implements IChronometricDB {
	private changeHandlers = Set<(changes: IDatabaseChange[]) => void>();
	offChange(callback: (changes: IDatabaseChange[]) => void): void {
		this.changeHandlers = this.changeHandlers.delete(callback);
	}
	async delete(): Promise<void> {
		await this.db.destroy();
	}
	onChange(callback: (changes: IDatabaseChange[]) => void): void {
		this.changeHandlers = this.changeHandlers.add(callback);
	}

	async waitForLeadership() {
		await this.db.waitForLeadership();
	}

	private db!: RxDatabase;
	timeEntries!: ITimeEntriesTable;
	get TimeEntries(): ITimeEntriesTable {
		return this.timeEntries;
	}
	tasks!: IChronometricTable<ITask>;
	get Tasks(): IChronometricTable<ITask> {
		return this.tasks;
	}
	tags!: IChronometricTable<ITag>;
	get Tags(): IChronometricTable<ITag> {
		return this.tags;
	}
	taskTagLinks!: IChronometricTable<ITaskTagLink>;
	get TaskTagLinks(): IChronometricTable<ITaskTagLink> {
		return this.taskTagLinks;
	}
	groups!: IChronometricTable<ITimeEntrySet>;
	get Groups(): IChronometricTable<ITimeEntrySet> {
		return this.groups;
	}
	taskMetadatas!: IChronometricTable<ITaskMetadata>;
	get TaskMetadatas(): IChronometricTable<ITaskMetadata> {
		return this.taskMetadatas;
	}
	isReady = false;

	public async Ready(): Promise<void> {
		if (this.isReady) {
			return;
		}
		await this.Pause(100);
		await this.Ready();
	}

	private Pause(timeout: number) {
		return new Promise((resolve) => {
			setTimeout(resolve, timeout);
		});
	}

	async init() {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		addRxPlugin(require("pouchdb-adapter-idb"));

		// if (process.env.NODE_ENV === "development") await removeRxDatabase("chronometric_rx", "idb");

		this.db = await createRxDatabase({
			name: "chronometric_rx", // <- name
			adapter: "idb", // <- storage-adapter
			multiInstance: true, // <- multiInstance (optional, default: true)
		});

		const guidRegex = "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$";
		await this.db.addCollections({
			groups: {
				schema: {
					version: 0,
					type: "object",
					title: "The root schema",
					description: "The root schema comprises the entire JSON document.",
					required: [
						"timeEntrySetGuid",
						"userId",
						"timeEntrySetStatusId",
						"timeEntrySetBillingStatusId",
						"createdWhen",
						"lastUpdatedWhen",
						"exportMaxAttemptsReached",
						"exportAttemptCount",
					],
					indexes: ["deletedWhen"],
					properties: {
						timeEntrySetGuid: {
							type: "string",
							pattern: guidRegex,
							primary: true,
						},
						userId: {
							type: "integer",
						},
						name: {
							type: "string",
						},
						timeEntrySetStatusId: {
							type: "integer",
						},
						timeEntrySetBillingStatusId: {
							type: "integer",
						},
						createdWhen: {
							type: "integer",
						},
						deletedWhen: {
							type: "integer",
						},
						lastUpdatedWhen: {
							type: "integer",
						},
						metadata: {
							type: "string",
						},
						taskExternalId: {
							type: "string",
						},
						taskIntegrationGuid: {
							type: "string",
							pattern: guidRegex,
						},
						lastSyncedWhen: {
							type: "integer",
						},
						exportMaxAttemptsReached: {
							type: "boolean",
							default: false,
						},
						exportAttemptCount: {
							type: "integer",
						},
						lastExportedWhen: {
							type: "integer",
						},
						lastExportErrorOccurredWhen: {
							type: "integer",
						},
						queuedForExportWhen: {
							type: "integer",
						},
					},
				},
			},
			time_entries: {
				schema: {
					version: 0,
					type: "object",
					required: ["timeEntryGuid", "timeEntrySetGuid", "startedWhen", "lastUpdatedWhen"],
					indexes: ["startedWhen", "deletedWhen"],
					properties: {
						timeEntryGuid: {
							type: "string",
							pattern: guidRegex,
							primary: true,
						},
						timeEntrySetGuid: {
							type: "string",
							pattern: guidRegex,
						},
						startedWhen: {
							type: "integer",
						},
						endedWhen: {
							type: "integer",
						},
						lastUpdatedWhen: {
							type: "integer",
						},
						deletedWhen: {
							type: "integer",
						},
						comment: {
							type: "string",
						},
						timeZone: {
							type: "string",
						},
						timeOffsetMinutes: {
							type: "integer",
						},
						lastSyncedWhen: {
							type: "integer",
						},
					},
				},
			},
			tasks: {
				schema: {
					version: 0,
					type: "object",
					properties: {
						key: {
							type: "string",
							primary: true,
						},
						externalId: {
							type: "string",
						},
						integrationGuid: {
							type: "string",
							pattern: guidRegex,
						},
						name: {
							type: "string",
						},
						metadata: {
							type: "string",
						},
						status: {
							type: "string",
						},
						assignee: {
							type: "string",
						},
						description: {
							type: "string",
						},
						createdWhen: {
							type: "integer",
						},
						lastUpdatedWhen: {
							type: "integer",
						},
						externalCreatedWhen: {
							type: "integer",
						},
						dueWhen: {
							type: "integer",
						},
						linkUrl: {
							type: "string",
						},
						syncedToServer: {
							type: "number",
						},
					},
					required: [
						"externalId",
						"integrationGuid",
						"name",
						"metadata",
						"status",
						"createdWhen",
						"lastUpdatedWhen",
						"externalCreatedWhen",
					],
				},
			},
			task_metadatas: {
				schema: {
					version: 0,
					type: "object",
					properties: {
						key: {
							type: "string",
							primary: true,
						},
						externalId: {
							type: "string",
						},
						integrationGuid: {
							type: "string",
							pattern: guidRegex,
						},
						usageCount: {
							type: "number",
						},
						isFavorite: {
							type: "boolean",
						},
						isTodo: {
							type: "boolean",
						},
						createdWhen: {
							type: "integer",
						},
						lastUpdatedWhen: {
							type: "integer",
						},
						usageCountCalculatedWhen: {
							type: "integer",
						},
					},
					required: ["externalId", "integrationGuid", "usageCount"],
				},
			},
			tags: {
				schema: {
					version: 0,
					type: "object",
					properties: {
						key: {
							type: "string",
							primary: true,
						},
						externalId: {
							type: "string",
						},
						integrationGuid: {
							type: "string",
							pattern: guidRegex,
						},
						value: {
							type: "string",
						},
						createdWhen: {
							type: "integer",
						},
						lastUpdatedWhen: {
							type: "integer",
						},
						tagTypeCodeName: {
							type: "string",
						},
					},
					required: ["externalId", "integrationGuid", "value", "createdWhen", "lastUpdatedWhen", "tagTypeCodeName"],
				},
			},
			task_tag_links: {
				schema: {
					version: 0,
					type: "object",
					properties: {
						key: {
							type: "string",
							primary: true,
						},
						taskExternalId: {
							type: "string",
						},
						tagExternalId: {
							type: "string",
						},
						integrationGuid: {
							type: "string",
							pattern: guidRegex,
						},
					},
					required: ["taskExternalId", "tagExternalId", "integrationGuid"],
				},
			},
		});

		this.subscribeTable<TimeEntryRxStorable>("time_entries");
		this.timeEntries = new RxTimeEntries(
			this.db.collections["time_entries"],
			(te) => {
				return {
					...te,
					deletedWhen: te.deletedWhen?.toMillis()?.cmAsInt() ?? -1,
					endedWhen: te.endedWhen?.toMillis().cmAsInt() ?? -1,
					lastSyncedWhen: te.lastSyncedWhen?.toMillis().cmAsInt() ?? -1,
					startedWhen: te.startedWhen.toMillis().cmAsInt(),
					lastUpdatedWhen: te.lastUpdatedWhen.toMillis().cmAsInt(),
				};
			},
			(storable) => {
				return {
					...storable,
					deletedWhen:
						storable.deletedWhen === -1
							? undefined
							: DateTime.fromMillis(storable.deletedWhen, { zone: storable.timeZone }),
					endedWhen:
						storable.endedWhen === -1
							? undefined
							: DateTime.fromMillis(storable.endedWhen, { zone: storable.timeZone }),
					lastSyncedWhen: storable.lastSyncedWhen === -1 ? undefined : Instant.fromMillis(storable.lastSyncedWhen),
					startedWhen: DateTime.fromMillis(storable.startedWhen, { zone: storable.timeZone }),
					lastUpdatedWhen: Instant.fromMillis(storable.lastUpdatedWhen),
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "time_entries",
					},
				])
		);

		this.subscribeTable<TimeEntrySetRxStorable>("groups");
		this.groups = new RxDBChronometricTable<ITimeEntrySet, TimeEntrySetRxStorable>(
			this.db.collections["groups"],
			(tes) => {
				return {
					...tes,
					deletedWhen: tes.deletedWhen?.toMillis().cmAsInt() ?? -1,
					lastSyncedWhen: tes.lastSyncedWhen?.toMillis().cmAsInt() ?? -1,
					lastUpdatedWhen: tes.lastUpdatedWhen.toMillis().cmAsInt(),
					createdWhen: tes.createdWhen.toMillis().cmAsInt(),
					lastExportedWhen: tes.lastExportedWhen?.toMillis().cmAsInt() ?? -1,
					queuedForExportWhen: tes.queuedForExportWhen?.toMillis().cmAsInt() ?? -1,
					lastExportErrorOccurredWhen: tes.lastExportErrorOccurredWhen?.toMillis().cmAsInt() ?? -1,
				};
			},
			(storable) => {
				return {
					...storable,
					deletedWhen: storable.deletedWhen === -1 ? undefined : Instant.fromMillis(storable.deletedWhen),
					lastSyncedWhen: storable.lastSyncedWhen === -1 ? undefined : Instant.fromMillis(storable.lastSyncedWhen),
					lastUpdatedWhen: Instant.fromMillis(storable.lastUpdatedWhen),
					createdWhen: Instant.fromMillis(storable.createdWhen),
					lastExportedWhen:
						storable.lastExportedWhen === -1 ? undefined : Instant.fromMillis(storable.lastExportedWhen),
					queuedForExportWhen:
						storable.queuedForExportWhen === -1 ? undefined : Instant.fromMillis(storable.queuedForExportWhen),
					lastExportErrorOccurredWhen:
						storable.lastExportErrorOccurredWhen === -1
							? undefined
							: Instant.fromMillis(storable.lastExportErrorOccurredWhen),
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "groups",
					},
				])
		);

		this.subscribeTable<TaskRxStorable>("tasks");
		this.tasks = new RxDBChronometricTable<ITask, TaskRxStorable>(
			this.db.collections["tasks"],
			(task) => {
				return {
					...task,
					key: task.integrationGuid + "|" + task.externalId,
					lastUpdatedWhen: task.lastUpdatedWhen.toMillis().cmAsInt(),
					createdWhen: task.createdWhen.toMillis().cmAsInt(),
					dueWhen: task.dueWhen?.toMillis().cmAsInt() ?? -1,
					externalCreatedWhen: task.externalCreatedWhen.toMillis().cmAsInt(),
				};
			},
			(storable) => {
				return {
					...storable,
					lastUpdatedWhen: Instant.fromMillis(storable.lastUpdatedWhen),
					createdWhen: Instant.fromMillis(storable.createdWhen),
					dueWhen: storable.dueWhen === -1 ? undefined : Instant.fromMillis(storable.dueWhen),
					externalCreatedWhen: Instant.fromMillis(storable.externalCreatedWhen),
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "tasks",
					},
				])
		);

		this.subscribeTable<TaskMetadataRxStorable>("task_metadatas");
		this.taskMetadatas = new RxDBChronometricTable<ITaskMetadata, TaskMetadataRxStorable>(
			this.db.collections["task_metadatas"],
			(tm) => {
				return {
					...tm,
					key: tm.integrationGuid + "|" + tm.externalId,
					lastUpdatedWhen: tm.lastUpdatedWhen?.toMillis().cmAsInt() ?? -1,
					createdWhen: tm.createdWhen?.toMillis().cmAsInt() ?? -1,
					usageCountCalculatedWhen: tm.usageCountCalculatedWhen?.toMillis().cmAsInt() ?? -1,
				};
			},
			(storable) => {
				return {
					...storable,
					lastUpdatedWhen: storable.lastUpdatedWhen === -1 ? undefined : Instant.fromMillis(storable.lastUpdatedWhen),
					createdWhen: storable.createdWhen === -1 ? undefined : Instant.fromMillis(storable.createdWhen),
					usageCountCalculatedWhen:
						storable.usageCountCalculatedWhen === -1
							? undefined
							: Instant.fromMillis(storable.usageCountCalculatedWhen),
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "task_metadatas",
					},
				])
		);

		this.subscribeTable<TagRxStorable>("tags");
		this.tags = new RxDBChronometricTable<ITag, TagRxStorable>(
			this.db.collections["tags"],
			(tm) => {
				return {
					...tm,
					key: tm.integrationGuid + "|" + tm.externalId,
					lastUpdatedWhen: tm.lastUpdatedWhen.toMillis().cmAsInt(),
					createdWhen: tm.createdWhen.toMillis().cmAsInt(),
				};
			},
			(storable) => {
				return {
					...storable,
					lastUpdatedWhen: Instant.fromMillis(storable.lastUpdatedWhen),
					createdWhen: Instant.fromMillis(storable.createdWhen),
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "tags",
					},
				])
		);

		this.subscribeTable<TaskTagLinkRxStorable>("task_tag_links");
		this.taskTagLinks = new RxDBChronometricTable<ITaskTagLink, TaskTagLinkRxStorable>(
			this.db.collections["task_tag_links"],
			(tm) => {
				return {
					...tm,
					key: tm.integrationGuid + "|" + tm.taskExternalId + "|" + tm.tagExternalId,
				};
			},
			(storable) => {
				return {
					...storable,
				};
			},
			(key, obj) =>
				this.executeChangeCallbacks([
					{
						key,
						obj,
						type: DatabaseChangeType.Update,
						table: "task_tag_links",
					},
				])
		);
	}

	private subscribeTable<T>(tableName: string) {
		return;
		this.db.collections[tableName].$.subscribe((evt: RxChangeEvent<T>) => {
			this.executeChangeCallbacks([
				{
					key: evt.rxDocument?.primary,
					obj: evt.rxDocument?.toJSON(),
					type: DatabaseChangeType.Update,
					table: tableName,
				},
			]);
		});
	}

	private executeChangeCallbacks(changes: IDatabaseChange[]) {
		this.changeHandlers.forEach((callback) => {
			callback(changes);
		});
	}

	constructor() {
		void this.init().then(() => {
			this.isReady = true;
		});
	}
}

type ITimeEntryRxStorableBase = Omit<
	ITimeEntry,
	"startedWhen" | "endedWhen" | "lastUpdatedWhen" | "deletedWhen" | "lastSyncedWhen"
>;
export class TimeEntryRxStorable implements ITimeEntryRxStorableBase {
	timeEntrySetGuid!: string;
	timeEntryGuid!: string;
	comment!: string;
	timeZone?: string;
	timeOffsetMinutes?: number;
	startedWhen!: number;
	endedWhen = -1;
	lastUpdatedWhen!: number;
	deletedWhen = -1;
	lastSyncedWhen = -1;
}

type ITimeEntrySetRxStorableBase = Omit<
	ITimeEntrySet,
	| "createdWhen"
	| "lastUpdatedWhen"
	| "deletedWhen"
	| "lastSyncedWhen"
	| "lastExportedWhen"
	| "queuedForExportWhen"
	| "lastExportErrorOccurredWhen"
>;
class TimeEntrySetRxStorable implements ITimeEntrySetRxStorableBase {
	name?: string;
	metadata?: string;
	timeEntrySetGuid!: string;
	userId!: number;
	timeEntrySetColourId?: number;
	timeEntrySetIconId?: number;
	timeEntrySetStatusId!: number;
	timeEntrySetBillingStatusId!: number;
	taskExternalId?: string;
	taskIntegrationGuid?: string;
	totalExportedSeconds?: number;
	secondsToExport?: number;
	lastExportErrorData?: string;
	lastExportErrorTypeCodeName?: string;
	exportMaxAttemptsReached!: boolean;
	exportAttemptCount!: number;
	createdWhen!: number;
	lastExportErrorOccurredWhen = -1;
	deletedWhen = -1;
	lastUpdatedWhen!: number;
	lastSyncedWhen = -1;
	lastExportedWhen = -1;
	queuedForExportWhen = -1;
}

type ITaskMetadataRxStorableBase = Omit<ITaskMetadata, "createdWhen" | "lastUpdatedWhen" | "usageCountCalculatedWhen">;
export class TaskMetadataRxStorable implements ITaskMetadataRxStorableBase {
	externalId!: string;
	integrationGuid!: string;
	usageCount!: number;
	isFavorite?: boolean;
	isTodo?: boolean;
	createdWhen = -1;
	lastUpdatedWhen = -1;
	usageCountCalculatedWhen = -1;
}

type ITaskRxStorableBase = Omit<ITask, "createdWhen" | "lastUpdatedWhen" | "dueWhen" | "externalCreatedWhen">;
export class TaskRxStorable implements ITaskRxStorableBase {
	key!: string;
	externalId!: string;
	integrationGuid!: string;
	name!: string;
	metadata!: string;
	status!: string;
	assignee!: string;
	description!: string;
	linkUrl!: string;
	syncedToServer!: number;
	createdWhen!: number;
	lastUpdatedWhen!: number;
	dueWhen = -1;
	externalCreatedWhen!: number;
}

type ITagRxStorableBase = Omit<ITag, "createdWhen" | "lastUpdatedWhen">;
export class TagRxStorable implements ITagRxStorableBase {
	key!: string;
	externalId!: string;
	integrationGuid!: string;
	value!: string;
	tagTypeCodeName!: string;
	createdWhen!: number;
	lastUpdatedWhen!: number;
}

export class TaskTagLinkRxStorable implements ITaskTagLink {
	key!: string;
	taskExternalId!: string;
	tagExternalId!: string;
	integrationGuid!: string;
}

class RxDBChronometricTable<T, TStorable> implements IChronometricTable<T> {
	constructor(
		protected readonly table: RxCollection<TStorable>,
		protected readonly toStorable: (obj: T) => TStorable,
		protected readonly fromStorable: (obj: TStorable) => T,
		protected readonly onChange?: (key?: string, obj?: T) => unknown
	) {
		this.table.$.subscribe((evt: RxChangeEvent<TStorable>) => {
			if (this.onChange && evt.rxDocument) {
				const storable = evt.rxDocument.toJSON();
				this.onChange(evt.rxDocument.primary, storable && this.fromStorable(storable));
			}
		});
	}

	async getAll(): Promise<List<T>> {
		const newLocal = await this.table.find().exec();
		return List(newLocal.map((x) => this.fromStorable(x.toJSON())));
	}
	async put(value: T, key: string): Promise<void> {
		await this.table.atomicUpsert(this.toStorable(value));
	}
	async get(key: string): Promise<T | undefined> {
		const newLocal = await this.table.findOne(key).exec();
		if (newLocal === null) return undefined;
		return this.fromStorable(newLocal.toJSON());
	}
	async bulkPut(values: List<T>, modifier: (item: T) => Promise<T> = (item) => Promise.resolve(item)): Promise<void> {
		await Promise.all(
			values.map(async (item) => {
				const modified = await modifier(item);
				const storable = this.toStorable(modified);
				await this.table.atomicUpsert(storable);
			})
		);
	}
}

class RxTimeEntries extends RxDBChronometricTable<ITimeEntry, TimeEntryRxStorable> implements ITimeEntriesTable {
	async GetTodaysLatestEntry(dayStartOffsetHours: number): Promise<ITimeEntry> {
		return (await this.GetTodaysEntries(dayStartOffsetHours)).last();
	}
	async GetTodaysEntries(dayStartOffsetHours: number): Promise<List<ITimeEntry>> {
		const startOfDay = Instant.now().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = Instant.now().endOf("day").plus({ hours: dayStartOffsetHours });
		return List(
			await this.getAllNotDeletedQuery()
				.or([{ endedWhen: -1 }, { startedWhen: { $gte: startOfDay.toMillis().cmAsInt(), $lte: endOfDay.toMillis().cmAsInt() } }])
				.sort("startedWhen")
				.exec()
		).map((x) => this.fromStorable(x.toJSON()));
	}
	async AllNotDeleted() {
		return List(await this.getAllNotDeletedQuery().exec()).map((x) => this.fromStorable(x.toJSON()));
	}

	private getAllNotDeletedQuery() {
		return this.table.find().where("deletedWhen").eq(-1);
	}
}
