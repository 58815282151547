import React, { useState } from "react";
import "./LiveTimeLabel.scss";
import { LiveTimeLabelForSetsProps } from "./LiveTimeLabelForSets.Interface";
import nameof from "ts-nameof.macro";
import { useGroupTime } from "./useGroupTime";
import { v4 } from "uuid";

export const LiveTimeLabelForSets = (props: LiveTimeLabelForSetsProps) => {
	const [source] = useState(() => `${nameof(LiveTimeLabelForSets)}_${v4()}`);
	const { totalTime, timeUnitIndicator } = useGroupTime(props.timeEntrySetGuid, props.groupsDayOffset, source);

	return (
		<>
			<span className="total-time">{totalTime}</span>
			<span className="time-unit-indicator">{timeUnitIndicator}</span>
		</>
	);
};
