import { useState, useRef, useEffect, ChangeEvent, FormEvent } from "react";
import { FormDef } from "./useForm";
import { Dictionary } from "../../Data/Dictionary";

export function useField(
	name: string,
	form: FormDef,
	{
		defaultValue,
		validations,
		fieldsToValidateOnChange,
	}: {
		defaultValue: string | undefined;
		validations: Validator[];
		fieldsToValidateOnChange: string[];
	}
) {
	const [value, setValue] = useState(defaultValue);
	const [errors, setErrors] = useState<string[]>([]);
	const [pristine, setPristine] = useState(true);
	const [validating, setValidating] = useState(false);
	const validateCounter = useRef(0);
	const validate = async () => {
		const validateIteration = ++validateCounter.current;
		setValidating(true);
		const formData = form.getFormData();
		let errorMessages = await Promise.all(validations.map((validation) => validation(formData, name)));
		errorMessages = errorMessages.filter((errorMsg) => !!errorMsg);
		if (validateIteration === validateCounter.current) {
			// this is the most recent invocation
			setErrors(errorMessages as string[]);
			setValidating(false);
		}
		const fieldValid = errorMessages.length === 0;
		return fieldValid;
	};
	useEffect(() => {
		if (pristine) return; // Avoid validate on mount
		void form.validateFields(fieldsToValidateOnChange);
	}, [value]);
	const field = {
		name,
		value,
		errors,
		setErrors,
		pristine,
		onChange: (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (pristine) {
				setPristine(false);
			}
			setValue(e.currentTarget.value);
		},
		validate,
		validating,
	} as FieldDef;
	form.addField(field);
	return field;
}

export type FieldDef = {
	name: string;
	value: string | undefined;
	errors: string[];
	setErrors: (errors: string[]) => void;
	pristine: boolean;
	onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	validate: () => Promise<boolean>;
	validating: boolean;
	submit: () => void;
};

export type Validator = (formData: Dictionary<string>, input: string) => string | false;
