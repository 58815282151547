import React, { useState, useRef, useContext } from "react";
import { TaskSearchProps } from "./TaskSearch.Interface";
import "./TaskSearch.scss";
import { FilterButton } from "./FilterButton/FilterButton";
import { SearchContext } from "../../../Context/SearchContext/SearchContext";
import { FilterModes } from "../../../Context/SearchContext/FilterModes";
import { SearchContextDispatchActionType } from "../../../Context/SearchContext/SearchContextDispatchActionType";
import { EditModeContext } from "../../../Context/EditModeContext/EditModeContext";

export function TaskSearch(props: TaskSearchProps) {
	// const Tasks = useTasks(
	// 	nameof(TaskSearch),
	// );
	const searchContext = useContext(SearchContext);
	const editModeContext = useContext(EditModeContext);
	const [input, setInput] = useState<string>("");
	const inputRef = useRef<HTMLInputElement>(null);

	// TODO: Get rid of this as it never changes
	const [searchControlsOpen] = useState<boolean>(true);

	function StartSearch() {
		searchContext.dispatch({ type: SearchContextDispatchActionType.search, payload: input });
		searchContext.dispatch({
			type: SearchContextDispatchActionType.setSearchFilter,
			payload: FilterModes.search,
		});
	}

	function ClearSearch() {
		setInput("");
	}

	function SetFilter(filter: FilterModes) {
		if (filter !== FilterModes.new && searchContext.state.filterMode === filter) {
			return;
		} else {
			searchContext.dispatch({
				type: SearchContextDispatchActionType.setSearchFilter,
				payload: filter,
			});
		}
	}

	function InputValueSelect(event: React.FocusEvent<HTMLInputElement>) {
		event.target.select();
	}

	function InputFocus(ev: React.FocusEvent<HTMLInputElement>) {
		// setShowFilterButtons(false);

		// groupContext.dispatch({
		// 	type: GroupContextDispatchActionType.SetActiveTaskGUID,
		// 	payload: undefined
		// })
		// groupContext.dispatch({
		// 	type: GroupContextDispatchActionType.FindActiveGroup,
		// 	payload: undefined
		// })

		searchContext.dispatch({
			type: SearchContextDispatchActionType.setSearchFilter,
			payload: FilterModes.noFilter,
		});
		InputValueSelect(ev);
	}

	function KeyDownSubmit(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === 13) {
			StartSearch();
		}
	}

	return (
		<section
			id="search-controls-wrapper"
			className={
				searchContext.state.filterMode +
				" " +
				(searchControlsOpen ? "search-controls-open " : "search-controls-closed ") +
				// + (showFilterButtons ? "search-input-closed" : "search-input-open")
				(input.length > 0 ? "search-emphasis " : "")
			}
		>
			<section className="search-controls-container">
				<section className="search-input-wrapper">
					<section className="search-input-container">
						<button className="search-clear icon-only" onClick={ClearSearch}>
							<span className="sr-only">Clear search</span>
						</button>
						<input
							className="search-input"
							type="text"
							ref={inputRef}
							value={input}
							onChange={(ev) => setInput(ev.target.value)}
							placeholder="Search..."
							required
							onFocus={(ev) => InputFocus(ev)}
							onKeyDown={KeyDownSubmit}
						/>
						<button className="search-submit primary color-text" type="submit" onClick={StartSearch}>
							<span className="sr-only">Submit search</span>
						</button>
						{/* <button
							className="show-filters icon-only"
							onClick={ShowFilterButtons}
							disabled={editModeContext.state.editModeStatus}
						>
							<span className="sr-only">Show filters</span>
						</button> */}
					</section>
				</section>

				<section className="task-filter-wrapper">
					<section className="task-filter-container">
						<FilterButton
							onClick={SetFilter}
							className="filter filter-search"
							filterMode={FilterModes.search}
							text="Search"
							currentFilterMode={searchContext.state.filterMode}
						/>
						<FilterButton
							onClick={SetFilter}
							className="filter filter-recent"
							filterMode={FilterModes.recent}
							text="Recent"
							currentFilterMode={searchContext.state.filterMode}
						/>
						<FilterButton
							onClick={SetFilter}
							className="filter filter-favourites"
							filterMode={FilterModes.favourites}
							text="Favourites"
							currentFilterMode={searchContext.state.filterMode}
						/>
						<FilterButton
							onClick={SetFilter}
							className="filter filter-most-used"
							filterMode={FilterModes.mostUsed}
							text="Most Used"
							currentFilterMode={searchContext.state.filterMode}
						/>
						<FilterButton
							onClick={SetFilter}
							className="filter filter-due-date"
							filterMode={FilterModes.dueDate}
							text="Due Date"
							currentFilterMode={searchContext.state.filterMode}
						/>
						<FilterButton
							onClick={SetFilter}
							className="filter filter-new"
							filterMode={FilterModes.new}
							text="New"
							currentFilterMode={searchContext.state.filterMode}
						/>
					</section>
				</section>
			</section>
		</section>
	);
}
