import React from "react";
import { AlertContextModel } from "./AlertContextModel";
import { AlertContextAction } from "./AlertContextAction";
import { AlertContextDispatchActionType } from "./AlertContextDispatchActionType";
import { AlertContextProviderProps } from "./AlertContext.Interface";
import { Ticker } from "../../Hooks/useTime";

export const AlertContext = React.createContext({
	state: new AlertContextModel(),
	dispatch: {} as React.Dispatch<AlertContextAction>,
});

function AlertReducer(state: AlertContextModel, action: AlertContextAction) {
	switch (action.type) {
		case AlertContextDispatchActionType.SetAlertComponent:
			return { ...state, alertComponent: action.payload as JSX.Element | JSX.Element[] };
		case AlertContextDispatchActionType.OpenAlert:
			Ticker.PauseTick();
			return { ...state, showAlert: true };
		case AlertContextDispatchActionType.CloseAlert:
			Ticker.ResumeTick();
			return { ...state, alertComponent: undefined, showAlert: false, preventClose: true };
		case AlertContextDispatchActionType.SetPreventClose:
			return { ...state, preventClose: action.payload as boolean };
		case AlertContextDispatchActionType.SetCustomCloseCallback:
			return { ...state, customCloseCallback: action.payload as () => boolean };
		case AlertContextDispatchActionType.SetCustomClassName:
			return { ...state, customClassName: action.payload as string };
		default:
			return { ...state };
	}
}

export const AlertContextProvider = (props: AlertContextProviderProps) => {
	const [state, dispatch] = React.useReducer(AlertReducer, {
		alertComponent: undefined,
		showAlert: false,
		preventClose: true,
		customCloseCallback: undefined,
		customClassName: "",
	});
	const value = { state, dispatch };

	return <AlertContext.Provider value={value}>{props.children}</AlertContext.Provider>;
};
