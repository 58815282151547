/* eslint-disable react/prop-types */
import React from "react";
import "./MenuButton.scss";
import { MenuButtonProps } from "./MenuButton.Interface";

// eslint-disable-next-line react/display-name
export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>((props, ref) => {
	// function PreventDefault(e:Event) {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// }

	function HandleCloseMenu() {
		props.CloseMenu();
	}

	// TODO: re-order the span components so that the button is on top of everything.
	// Currently pressing the menu-icon-bars will trigger then click-off function rather than the normal close (since it isn't the forwarded ref)

	return (
		<div className={`${props.parent || ""}-button menu-button-container`}>
			{props.menuState ? (
				<button className={"menu-button close-menu"} ref={ref} onClick={HandleCloseMenu} aria-label="Open menu">
					{/* <button className="overlaying-button"  /> */}
					<span className="menu-icon-bar" />
					<span className="menu-icon-bar" />
				</button>
			) : (
				<button className={"menu-button open-menu"} onClick={props.OpenMenu} aria-label="Close menu">
					<span className="menu-icon-bar" />
					<span className="menu-icon-bar" />
					<span className="menu-icon-bar" />
				</button>
			)}
		</div>
	);
});
