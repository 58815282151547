import { singletonHook } from "react-singleton-hook";
import { useContext, useEffect, useState } from "react";
import { ImContext } from "../../../Context/DbContext/DbContext";

function usePlayingTimeImpl() {
	const im = useContext(ImContext);

	const [playingTime, setPlayingTime] = useState<{ isPlaying: boolean, currentTimeEntryGUID: string | undefined, currentTimeEntrySetGUID: string | undefined }>({
		isPlaying: false,
		currentTimeEntryGUID: undefined,
		currentTimeEntrySetGUID: undefined,
	});

	const fn = async () => {
		console.debug("Calculating playing time entry");
		const db = im.db;

		const runningEntry = await db.TimeEntries.GetTodaysLatestEntry(0);
		const runningTimeEntrySet = runningEntry ? await db.Groups.get(runningEntry.timeEntrySetGuid) : undefined;

		const newCurrentTimeEntryGUID =
			runningTimeEntrySet &&
				runningEntry &&
				(!runningTimeEntrySet.queuedForExportWhen ||
					runningTimeEntrySet.queuedForExportWhen < runningTimeEntrySet.lastUpdatedWhen)
				? runningEntry.timeEntryGuid
				: undefined;

		const newCurrentTimeEntrySetGUID =
			runningTimeEntrySet &&
				runningEntry &&
				(!runningTimeEntrySet.queuedForExportWhen ||
					runningTimeEntrySet.queuedForExportWhen < runningTimeEntrySet.lastUpdatedWhen)
				? runningEntry.timeEntrySetGuid
				: undefined;

		const newIsPlaying = !!runningEntry && !!runningTimeEntrySet && !runningEntry.endedWhen;

		setPlayingTime({
			isPlaying: newIsPlaying,
			currentTimeEntryGUID: newCurrentTimeEntryGUID,
			currentTimeEntrySetGUID: newCurrentTimeEntrySetGUID,
		});
	}

	useEffect(() => {
		im.db.onChange(fn);
		return () => { im.db.offChange(fn); }
	}, [fn, im])

	return playingTime
}

export const usePlayingTime = singletonHook({
	isPlaying: false,
	currentTimeEntryGUID: undefined,
	currentTimeEntrySetGUID: undefined,
}, usePlayingTimeImpl);

