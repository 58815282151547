import React, { useMemo } from "react";
import { useTime } from "../../../Hooks/useTime";
import { TimerProps } from "./Timer.Interface";
import "./Timer.scss";
import { Duration } from "luxon";
import { usePlayingTime } from "../Playhead/usePlayingTime";

// NOTE: change props.isDecimalFormat, to a format prop that can have multiple options using ENUMS

export const Timer = (props: TimerProps) => {
	const { isPlaying } = usePlayingTime() || {};

	const time = useTime();

	return useMemo(() => {
		const timeToDisplay =
			props.timeToDisplay || (props.getTimeToDisplay && props.getTimeToDisplay()) || Duration.fromMillis(0);
		let hours = 0;
		let minutes = 0;
		let seconds = 0;
		let decimalTime = "0.0h";

		if (props.isDecimalFormat) {
			const hours = timeToDisplay.as("hours");

			decimalTime = hours.toFixed(1) + "h";
		} else {
			const newLocal = timeToDisplay.shiftTo("hours", "minutes", "seconds");
			hours = newLocal.hours;
			minutes = newLocal.minutes;
			seconds = Math.floor(newLocal.seconds);
		}

		return (
			<div className={"timer-display " + props.className}>
				{/*+ (isPlaying ? " is-playing" : " not-playing")}>*/}
				{props.isDecimalFormat ? (
					decimalTime
				) : (
					<>
						<span className="time-indicator">{Math.floor(hours / 10)}</span>
						<span className="time-indicator">{hours - Math.floor(hours / 10) * 10}</span>
						<span className="time-unit-indicator">:</span>
						<span className="time-indicator">{Math.floor(minutes / 10)}</span>
						<span className="time-indicator">{minutes - Math.floor(minutes / 10) * 10}</span>
						<span className="time-unit-indicator">:</span>
						<span className="time-indicator">{Math.floor(seconds / 10)}</span>
						<span className="time-indicator">{seconds - Math.floor(seconds / 10) * 10}</span>
						{/* <span className="time-unit-indicator">s</span> */}
					</>
				)}
			</div>
		);
	}, [isPlaying, props.getTimeToDisplay ? time.second : undefined, props.timeToDisplay, props.getTimeToDisplay]);
};
