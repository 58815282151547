import React, { useContext } from "react";
import "../GroupIndicators/group-indicators.scss";
import "./Playhead.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { Timer } from "../Timer/Timer";
import nameof from "ts-nameof.macro";
import { useGroups } from "../../../Hooks/useGroups";
import { TheHookModel } from "../../../Hooks/TheHook/TheHookModel";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { GroupNameLabel } from "../GroupNameLabel/GroupNameLabel";
import { useCalculatedTimes } from "../../../Hooks/useCalculatedTimes";
import { usePlayingTime } from "./usePlayingTime";
import { Task } from "../Task/Task";
import { Tasklist } from "../../../Containers/Tasklist/Tasklist";
import { TasklistView } from "../../Views/TasklistView/TasklistView";
import { useTasks } from "../../../Hooks/useTasks";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { TasklistModal } from "../TasklistModal/TasklistModal";

enum PlayingStatus {
	isPlaying = " is-playing",
	notPlaying = " not-playing",
}

export const Playhead = () => {
	const theHook: TheHookModel = TheHook(nameof(Playhead));
	const timeControls = useTimeControls(nameof(Playhead));
	const { isPlaying, currentTimeEntryGUID, currentTimeEntrySetGUID } = usePlayingTime() || {};

	//const { isPlaying, currentTimeEntryGUID, currentTimeEntrySetGUID } = usePlayingTime()||{};

	const TimeEntries = useTimeEntries(nameof(Playhead), currentTimeEntryGUID);
	const Groups = useGroups(nameof(Playhead), currentTimeEntrySetGUID);
	const group = currentTimeEntrySetGUID ? Groups.Get(currentTimeEntrySetGUID) : undefined;
	const dayStarted = !!currentTimeEntryGUID;
	const { groupTotalTime } = useCalculatedTimes();
	const modalContext = useContext(ModalContext);

	function OpenEditModeTimeline() {
		if (currentTimeEntryGUID) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);
			theHook.OpenEditTimeline(0, currentTimeEntry);
		}
	}

	function OpenLinkingModal() {
		if (currentTimeEntrySetGUID) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetModalComponent,
				payload: <TasklistModal timeEntrySetGuid={currentTimeEntrySetGUID} />,
			});

			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetCustomClassName,
				payload: "search-modal",
			});

			modalContext.dispatch({
				type: ModalContextDispatchActionType.OpenModal,
				payload: undefined,
			});
		}
	}

	return (
		<div className={"playhead-container "}>
			<div className="playhead-info-wrapper">
				<div className={"playhead-task-info"}>
					{/*Task Label */}
					<div className="task-name">
						<GroupNameLabel group={group} />
					</div>
					{/*Link Task Button - Need to implement correct button*/}
					<button
						className={"link circle icon-only"}
						onClick={OpenLinkingModal}
						aria-label="Link to task"
						title="Link to task"
						disabled={!dayStarted}
					></button>
				</div>
			</div>
			<div className="timer-controls-wrapper">
				<div className="timer-controls-container">
					{/* PLAY/PAUSE BUTTON */}
					<button
						onClick={() => timeControls.TogglePlay()}
						className={"play circle icon-only" + (isPlaying ? PlayingStatus.isPlaying : PlayingStatus.notPlaying)}
						aria-label={isPlaying ? "Pause task" : "Play task"}
						title={isPlaying ? "Pause task" : "Play task"}
					/>
					<Timer timeToDisplay={groupTotalTime} className={"playhead-timer"} />
					{/* TIME SWITCH BUTTON */}
					<button
						onClick={() => timeControls.SwitchTo()}
						className={"switch circle icon-only"}
						aria-label="Switch to new task"
						title="Switch to new task"
						disabled={!dayStarted}
					/>
				</div>
			</div>
		</div>
	);
};
