import { TimeApiConnection } from "./TimeApiConnection";
import { IntegrationApiConnection } from "./IntegrationApiConnection";
import { TimeHubConnection } from "./TimeHubConnection";
import { SyncManager } from "./SyncManager";
import { AuthManager } from "./AuthManager";
import { TimeSource } from "./TimeSource";
import { ConnectionManager } from "./ConnectionManager";
import { EndpointManager } from "./EndpointManager";
import { DataLayer } from "./TheClaw";
import { SubscribableDbAdapter } from "./SubscribableDbAdapter";
import nameof from "ts-nameof.macro";
import { IChronometricDB } from "./IChronometricDB";
import { TimeHelper } from "./TimeHelper";
import { ChronometricRxDB } from "./ChronometricRxDB";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { TaskHelper } from "./TaskHelper";
import { EditModeClaw } from "../Hooks/TheHook/TheEditModeClaw";
import { TimeCalculationHelpers } from "../Hooks/TimeCalculationHelpers";

export class ImSingleton {
	private static im?: InstanceManager;

	public static getIm(): InstanceManager {
		if (!this.im) this.im = new InstanceManager();

		return this.im;
	}
}

export class InstanceManager {
	constructor() {
		// eslint-disable-next-line no-debugger
		// debugger;
		console.debug("Constructing " + nameof(InstanceManager));

		this.em = new EndpointManager();
		this.auth = new AuthManager(this.em);
		this.cm = new ConnectionManager(this.auth);
		this.db = new ChronometricRxDB();
		this.int = new IntegrationApiConnection(this.cm, this.auth, this.db, new TimeHelper(), this.em);
		this.timeHub = new TimeHubConnection(this.auth, this.em);
		this.timeApi = new TimeApiConnection(this.timeHub, new TimeHelper());
		this.timeSource = new TimeSource(this.timeApi);
		this.syncManager = new SyncManager(this.timeApi, this.int, this.timeSource, this.auth, this.timeHub, this.db);
		this.dataLayer = new DataLayer(this.db, this.timeSource);
		this.dbAdapter = new SubscribableDbAdapter(this.db, this.dataLayer);
		this.taskHelper = new TaskHelper(this.dataLayer, this.timeSource);
		this.editModeDataLayer = new EditModeClaw(this.timeSource);
		this.timeCalculationHelpers = new TimeCalculationHelpers(this.timeSource, this.dataLayer);
	}

	public readonly db: IChronometricDB;
	public readonly dataLayer: DataLayer;
	public readonly em: EndpointManager;
	public readonly auth: AuthManager;
	public readonly cm: ConnectionManager;
	public readonly int: IntegrationApiConnection;
	public readonly timeHub: TimeHubConnection;
	public readonly timeApi: TimeApiConnection;
	public readonly timeSource: TimeSource;
	public readonly dbAdapter: SubscribableDbAdapter;
	public readonly syncManager: SyncManager;
	public readonly taskHelper: TaskHelper;
	public readonly editModeDataLayer: EditModeClaw;
	public readonly timeCalculationHelpers: TimeCalculationHelpers;
	public serviceWorker: ServiceWorker | null = null;
	public auth0Obj: Auth0ContextInterface<User> | null = null;
	public setAuth0(auth0Obj: Auth0ContextInterface<User>) {
		console.debug("InstanceManager setAuth0", auth0Obj)
		this.auth0Obj = auth0Obj;
		this.auth.setAuth0(auth0Obj);
	}
}

//export class InstanceManager {
	//public static get db(): IChronometricDB {
		//return ImSingleton.getIm().db;
	//}
	//public static get dataLayer(): DataLayer {
		//return ImSingleton.getIm().dataLayer;
	//}
	//public static get em() {
		//return ImSingleton.getIm().em;
	//}
	//public static get auth() {
		//return ImSingleton.getIm().auth;
	//}
	//public static get cm() {
		//return ImSingleton.getIm().cm;
	//}
	//public static get int() {
		//return ImSingleton.getIm().int;
	//}
	//public static get timeHub() {
		//return ImSingleton.getIm().timeHub;
	//}
	//public static get timeApi() {
		//return ImSingleton.getIm().timeApi;
	//}
	//public static get timeSource() {
		//return ImSingleton.getIm().timeSource;
	//}
	//public static get dbAdapter() {
		//return ImSingleton.getIm().dbAdapter;
	//}
	//public static get syncManager() {
		//return ImSingleton.getIm().syncManager;
	//}
	//public static get serviceWorker(): ServiceWorker | null {
		//return ImSingleton.getIm().serviceWorker;
	//}
	//public static set serviceWorker(sw: ServiceWorker | null) {
		//ImSingleton.getIm().serviceWorker = sw;
	//}
//}

//declare global {
	//interface Window {
		//im: InstanceManager;
	//}
//}

//window.im = ImSingleton.getIm();
