import { InstanceManager } from "../Data/InstanceManager";
import { Source } from "../Data/Source";

import { useSubscribableCollection } from "./useSubscribable";
import { useContext } from "react";
import { ImContext } from "../Context/DbContext/DbContext";
/**
 *
 * Causes re-render when any item in TaskMetadatas changes
 * @param source
 */
export function useTaskMetadatas(source: Source, key?: string) {
	const im = useContext(ImContext);
	return useSubscribableCollection(im.dataLayer.TaskMetadatas, source, key);
}
