import React, { useEffect } from "react";
import "./GlobalStyles/global-styles.scss";
import { EditModeContextProvider } from "./Context/EditModeContext/EditModeContext";
import { UndoRedoContextProvider } from "./Context/UndoRedoContext/UndoRedoContext";
import { SearchContextProvider } from "./Context/SearchContext/SearchContext";
import { TreeContextProvider } from "./Context/TreeContext/TreeContext";
import { TimelineContextProvider } from "./Context/TimelineContext/TimelineContext";
import { GroupTaskModalContextProvider } from "./Context/GroupTaskModalContext/GroupTaskModalContext";
import { ForgottenTimeEntryContextProvider } from "./Context/ForgottenTimeEntryContext/ForgottenTimeEntryContext";
import { ModalContextProvider } from "./Context/ModalContext/ModalContext";
import { ViewManager } from "./Components/ViewManager";
import { ViewContextProvider } from "./Context/ViewContext/ViewContext";
import { Modal } from "./Components/Library/Modal/Modal";
import { ExportContextProvider } from "./Context/ExportContext/ExportContext";
import { GlobalSettingsContextProvider } from "./Context/GlobalSettingsContext/GlobalSettingsContext";
import { HaloContextProvider } from "./Context/HaloContext/HaloContext";
import { AlertContextProvider } from "./Context/AlertContext/AlertContext";
import { AlertBox } from "./Components/Library/AlertBox/AlertBox";
import { SelfTest } from "./GlobalUtils/TimeUtils";
import "./GlobalUtils/MakeOverrunTimeEntry";

import "@fortawesome/fontawesome-pro/css/all.css";
import { useAuth0 } from "@auth0/auth0-react";
import { ImContextProvider } from "./Context/DbContext/DbContext";

SelfTest();

export function App() {
	if (window.location.hostname.endsWith(".io")) {
		window.location.href = window.location.href.replace(".io", ".app");
	}

	const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

	useEffect(() => {
		if (!isLoading && !isAuthenticated) void loginWithRedirect();
	}, [isLoading, isAuthenticated])

	return (
		<ImContextProvider>
			<GlobalSettingsContextProvider>
				<EditModeContextProvider>
					<UndoRedoContextProvider>
						<SearchContextProvider>
							<TreeContextProvider>
								<TimelineContextProvider>
									<GroupTaskModalContextProvider>
										<ExportContextProvider>
											<ForgottenTimeEntryContextProvider>
												<ModalContextProvider>
													<AlertContextProvider>
														<ViewContextProvider>
															<HaloContextProvider>
																<ViewManager />
																<Modal />
																<AlertBox />
															</HaloContextProvider>
														</ViewContextProvider>
													</AlertContextProvider>
												</ModalContextProvider>
											</ForgottenTimeEntryContextProvider>
										</ExportContextProvider>
									</GroupTaskModalContextProvider>
								</TimelineContextProvider>
							</TreeContextProvider>
						</SearchContextProvider>
					</UndoRedoContextProvider>
				</EditModeContextProvider>
			</GlobalSettingsContextProvider>
		</ImContextProvider>
	);
}
