/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, FormEvent } from "react";
import { Dictionary } from "../../../types/Dictionary";
import { FieldDef } from "./useField";

export function useForm({ onSubmit }: { onSubmit(formData: Dictionary<string>, valid: boolean): any }) {
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const fields: FieldDef[] = [];
	const validateFields = async (fieldNames?: string[]) => {
		let fieldsToValidate;
		if (fieldNames instanceof Array) {
			fieldsToValidate = fields.filter((field) => fieldNames.includes(field.name));
		} else {
			//if fieldNames not provided, validate all fields
			fieldsToValidate = fields;
		}
		const fieldsValid = await Promise.all(fieldsToValidate.map((field) => field.validate()));
		const formValid = fieldsValid.every((isValid) => isValid === true);
		return formValid;
	};
	const getFormData = () => {
		return fields.reduce((formData, f) => {
			formData[f.name] = f.value || "";
			return formData;
		}, {} as Dictionary<string>);
	};
	return {
		onSubmit: async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			setSubmitting(true);
			setSubmitted(true); // User has attempted to submit form at least once
			const formValid = await validateFields();
			const returnVal = await onSubmit(getFormData(), formValid);
			setSubmitting(false);
			return returnVal;
		},
		isValid: () => fields.every((f) => f.errors.length === 0),
		addField: (field: FieldDef) => fields.push(field),
		getFormData,
		validateFields,
		submitted,
		submitting,
	} as FormDef;
}

export type FormDef = {
	onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
	isValid: () => boolean;
	addField: (field: FieldDef) => number;
	getFormData: () => Dictionary<string>;
	validateFields: (fields: string[]) => Promise<boolean>;
	submitted: boolean;
	submitting: boolean;
};
