import React, { useContext } from "react";
import { GroupNameLabelProps } from "./GroupNameLabel.Interface";
import { EditableLabel } from "../EditableLabel/EditableLabel";
import "./GroupNameLabel.scss";
import { useGroups } from "../../../Hooks/useGroups";
import nameof from "ts-nameof.macro";
import { KeyHelper } from "../../../Data/KeyHelper";
import { ImContext } from "../../../Context/DbContext/DbContext";

export const GroupNameLabel = (props: GroupNameLabelProps) => {
	const defaultGroupName = "Unassigned";
	const im = useContext(ImContext);
	const Groups = useGroups(nameof(GroupNameLabel), props.group?.timeEntrySetGuid);

	// TODO: Move this into the Hook
	function SetGroupName(newName: string) {
		if (props.group) {
			Groups.Set(props.group.timeEntrySetGuid, {
				...props.group,
				name: newName,
				lastUpdatedWhen: im.timeSource.GetUtcTime(),
			});
		}
	}

	function GetGroupName() {
		let groupName = "";
		if (props.group) {
			const task = im.dataLayer.Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(props.group));

			if (task) {
				groupName = task.name;
			} else if (props.group.name && props.group.name !== "") {
				groupName = props.group.name;
			} else if (!props.editable) {
				groupName = defaultGroupName;
			}
		}

		return groupName;
	}

	return props.editable ? (
		<EditableLabel
			value={GetGroupName()}
			setValue={SetGroupName}
			placeholderLabel={defaultGroupName}
			className={"group-name-label "}
		/>
	) : (
		<div className={"group-name-label "}>{GetGroupName()}</div>
	);
};
