import React, { useState, useEffect, useContext } from "react";
import "./GroupTaskModal.scss";
import { useTasks } from "../../../Hooks/useTasks";
import { GroupSummary } from "../GroupSummary/GroupSummary";
import nameof from "ts-nameof.macro";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import "../EditableLabel/EditableLabel.scss";
import { Halo } from "../Halo/Halo";
import { DateTime, Duration } from "luxon";
import { HaloShape } from "../Halo/Halo";
import { GroupTaskModalContext } from "../../../Context/GroupTaskModalContext/GroupTaskModalContext";
import { GroupTaskModalContextDispatchActionType } from "../../../Context/GroupTaskModalContext/GroupTaskModalContextDispatchActionType";
import { KeyHelper } from "../../../Data/KeyHelper";
import { TaskHelper } from "../../../Data/TaskHelper";
import { GroupNameLabel } from "../GroupNameLabel/GroupNameLabel";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { ImContext } from "../../../Context/DbContext/DbContext";

export const GroupTaskModal = () => {
	const im = useContext(ImContext);
	const theHook = TheHook(nameof(GroupTaskModal));
	const Tasks = useTasks(nameof(GroupTaskModal));
	const TimeEntries = useTimeEntries(nameof(GroupTaskModal));
	const groupTaskModal = useContext(GroupTaskModalContext);
	const [groupTotalTime, setGroupTotalTime] = useState<Duration>(() => Duration.fromMillis(0));
	const secondsInAnHour = 3600;
	const timeControls = useTimeControls(nameof(GroupTaskModal));

	// useEffect(()=>{
	// 	if(groupTaskModal.state.modalTask){}
	// 		SetupTask();

	// 	if(groupTaskModal.state.modalGroup){}
	// 		SetupTimeEntrySet();
	// },[groupTaskModal.state.showModal])

	useEffect(() => {
		if (groupTaskModal.state.modalTask) SetupTask();
	}, [groupTaskModal.state.modalTask]);

	useEffect(() => {
		if (groupTaskModal.state.modalGroup) SetupTimeEntrySet();
	}, [groupTaskModal.state.modalGroup]);

	function SetupTask() {
		if (groupTaskModal.state.modalTask) {
			if (!groupTaskModal.state.modalGroup) {
				const group = theHook
					.GetTodaysGroups()
					.find((g) => KeyHelper.GetTimeEntrySetTaskKey(g) === KeyHelper.GetTaskKey(groupTaskModal.state.modalTask!));

				groupTaskModal.dispatch({
					type: GroupTaskModalContextDispatchActionType.SetModalGroup,
					payload: group,
				});
			}
		}
	}

	function SetupTimeEntrySet() {
		if (groupTaskModal.state.modalGroup) {
			setGroupTotalTime(timeControls.GetTotalGroupTime(groupTaskModal.state.modalGroup.timeEntrySetGuid));

			if (!groupTaskModal.state.modalTask) {
				if (groupTaskModal.state.modalGroup.taskExternalId) {
					const task = Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(groupTaskModal.state.modalGroup));

					if (task) {
						const result = im.taskHelper.SearchResultifyTask(task);

						groupTaskModal.dispatch({
							type: GroupTaskModalContextDispatchActionType.SetModalTask,
							payload: result,
						});
					}
				}
			}
		}
	}

	function CurrentDay() {
		let currentDay = "TODAY";

		if (groupTaskModal.state.modalDayOffset !== 0) {
			if (groupTaskModal.state.modalDayOffset === 1) {
				currentDay = "YESTERDAY";
			} else {
				currentDay = im.timeSource
					.GetLocalTime()
					.minus({ days: groupTaskModal.state.modalDayOffset })
					.toFormat("ccc d LLL");
			}
		}

		return currentDay;
	}

	function GoBackADay() {
		groupTaskModal.dispatch({
			type: GroupTaskModalContextDispatchActionType.SetModalDayOffset,
			payload: groupTaskModal.state.modalDayOffset ? groupTaskModal.state.modalDayOffset + 1 : 1,
		});
	}

	function GoForwardADay() {
		if (groupTaskModal.state.modalDayOffset !== 0) {
			groupTaskModal.dispatch({
				type: GroupTaskModalContextDispatchActionType.SetModalDayOffset,
				payload: groupTaskModal.state.modalDayOffset ? groupTaskModal.state.modalDayOffset - 1 : 0,
			});
		}
	}

	return (
		<section className="group-task-modal">
			<section className="header-section">
				<div
					className={
						"group-indicator group-indicator-" +
						(groupTaskModal.state.modalGroup && groupTaskModal.state.modalGroup.timeEntrySetColourId
							? groupTaskModal.state.modalGroup.timeEntrySetColourId.toString()
							: "")
					}
				></div>
				<div className="name">
					{groupTaskModal.state.modalTask ? (
						<div className="task-name">{groupTaskModal.state.modalTask.name}</div>
					) : (
						<GroupNameLabel editable={true} group={groupTaskModal.state.modalGroup} />
					)}
				</div>
			</section>

			<section className="modal-content-wrapper">
				<div className="group-section">
					{groupTaskModal.state.modalGroup ? (
						<div className="group-info">
							<section className="group-info-header">
								<div className="group-total-time">
									<span className="icon"></span>
									<h2 className="title">Total Time</h2>
									<span className="total-time-value">
										{groupTotalTime.as("hours").toFixed(1)}
										<span className="time-unit-indicator">h</span>
									</span>
								</div>

								<button className="task-sync square sz-2 icon-only" title="Sync task time">
									<span className="sr-only">Sync task time</span>
								</button>
							</section>
							{groupTaskModal.state.modalTask ? (
								<div className="day-controls">
									<button className="go-back-day" onClick={GoBackADay}>
										{"<"}
									</button>
									<div className="date-calander">{CurrentDay()}</div>
									<button
										className="go-forward-day"
										onClick={GoForwardADay}
										disabled={groupTaskModal.state.modalDayOffset === 0}
									>
										{">"}
									</button>
								</div>
							) : (
								<></>
							)}
							<section className="group-summary-contrast">
								<GroupSummary
									TimeEntrySetGuid={groupTaskModal.state.modalGroup.timeEntrySetGuid}
									DayOffset={groupTaskModal.state.modalDayOffset}
								/>
							</section>
							MMM
							<section className="halo-wrapper">
								<div className="current-time">
									{im.timeSource.GetLocalTime().toLocaleString(DateTime.TIME_SIMPLE)}
								</div>
								<Halo timeEntrySetGuid={groupTaskModal.state.modalGroup.timeEntrySetGuid} haloShape={HaloShape.line} />
								<div className="start-time">
									{TimeEntries.GetEarliestEntry()?.startedWhen.toLocaleString(DateTime.TIME_SIMPLE)}
								</div>
							</section>
						</div>
					) : (
						<div className="no-group">This task has no running time</div>
					)}
				</div>
				<div className="task-section">
					{groupTaskModal.state.modalTask ? (
						<div className="task-info">
							<div className="project-name">{/*groupTaskgroupTaskModal.state.modalTask.projectName*/}</div>
							<div className="task-status">STATUS</div>
							<div className="task-description">{groupTaskModal.state.modalTask.description}</div>
						</div>
					) : (
						<div className="no-task">NO ASSIGNED TASK</div>
					)}
				</div>
			</section>
		</section>
	);
};
