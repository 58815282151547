export enum HostType {
	Auth,
	Time,
	Task,
	Export,
	Search,
	Report,
	Improve,
}

export class HostNameHelper {
	public static readonly HostPrefixes = {
		[HostType.Auth]: "manage-api",
		[HostType.Time]: "time-api",
		[HostType.Task]: "task-api",
		[HostType.Export]: "export",
		[HostType.Search]: "search",
		[HostType.Report]: "report",
		[HostType.Improve]: "improve",
	};

	public static readonly LocalhostPorts = {
		[HostType.Auth]: 5000,
		[HostType.Time]: 5001,
		[HostType.Task]: 5002,
		[HostType.Export]: 5002,
		[HostType.Search]: 5002,
		[HostType.Report]: 5003,
		[HostType.Improve]: 5003,
	};

	public static GetHost(requestHost: string, hostType: HostType): string {
		const regex = /^\w+(?:-api)?(-\w+)?\./;
		const host = regex.test(requestHost)
			? "https://" + requestHost.replace(regex, HostNameHelper.HostPrefixes[hostType] + "$1.")
			: `http://localhost:${HostNameHelper.LocalhostPorts[hostType]}`;
		return host;
	}
}
