import React, { useState, useContext } from "react";
import { TimeEntryProps } from "./TimeEntry.Interface";
import "./TimeEntry.scss";
import { defaultScale, TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { KeyHelper } from "../../../Data/KeyHelper";
import { GlobalSettingsContext } from "../../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { DraggableCore, DraggableData, DraggableEvent } from "react-draggable";
import { GetRelativeClickCoordinates } from "../../../GlobalUtils/PositionUtils";
import { CalcTimePosition, CalcPositionTime } from "../../../GlobalUtils/TimeUtils";
import { useGroups } from "../../../Hooks/useGroups";
import { useTasks } from "../../../Hooks/useTasks";
import { useContextMenuParams, ContextMenu } from "../ContextMenu/ContextMenu";
import { useTime } from "../../../Hooks/useTime";
import { formatDurationElements } from "../LiveTimeLabel/useGroupTime";
import nameof from "ts-nameof.macro";

enum LinkedClassName {
	linked = " mode-linked",
	unlinked = " mode-unlinked",
}

enum HighlightedClassName {
	highlighted = " highlighted",
	notHighlighted = "",
}

enum Orientation {
	top = "top",
	bottom = "bottom",
}

// Once the edit mode bugs have been sorted out, return the setTops and setHeights to the mouse up top tab /bottom tag functions ot make it feel smoother.
// and uncomment the useEffect with conditions for setting height and top to make it more efficient.

export const TimeEntry = (props: TimeEntryProps) => {
	const globalSettingsContext = useContext(GlobalSettingsContext);
	const timelineContext = useContext(TimelineContext);

	const source = nameof(TimeEntry) + props.timeEntry.timeEntryGuid;

	const groups = useGroups(source, props.timeEntry.timeEntrySetGuid);

	const group = groups.Get(props.timeEntry.timeEntrySetGuid);

	const tasks = useTasks(source, group && KeyHelper.GetTimeEntrySetTaskKey(group));
	const isLinked = !!group?.taskExternalId;

	const time = useTime();

	const scale = timelineContext.state.currentScale || defaultScale;
	const dayStartOffsetHours = timelineContext.state.timelineStartTimeOffsetHours || 0;

	const [topDelta, setTopDelta] = useState(0);
	const [bottomDelta, setBottomDelta] = useState(0);
	const [bodyDelta, setBodyDelta] = useState(0);

	const unchangedStartPos = CalcTimePosition(
		props.timeEntry.startedWhen,
		dayStartOffsetHours,
		scale,
		timelineContext.state.currentDayOffset
	);
	const unchangedEndPos = CalcTimePosition(
		props.timeEntry.endedWhen || time,
		dayStartOffsetHours,
		scale,
		timelineContext.state.currentDayOffset
	);
	const timeEntryIsRunning = !props.timeEntry.endedWhen;

	const top = unchangedStartPos + topDelta + bodyDelta;
	const bottom = unchangedEndPos + bottomDelta + bodyDelta;
	const height = bottom - top;

	const tmpStartTime = CalcPositionTime(top, dayStartOffsetHours, scale, timelineContext.state.currentDayOffset);
	const tmpEndTime = CalcPositionTime(bottom, dayStartOffsetHours, scale, timelineContext.state.currentDayOffset);

	function endEdit() {
		setTopDelta(0);
		setBottomDelta(0);
		setBodyDelta(0);
		setBeingEdited(false);
		props.setCurrentlyEditing(false);
	}

	// --------------Dragging Handles---------------

	function OnTopDrag(_event: DraggableEvent, data: DraggableData) {
		setTopDelta(topDelta + data.deltaY);
	}

	function OnBottomDrag(_event: DraggableEvent, data: DraggableData) {
		setBottomDelta(bottomDelta + data.deltaY);
	}

	function OnBodyDrag(_event: DraggableEvent, data: DraggableData) {
		setBodyDelta(bodyDelta + data.deltaY);
	}

	function OnTopDragStart() {
		setTopDelta(0);
		setBodyDelta(0);
		setBeingEdited(true);
		props.setCurrentlyEditing(true);
	}

	function OnBottomDragStart() {
		setBottomDelta(0);
		setBeingEdited(true);
		props.setCurrentlyEditing(true);
	}

	function OnBodyDragStart() {
		setBodyDelta(0);
		setBeingEdited(true);
		props.setCurrentlyEditing(true);
	}

	function TopTagMouseUp() {
		let newStartTime = tmpStartTime;
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;

		// Need to make sure you can drag top bellow the bottom
		// Setting height here is to stop flickering from relying on the useEffect

		if (timelineContext.state.currentDayOffset === 0) {
			if (newStartTime > time) {
				newStartTime = time.minus({ minutes: 10 });
			}
			if (newEndTime && newEndTime > time) {
				newEndTime = time.minus({ second: 1 });
			}
		}
		void props.theHook.DragCollision(props.timeEntry.timeEntryGuid, newStartTime, newEndTime).then(() =>
			endEdit()
		);
	}

	function BottomTagMouseUp() {
		// Need to make sure you can't drag bottom above top.
		// If you drag the end time of a currently timed entry down, should it set the end time?
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;

		if (newEndTime && timelineContext.state.currentDayOffset === 0 && newEndTime > time) {
			newEndTime = time.minus({ second: 1 });
		}

		void props.theHook.DragCollision(props.timeEntry.timeEntryGuid, props.timeEntry.startedWhen, newEndTime).then(() => endEdit());
	}

	// ----------------Time Entry Dragging----------------

	function BodyMouseUp() {
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;
		let newStartTime = tmpStartTime;

		if (newEndTime && timelineContext.state.currentDayOffset === 0 && newEndTime > time) {
			newStartTime = time.minus({ minutes: height / timelineContext.state.currentScale });
			newEndTime = time;
		}

		void props.theHook.DragCollision(props.timeEntry.timeEntryGuid, newStartTime, newEndTime).then(() => endEdit());
	}

	// --------------------------------------------------------
	function DeleteThisTimeEntry() {
		props.theHook.RemoveTimeEntry(props.timeEntry.timeEntryGuid);
	}

	function InsertBreakInThisTimeEntry(position: number) {
		const timeForPos = CalcPositionTime(
			position,
			timelineContext.state.currentDayOffset || 0,
			timelineContext.state.currentScale || 1,
			timelineContext.state.currentDayOffset
		);

		props.theHook.InsertBreakInTimeEntry(props.timeEntry.timeEntryGuid, timeForPos);
	}

	async function SplitThisTimeEntry(position: number) {
		const timeForPos = CalcPositionTime(
			position,
			timelineContext.state.currentDayOffset || 0,
			timelineContext.state.currentScale || 1,
			timelineContext.state.currentDayOffset
		);

		await props.theHook.SplitTimeEntry(props.timeEntry.timeEntryGuid, timeForPos);
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams();

	function HandleContextMenu(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();
		const coords = GetRelativeClickCoordinates(e.nativeEvent, props.scrollRef);

		const menuOptions = [
			{ display: "Split", callback: () => InsertBreakInThisTimeEntry(coords.y) },
			{ display: "Split & Switch", callback: () => SplitThisTimeEntry(coords.y) },
			{ display: "Delete", callback: () => DeleteThisTimeEntry() },
		];

		setMenuCoords(coords);
		setMenuOptions(menuOptions);
		setShowContextMenu(true);
	}

	function GetTaskName() {
		if (group) {
			if (group.taskIntegrationGuid) {
				const taskGuid = KeyHelper.GetTimeEntrySetTaskKey(group);
				const task = tasks.Get(taskGuid);
				if (task) {
					return task.name;
				}
			}
			if (group.name) {
				return group.name;
			}
		}
		// TODO: Handle this issue
		return globalSettingsContext.state.defaultGroupName;
	}

	function GetTaskTime() {
		const startTime = props.timeEntry.startedWhen.toFormat('hh:mm')
		const endTime = props.timeEntry.endedWhen ? props.timeEntry.endedWhen.toFormat('hh:mm') : "Now"
		const totalTime = props.timeEntry.endedWhen ? props.timeEntry.endedWhen.diff(props.timeEntry.startedWhen) : time.diff(props.timeEntry.startedWhen)
		const formatedTotalTime = formatDurationElements(totalTime)

		return startTime + " - " + endTime + "  " + formatedTotalTime.totalTime + formatedTotalTime.timeUnitIndicator
	}

	function checkIfSpaceForNewEntry(topOrBottom: Orientation) {
		const topCutoffMinutes = 20 / (timelineContext.state.currentScale / 2) // Minimum minutes between time entries that still shows the top insert button
		const bottomCutoffMinutes = 60 / (timelineContext.state.currentScale / 2) // Minimum minutes between time entries that still shows the bottom insert button

		if (topOrBottom == Orientation.bottom && timeEntryIsRunning) {
			return false;
		}
		const neighbours = props.GetEntryNeighbours(props.timeEntry.timeEntryGuid)

		if (topOrBottom == Orientation.bottom) {
			if (neighbours.nextEntry && !timeEntryIsRunning) {
				if (neighbours.nextEntry.startedWhen.diff(tmpEndTime, "minutes").minutes < bottomCutoffMinutes) { // Change to be based on zoom amount rather than 15 minutes
					return false;
				}
			}
		} else {
			if (neighbours.previousEntry && neighbours.previousEntry.endedWhen) {
				const diffMinutes = tmpStartTime.diff(neighbours.previousEntry.endedWhen, "minutes").minutes
				if (diffMinutes < topCutoffMinutes) {
					return false;
				} else if (diffMinutes < bottomCutoffMinutes) {
					// TODO: adjust position of insert button to be equally between both time entries
				}
			}
		}

		return true;
	}

	function handleTopInsert() {
		insertNewEntry(Orientation.top)
	}

	function handleBottomInsert() {
		insertNewEntry(Orientation.bottom)
	}

	function insertNewEntry(orientation: Orientation) {
		// TODO: Make it a non-destructive insert that fills whatever available space there is up to 15 minutes (for when it's zoomed)

		const durationMs = 900000 // 15 minutes.
		const gapMs = 120000 // 2 minutes. Space between this time entry and new time entry.

		let startTime;
		let endTime;

		if (orientation == Orientation.top) {

			startTime = props.timeEntry.startedWhen.minus(durationMs + gapMs);
			endTime = props.timeEntry.startedWhen.minus(gapMs);

		} else if (orientation == Orientation.bottom && props.timeEntry.endedWhen) {

			startTime = props.timeEntry.endedWhen.plus(gapMs)
			endTime = props.timeEntry.endedWhen.plus(durationMs + gapMs)

		} else {
			return false;
		}

		props.theHook.InsertNewTimeEntry(startTime, endTime)
	}

	const [beingEdited, setBeingEdited] = useState(false);

	return (
		<>
			{!props.currentlyEditing && checkIfSpaceForNewEntry(Orientation.top) ?
				(
					<div className="insert-new-entry top"
						style={{
							top: (top - 40).toFixed() + "px",
						}}
						onClick={handleTopInsert}
					>
						<svg viewBox="0 0 120 120">
							<circle cx="55" cy="55" r="50" className="dashed-circle" />
							<foreignObject x="5" y="5" height="100px" width="100px">
								<div className="insert-new-entry-content" >+</div>
							</foreignObject>
						</svg>
					</div>
				)
				:
				(
					<></>
				)
			}
			<DraggableCore
				// Draggable for time entry body
				handle=".body-handle"
				onStart={OnBodyDragStart}
				onDrag={OnBodyDrag}
				onStop={BodyMouseUp}
				disabled={props.timeEntry.endedWhen ? false : true}
				offsetParent={props.scrollRef}
			>
				<div
					className={"time-unit-wrapper"}
					style={{
						top: top.toFixed() + "px",
						height: height.toFixed() + "px",
					}}
				>
					<div
						className={
							"time-unit " +
							(isLinked ? LinkedClassName.linked : LinkedClassName.unlinked) +
							HighlightedClassName.highlighted +
							" edit-mode" +
							(beingEdited ? " being-edited" : "")
						}
					>
						<div onContextMenu={HandleContextMenu} className="time-entry-block body-handle">
							<div className="time-entry-name-wrapper">
								<div className="time-entry-name">{GetTaskName()}</div>
								<div className="time-entry-time-description">{GetTaskTime()}</div>
							</div>
						</div>
						<DraggableCore
							// Draggable for top time tag
							handle=".top-handle"
							onStart={OnTopDragStart}
							onDrag={OnTopDrag}
							onStop={TopTagMouseUp}
							offsetParent={props.scrollRef}
						>
							<div className="draggable-tag top top-handle">
								<span className="icon" />
								<span className="time-stamp">{tmpStartTime.toFormat("HH:mm")}</span>
							</div>
						</DraggableCore>
						{props.timeEntry.endedWhen ? (
							<DraggableCore
								// Draggable for top time tag
								handle=".bottom-handle"
								onStart={OnBottomDragStart}
								onDrag={OnBottomDrag}
								onStop={BottomTagMouseUp}
								disabled={props.timeEntry.endedWhen ? false : true}
								offsetParent={props.scrollRef}
							>
								<div className="draggable-tag bottom bottom-handle">
									<span className="time-stamp">{tmpEndTime.toFormat("HH:mm")}</span>
									<span className="icon" />
								</div>
							</DraggableCore>
						) : (
							<></>
						)}
					</div>
				</div>
			</DraggableCore>
			{!props.currentlyEditing && checkIfSpaceForNewEntry(Orientation.bottom) ?
				(
					<div className="insert-new-entry bottom"
						style={{
							top: (top + height).toFixed() + "px",
						}}
						onClick={handleBottomInsert}
					>
						<svg viewBox="0 0 120 120">
							<circle cx="55" cy="55" r="50" className="dashed-circle" />
							<foreignObject x="5" y="5" height="100px" width="100px">
								<div className="insert-new-entry-content" >+</div>
							</foreignObject>
						</svg>
					</div>
				)
				:
				(
					<></>
				)
			}
			{ContextMenu(contextMenuParams)}
		</>
	);
};
