import { TimeEntrySubscribable, TimeEntryTempSubscribable } from "../../Data/TimeEntrySubscribable";
import { GroupSubscribable, GroupTempSubscribable } from "../../Data/GroupSubscribable";
import { TimeSource } from "../../Data/TimeSource";

export class EditModeClaw {
	constructor(private readonly timeSource: TimeSource) { }
	public editModeTimeEntriesSubscribable = new TimeEntryTempSubscribable([], this.timeSource);
	public editModeGroupSubscribable = new GroupTempSubscribable([], this.timeSource);
}

