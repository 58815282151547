import React from "react";
import { TimelineContextModel } from "./TimelineContextModel";
import { TimelineContextAction } from "./TimelineContextAction";
import { TimelineContextDispatchActionType } from "./TimelineContextDispatchActionType";
import { TimelineContextProviderProps } from "./TimelineContext.Interface";
import { DateTime } from "luxon";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";

const SCALE_FACTOR_STEP = 2;
const MIN_SCALE_FACTOR = SCALE_FACTOR_STEP;
const MAX_SCALE_FACTOR = 20;

export const TimelineContext = React.createContext({
	state: {} as TimelineContextModel,
	dispatch: {} as React.Dispatch<TimelineContextAction>,
});

export enum timelineNotesSortModeEnum {
	sortByGroup = "GROUP",
	sortByTime = "TIME",
}

export function IncreaseScaleByStep(currentScale: number) {
	//Max scale needs to be based on pixel resolution
	let newScale = currentScale;
	if (currentScale < MAX_SCALE_FACTOR) {
		newScale += SCALE_FACTOR_STEP;
	}
	console.debug(`Scale increased to ${newScale}px/min`);
	return newScale;
}

export function DecreaseScaleByStep(currentScale: number) {
	//Min scale needs to be based on pixel resolution
	let newScale = currentScale;
	if (currentScale > MIN_SCALE_FACTOR) {
		newScale -= SCALE_FACTOR_STEP;
	}
	console.debug(`Scale decreased to ${newScale}px/min`);
	return newScale;
}

function ToggleTimelineSortMode(timelineSortMode: timelineNotesSortModeEnum | null | undefined) {
	if (timelineSortMode === timelineNotesSortModeEnum.sortByTime) {
		return timelineNotesSortModeEnum.sortByGroup;
	} else {
		return timelineNotesSortModeEnum.sortByTime;
	}
}

function TimelineReducer(state: TimelineContextModel, action: TimelineContextAction) {
	switch (action.type) {
		case TimelineContextDispatchActionType.IncreaseScale:
			return { ...state, currentScale: IncreaseScaleByStep(state.currentScale) };
		case TimelineContextDispatchActionType.DecreaseScale:
			return { ...state, currentScale: DecreaseScaleByStep(state.currentScale) };
		case TimelineContextDispatchActionType.SetCurrentScale:
			return { ...state, currentScale: action.payload as number };
		case TimelineContextDispatchActionType.SetTimelineStartTimeOffset:
			return { ...state, timelineStartTimeOffset: action.payload as number };
		case TimelineContextDispatchActionType.SetCurrentDayOffset:
			return { ...state, currentDayOffset: action.payload as number };
		case TimelineContextDispatchActionType.ScrollToBottom:
			return { ...state, ScrollToBottom: !state.ScrollToBottom };
		case TimelineContextDispatchActionType.ScrollToDate:
			return { ...state, ScrollToDate: action.payload as DateTime };
		case TimelineContextDispatchActionType.ScrollToTimeEntry:
			return { ...state, ScrollToTimeEntry: action.payload as ITimeEntry };
		case TimelineContextDispatchActionType.ToggleTimelineSortMode:
			return { ...state, timelineSortMode: ToggleTimelineSortMode(state.timelineSortMode) };
		case TimelineContextDispatchActionType.SetEditingComment:
			return { ...state, editingComment: action.payload as boolean };
		default:
			return { ...state };
	}
}

export const TimelineContextProvider = (props: TimelineContextProviderProps) => {
	const [state, dispatch] = React.useReducer(
		TimelineReducer,
		new TimelineContextModel({
			currentScale: defaultScale, // in pixels per minute
			timelineSortMode: timelineNotesSortModeEnum.sortByTime,
		})
	);
	const value = { state, dispatch };

	return <TimelineContext.Provider value={value}>{props.children}</TimelineContext.Provider>;
};

export const defaultScale = 2; // Default scale for editable timeline, currently based on pixels to minutes i.e. a scale of 2 means every minute is 2 pixels.

export const END_OF_DAY_BUFFER_HOURS = 3; // Number of hours that are displayed after the 'present' time in the editable timeline.
// TODO: Check that this correctly displays time's when the working day crosses between literal days (i.e. mightnight -> 1am)

