import React, { useState, useEffect, useContext, useRef } from "react";
import { GroupHeaderProps } from "./GroupHeader.Interface";
import "./GroupHeader.scss";
import { DateTime } from "luxon";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import nameof from "ts-nameof.macro";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { TasklistModal } from "../TasklistModal/TasklistModal";
import { EditableLabel } from "../EditableLabel/EditableLabel";
import { ITag } from "../../../Data/Models/ITag";
import { JiraTagTypeCodeNames } from "../../../Hooks/useTags";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TheHook } from "../../../Hooks/TheHook/TheHook";

import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { GlobalSettingsContext } from "../../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { LiveTimeLabelForSets } from "../LiveTimeLabel/LiveTimeLabelForSets";
import { ContextMenu, useContextMenuParams } from "../ContextMenu/ContextMenu";
import { GetRelativeClickCoordinates } from "../../../GlobalUtils/PositionUtils";
import { ContextMenuOrigin } from "../ContextMenu/ContextMenu.interface";
import { List } from "immutable";
import { formatDuration } from "../LiveTimeLabel/useGroupTime";
import { TaskExternalLink } from "./taskExternalLink";

import { v4 } from "uuid";
import { usePlayingTime } from "../Playhead/usePlayingTime";
import { ImContext } from "../../../Context/DbContext/DbContext";

export const GroupHeader = (props: GroupHeaderProps) => {
	const source = `${nameof(GroupHeader)}_${v4()}`;
	const timelineContext = useContext(TimelineContext);
	const modalContext = useContext(ModalContext);
	const globalSettingsContext = useContext(GlobalSettingsContext);
	const timeControls = useTimeControls(source);
	const theHook = TheHook(source);
	const im = useContext(ImContext);

	const [hideLinkButton, setHideLinkButton] = useState(false);
	const [linked, setLinked] = useState(false);
	const [tags, setTags] = useState<List<ITag>>(List());

	const [projectName, setProjectName] = useState("");
	const [customerName, setCustomerName] = useState("");

	const [isActiveGroup, setIsActiveGroup] = useState<boolean>(false);

	const { isPlaying, currentTimeEntryGUID, currentTimeEntrySetGUID } = usePlayingTime() || {};

	const TimeEntries = useTimeEntries(source, currentTimeEntryGUID);

	enum PlayingStatus {
		isPlaying = " is-playing",
		notPlaying = " not-playing",
	}

	function TogglePlay() {
		return timeControls.TogglePlay();
	}

	function SwitchToThisGroup() {
		// const myLinkingTimeEntries: ITimeEntry[] = [];

		// props.consecutiveTimeEntries.forEach((compressedTimeEntrySummary) => {
		// 	compressedTimeEntrySummary.forEach((te) => {
		// 		myLinkingTimeEntries.push(te);
		// 	});
		// });

		return theHook.SwitchToGroup(props.MyGroup.timeEntrySetGuid);
	}

	useEffect(() => {
		if (currentTimeEntryGUID) {
			const activeTimeEntry = TimeEntries.Get(currentTimeEntryGUID);

			if (activeTimeEntry && activeTimeEntry.timeEntrySetGuid === props.MyGroup.timeEntrySetGuid) {
				setIsActiveGroup(true);
				return;
			}
		}
		setIsActiveGroup(false);
	}, [currentTimeEntryGUID, currentTimeEntrySetGUID]);

	useEffect(() => {
		CheckIsLinked();
		if (props.MyTask) {
			setTags(props.theHook.GetTaskTags(props.MyTask));
		} else {
			setTags(List());
		}
	}, [props.MyTask]);

	useEffect(() => {
		AssignTagsToState();
	}, [tags]);

	useEffect(() => {
		CheckIsLinked();
	}, [props.MyGroup && props.MyGroup.taskIntegrationGuid, props.MyTask]);

	function CheckIsLinked() {
		setLinked(props.MyGroup.taskExternalId ? true : false);
	}

	function LinkGroup() {
		const myLinkingTimeEntries: ITimeEntry[] = [];

		props.consecutiveTimeEntries.forEach((compressedTimeEntrySummary) => {
			compressedTimeEntrySummary.forEach((te) => {
				myLinkingTimeEntries.push(te);
			});
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <TasklistModal timeEntrySetGuid={props.MyGroup.timeEntrySetGuid} />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	function ExportGroup() {
		// TODO: Provide feedback for currently exporting in comments view
		theHook.MarkGroupForExport(
			props.MyGroup.timeEntrySetGuid,
			timeControls.GetTotalGroupTime(props.MyGroup.timeEntrySetGuid, timelineContext.state.currentDayOffset)
		);
	}

	function ViewTask() {
		// TODO
	}

	function ToggleHideLinkButton() {
		setHideLinkButton(!hideLinkButton);
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams(
		ContextMenuOrigin.TopRight
	);
	const scrollRef = useRef<HTMLDivElement>(null);

	function GetGroupsDayOffset() {
		const timeEntry = im.dataLayer.TimeEntries.GetForGroup(props.MyGroup.timeEntrySetGuid)
			.sortBy((x) => x.startedWhen)
			.first(undefined);

		// Returns -1 if fail since the checks that will use this groups day offset will check every day when given -1
		return timeEntry ? im.timeSource.GetLocalTime().diff(timeEntry.startedWhen, "days").days : -1;
	}

	function HandleContextMenu(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		// TODO: Replace this with a proper menu with the close button and everything.
		e.preventDefault();
		e.stopPropagation();

		if (!scrollRef.current) return;

		const menuCoords = GetRelativeClickCoordinates(e.nativeEvent, scrollRef.current);
		//const menuCoords = GetRelativeClickCoordinates(e.) { x: e.pageX, y: e.pageY };
		const groupTotalTime = formatDuration(
			timeControls.GetTotalGroupTime(props.MyGroup.timeEntrySetGuid, GetGroupsDayOffset())
		);

		const menuOptions = [
			{ display: linked ? "Relink all task time" : "Link to task", callback: LinkGroup }, // Make only affect these consecutive entries
			{ display: "View Task [N/A]", callback: ViewTask, disabled: true },
			{ display: linked ? "Switch to this task" : "Switch to this group", callback: SwitchToThisGroup },
			{
				display: "Export " + (groupTotalTime !== "" ? "(" + groupTotalTime + ")" : ""),
				callback: ExportGroup,
				disabled: theHook.CheckGroupIsExportable(props.MyGroup) ? false : true,
			},
		];

		if (!linked) {
			menuOptions.push({
				display: hideLinkButton ? "Show link button" : "Hide link button",
				callback: ToggleHideLinkButton,
			});
		}

		setMenuCoords(menuCoords);
		setMenuOptions(menuOptions);
		setShowContextMenu(true);
	}

	function SetGroupName(newName: string) {
		const groupWithNewName = { ...props.MyGroup, name: newName };

		props.theHook.SetGroup(groupWithNewName);
	}

	function AssignTagsToState() {
		let project = "No Project";
		let customer = "No Customer";

		tags.forEach((tag) => {
			// should use an ENUM for this for now.
			if (tag.tagTypeCodeName === JiraTagTypeCodeNames.project) {
				project = tag.value;
			} else if (tag.tagTypeCodeName === JiraTagTypeCodeNames.customer) {
				customer = tag.value;
			}
		});

		setProjectName(project);
		setCustomerName(customer);
	}

	function TaskFailedToSync() {
		if (props.MyGroup.taskExternalId && !props.MyTask) {
			return true;
		}
		return false;
	}

	const groupStartTime = props.consecutiveTimeEntries[0][0];

	return (
		<section className={"group-header-wrapper " + (linked ? " linked" : " unlinked")} ref={scrollRef}>
			{/* TO DO - Implement Start of cluster/group time */}
			<div className="group-start-time-wrapper">
				<span className="group-start-time">
					{groupStartTime ? groupStartTime.startedWhen.toLocaleString(DateTime.TIME_SIMPLE) : ""}
				</span>
			</div>

			<div className="group-header-container">
				<div className="group-controls-wrapper">
					<div className="group-controls-container">
						{isActiveGroup ? (
							<>
								{/* PLAY/PAUSE BUTTON */}
								<button
									onClick={TogglePlay}
									className={"play circle icon-only" + (isPlaying ? PlayingStatus.isPlaying : PlayingStatus.notPlaying)}
									title={isPlaying ? "Pause task" : "Play task"}
								>
									<span className="sr-only">{isPlaying ? "Pause task" : "Play task"}</span>
								</button>
							</>
						) : (
							<>
								{/* TIME SWITCH BUTTON */}
								<button onClick={SwitchToThisGroup} className={"switch circle icon-only"} title="Switch to this task">
									<span className="sr-only">Switch to this task</span>
								</button>
							</>
						)}
					</div>
				</div>

				<div className="group-details-wrapper">
					<div className="group-name">
						{props.MyTask && props.MyTask.name ? (
							<div className="group-name-wrapper">
								<h2 className="name-label">{props.MyTask.name}</h2>
								<TaskExternalLink task={props.MyTask} />
							</div>
						) : (
							<>
								<EditableLabel
									className="time-entry-comment"
									value={props.MyGroup.name ? props.MyGroup.name : globalSettingsContext.state.defaultGroupName}
									setValue={SetGroupName}
									placeholderLabel={
										props.MyGroup.name ? props.MyGroup.name : globalSettingsContext.state.defaultGroupName
									}
									labelElement="h2"
									StartInEditing={false}
								/>
								{TaskFailedToSync() ? (
									// Change title to use a enum so the error message can be changed
									<div className="task-sync-warning" title="Task was not found"></div>
								) : (
									<></>
								)}
							</>
						)}
					</div>

					<div className="group-details-container">
						<div className="customer-name">
							<span className="icon" title="Customer Name" />{" "}
							<span className="label">
								<span className="sr-only">Customer Name </span>
								{customerName}
							</span>
							{/* {customerName ? (
							<>
								
							</>
						) : (
							<></>
						)} */}
						</div>

						<div className="project-name">
							<span className="icon" title="Project Name" />{" "}
							<span className="label">
								<span className="sr-only">Project Name </span>
								{projectName}
							</span>
							{/* {projectName ? (
							<>
								
							</>
						) : (
							<></>
						)} */}
						</div>

						<div className="group-total-time" title="Total group time">
							<LiveTimeLabelForSets
								timeEntrySetGuid={props.MyGroup.timeEntrySetGuid}
								groupsDayOffset={timelineContext.state.currentDayOffset}
							/>
						</div>
					</div>
				</div>
				<div className="group-actions-wrapper">
					{linked || hideLinkButton ? (
						<></>
					) : (
						<>
							<button
								className="group-link icon-only"
								title="Link to task"
								aria-label="Link to task"
								onClick={LinkGroup}
							></button>
						</>
					)}
					<button
						className="group-context-menu icon-only"
						title="Open context menu"
						aria-label="Open context menu"
						onClick={HandleContextMenu}
					/>
				</div>
			</div>
			{ContextMenu(contextMenuParams)}
		</section>
	);
};
