import React, { useContext } from "react";
import { GroupSummaryProps } from "./GroupSummary.Interface";
import "./GroupSummary.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { TimeEntrySummary } from "../TimeEntrySummary/TimeEntrySummary";
import { useGroups } from "../../../Hooks/useGroups";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { useTasks } from "../../../Hooks/useTasks";
import { Guid } from "../../../Data/Guid";
import { KeyHelper } from "../../../Data/KeyHelper";
import { GroupHeader } from "../GroupHeader/GroupHeader";
import { ITask } from "../../../Data/Models/ITask";
import { GlobalSettingsContext } from "../../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { v4 } from "uuid";
import { ImContext } from "../../../Context/DbContext/DbContext";

export const GroupSummary = (props: GroupSummaryProps) => {
	const globalSettings = useContext(GlobalSettingsContext);
	const im = useContext(ImContext);

	const source = `${nameof(GroupSummary)}_${v4()}`;
	const TimeEntries = useTimeEntries(source);
	const Groups = useGroups(source, props.TimeEntrySetGuid);
	const Tasks = useTasks(source);
	const theHook = TheHook(source);

	function GetConsecutivelyOrderedTimeEntries() {
		const consecutiveEntries: ITimeEntry[][][] = [];
		// [All group headers] -> [All time entry summaries] -> [All time entries that are within the same summary due to compact comments]

		TimeEntries.GetSetDaysEntries(im.timeSource.GetLocalTime().minus({ days: props.DayOffset }))
			.filter(
				(timeEntry) =>
					// Displays a sumamry of every time entry in declared time entry set
					// If time entry set is undefined, it displays all of todays time entries
					timeEntry.timeEntrySetGuid === (props.TimeEntrySetGuid ? props.TimeEntrySetGuid : timeEntry.timeEntrySetGuid)
			)
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.forEach((te, index, array) => {
				// For compressed comments we want index === 0 to be rendered out only if none of the consecutive entries have comments

				if (index === 0 || array.get(index - 1)?.timeEntrySetGuid !== te.timeEntrySetGuid) {
					consecutiveEntries.push([[te]]);
				} else {
					if (!globalSettings.state.compactComments || te.comment) {
						consecutiveEntries[consecutiveEntries.length - 1].push([te]);
					} else {
						consecutiveEntries[consecutiveEntries.length - 1][
							consecutiveEntries[consecutiveEntries.length - 1].length - 1
						].push(te);
					}
				}
			});

		return consecutiveEntries;
	}

	function GetTask(timeEntrySetGuid: Guid) {
		const group = Groups.Get(timeEntrySetGuid);
		let task: undefined | ITask;

		if (group) {
			task = Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(group));
		}

		return task;
	}

	// REDO consecutive entries
	// Headers need to have all the entries under them, summaries need their own consecutive time entries (for compressed comments)

	return (
		<div>
			{GetConsecutivelyOrderedTimeEntries().map((consecutiveTimeEntries, index) => {
				const myGroup = Groups.Get(consecutiveTimeEntries[0][0].timeEntrySetGuid);

				if (!myGroup) return;
				let groupHeader = <></>;
				if (!props.hideHeaders) {
					groupHeader = (
						<GroupHeader
							key={`group-header-${index}`}
							MyTask={GetTask(consecutiveTimeEntries[0][0].timeEntrySetGuid)}
							MyGroup={myGroup}
							theHook={theHook}
							consecutiveTimeEntries={consecutiveTimeEntries}
						/>
					);
				}

				return (
					<div key={index}>
						{groupHeader}
						<div className="group-summary">
							{consecutiveTimeEntries.map((compressedTimeEntrySummary) => (
								<TimeEntrySummary
									key={"Time-Entry-Summary-" + compressedTimeEntrySummary[0].timeEntryGuid}
									compressedTimeEntry={compressedTimeEntrySummary}
									MyGroup={myGroup}
									theHook={theHook}
								/>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};
