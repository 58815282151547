/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITag } from "./Models/ITag";
import { ITask } from "./Models/ITask";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { DateTime } from "luxon";
import { ISearchResult } from "./Models/ISearchResult";

export class TimeHelper {
	ensureTimeEntryDates(obj: ITimeEntry): ITimeEntry {
		return {
			...obj,
			lastUpdatedWhen: this.toUtcDate(obj.lastUpdatedWhen)!,
			deletedWhen: obj.deletedWhen && this.cleanLocalDate(obj.deletedWhen),
			endedWhen: obj.endedWhen && this.cleanLocalDate(obj.endedWhen),
			startedWhen: this.cleanLocalDate(obj.startedWhen)!,
			lastSyncedWhen: obj.lastSyncedWhen && this.toUtcDate(obj.lastSyncedWhen),
		};
	}

	ensureTaskDates(obj: ITask): ITask {
		return {
			...obj,
			lastUpdatedWhen: this.toUtcDate(obj.lastUpdatedWhen)!,
			createdWhen: this.toUtcDate(obj.createdWhen)!,
			dueWhen: obj.dueWhen && this.toUtcDate(obj.dueWhen),
			externalCreatedWhen: this.toUtcDate(obj.externalCreatedWhen)!,
		};
	}

	ensureTagDates(obj: ITag): ITag {
		return {
			...obj,
			lastUpdatedWhen: this.toUtcDate(obj.lastUpdatedWhen)!,
			createdWhen: this.toUtcDate(obj.createdWhen)!,
		};
	}

	ensureTimeEntrySetDates(obj: ITimeEntrySet): ITimeEntrySet {
		return {
			...obj,
			lastUpdatedWhen: this.toUtcDate(obj.lastUpdatedWhen)!,
			deletedWhen: obj.deletedWhen && this.toUtcDate(obj.deletedWhen),
			createdWhen: this.toUtcDate(obj.createdWhen)!,
			lastExportedWhen: obj.lastExportedWhen && this.toUtcDate(obj.lastExportedWhen),
			lastExportErrorOccurredWhen: obj.lastExportErrorOccurredWhen && this.toUtcDate(obj.lastExportErrorOccurredWhen),
			queuedForExportWhen: obj.queuedForExportWhen && this.toUtcDate(obj.queuedForExportWhen),
			lastSyncedWhen: obj.lastSyncedWhen && this.toUtcDate(obj.lastSyncedWhen),
		};
	}

	ensureTaskMetadataDates(obj: ITaskMetadata): ITaskMetadata {
		return {
			...obj,
			lastUpdatedWhen: obj.lastUpdatedWhen && this.toUtcDate(obj.lastUpdatedWhen),
			createdWhen: obj.createdWhen && this.toUtcDate(obj.createdWhen),
			usageCountCalculatedWhen: obj.usageCountCalculatedWhen && this.toUtcDate(obj.usageCountCalculatedWhen),
		};
	}

	toUtcDate(val: DateTime | Date | string): DateTime {
		const momVal =
			typeof val === "string" ? DateTime.fromISO(val) : val instanceof Date ? DateTime.fromJSDate(val) : val;

		if (!DateTime.isDateTime(momVal)) {
			throw new Error(`Unable to convert '${val.toString()}' to DateTime`);
		}
		return momVal.toUTC();
	}

	cleanLocalDate(val: DateTime | Date | string): DateTime {
		const momVal =
			typeof val === "string" ? DateTime.fromISO(val) : val instanceof Date ? DateTime.fromJSDate(val) : val;

		if (!DateTime.isDateTime(momVal)) {
			throw new Error(`Unable to convert '${val.toString()}' to DateTime`);
		}
		return momVal.toLocal();
	}

	ensureSearchResultDates(sr: ISearchResult) {
		sr.dueWhen = sr.dueWhen ? this.toUtcDate(sr.dueWhen) : undefined;
		sr.externalCreatedWhen = this.toUtcDate(sr.externalCreatedWhen);
		return sr;
	}
}
