import { AuthManager } from "./AuthManager";

export class ConnectionManager {
	constructor(private readonly auth: AuthManager) {}

	public async Fetch(input: RequestInfo, init?: RequestInit) {
		const initClone = Object.assign({}, init);

		const token = await this.auth.GetApiToken();

		initClone.headers = Object.assign({}, { Authorization: `Bearer ${token || ""}` }, initClone.headers);

		const response = await fetch(input, initClone);

		return response;
	}

	public async FetchNoToken(input: RequestInfo, init?: RequestInit) {
		const response = await fetch(input, init);

		return response;
	}
}
