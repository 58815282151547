import React from "react";
import { TimeEntryCommentProps } from "./TimeEntryComment.Interface";
import { EditableComment } from "../EditableComment/EditableComment";
import "./TimeEntryComment.scss";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import nameof from "ts-nameof.macro";

export const TimeEntryComment = (props: TimeEntryCommentProps) => {
	const source = `${nameof(TimeEntryComment)}_${props.MyTimeEntry.timeEntryGuid}`;
	const theHook = TheHook(source);

	function SetComment(newComment: string) {
		theHook.SetComment(props.MyTimeEntry, newComment);
	}

	return props.editable ? (
		<EditableComment
			className="time-entry-comment"
			value={props.MyTimeEntry.comment ? props.MyTimeEntry.comment : ""}
			setValue={SetComment}
			placeholderLabel={"Add a comment..."}
			StartInEditing={props.StartInEditing}
		/>
	) : (
		<div className="time-entry-comment">{props.MyTimeEntry.comment}</div>
	);
};
