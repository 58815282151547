import React, { useState, useEffect, useContext } from "react";
import "./AddTimeEntryModal.scss";
import { useForm } from "../../../Hooks/Forms/useForm";
import { useField } from "../../../Hooks/Forms/useField";
import { Field, FieldTypes } from "../Field";
import { DateTime } from "luxon";
import nameof from "ts-nameof.macro";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TasklistModal } from "../TasklistModal/TasklistModal";
import { Dictionary } from "../../../../types/Dictionary";
import { ImContext } from "../../../Context/DbContext/DbContext";

enum WarningType {
	None = "none",
	Adjusted = "adjusted", // appears if any time entry would be adjusted by the new times
	Remove = "remove", // only appears if a time entry would be removed by the new times
	Split = "split", // only appears if a time entry would be split by the new times
	AfterCurrent = "after", // appears if the start or end time is after current time
	Invalid = "invalid", // appears if the start time is after the end time, or vise versa
}

// NEED TO CHECK FOR CURRENTLY RUNNING TIME ENTRY AND NOT ALLOW THEM TO EDIT END TIME WITHOUT PAUSING.

// Need to allow for linking new entries to groups / tasks

export const AddTimeEntryModal = () => {
	const theHook = TheHook(nameof(AddTimeEntryModal));
	const [linkToTask, setLinkToTask] = useState<boolean>(false);

	const modalContext = useContext(ModalContext);
	const timelineContext = useContext(TimelineContext);

	const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
	const [startTimeWarningType, setStartTimeWarningType] = useState<string>(WarningType.None);
	const [endTimeWarningType, setEndTimeWarningType] = useState<string>(WarningType.None);

	const im = useContext(ImContext);

	// Make start and end bold
	useEffect(() => {
		setWarningMessage("");

		// Warning messages in order of priority (adjustment -> split -> remove -> invalid)
		// These can definitely be simplified by having a single modified message instead of separate ones for each warning.
		if (startTimeWarningType === WarningType.Adjusted && endTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the start and end times of this time entry will override adjacent entries");
		} else if (startTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the start time of this time entry will override adjacent entries");
		} else if (endTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the end time of this time entry will override adjacent entries");
		}

		if (startTimeWarningType === WarningType.Split && endTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the start and end times of this time entry will split an entry into two");
		} else if (startTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the start time of this time entry will Split an entry into two");
		} else if (endTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the end time of this time entry will Split an entry into two");
		}

		if (startTimeWarningType === WarningType.Remove && endTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the start and end times of this time entry will remove adjacent entries");
		} else if (startTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the start time of this time entry will remove adjacent entries");
		} else if (endTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the end time of this time entry will remove adjacent entries");
		}

		if (startTimeWarningType === WarningType.Invalid || endTimeWarningType === WarningType.Invalid) {
			setWarningMessage("New Start time cannot be after End time");
		}

		if (startTimeWarningType === WarningType.AfterCurrent && endTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New Start and End times cannot be after current time");
		} else if (startTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New Start time cannot be after current time");
		} else if (endTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New End time cannot be after current time");
		}
	}, [startTimeWarningType, endTimeWarningType]);

	async function CreateNewEntry(startTimeString: string, endTimeString?: string) {
		if (
			startTimeWarningType !== WarningType.AfterCurrent ||
			(endTimeWarningType !== WarningType.AfterCurrent && (startTimeString || endTimeString))
		) {
			let confirm = false;
			if (startTimeWarningType === WarningType.Remove || endTimeWarningType === WarningType.Remove) {
				confirm = window.confirm("This will delete time entries. Continue?");
			} else {
				confirm = true;
			}

			// if (!confirm && (startTimeWarningType !== WarningType.None || endTimeWarningType !== WarningType.None)) {
			// 	confirm = window.confirm("This will alter existing time entries. Continue?");
			// } else {
			// 	confirm = true;
			// }

			if (confirm) {
				const date = im.timeSource
					.GetLocalTime()
					.minus({ days: timelineContext.state.currentDayOffset })
					.toFormat("yyyy-LL-dd");

				const newEntryStartTime = DateTime.fromISO(date + "T" + startTimeString);
				const newEntryEndTime = endTimeString ? DateTime.fromISO(date + "T" + endTimeString) : undefined;

				const newTimeEntry = await theHook.NewTimeEntry(
					newEntryStartTime,
					newEntryEndTime ? newEntryEndTime : undefined
				);

				await theHook.DragCollision(newTimeEntry.timeEntryGuid, newEntryStartTime, newEntryEndTime);
				return newTimeEntry;
			}
		}

		return undefined;
	}

	function FormatTime(time?: DateTime) {
		return time ? time.toLocaleString(DateTime.TIME_SIMPLE) : "NOW";
	}

	function ValidateTime(formData: Dictionary<string>) {
		setStartTimeWarningType(WarningType.None);
		setEndTimeWarningType(WarningType.None);

		if (formData.startTime && formData.endTime) {
			const date = im.timeSource
				.GetLocalTime()
				.minus({ days: timelineContext.state.currentDayOffset })
				.toFormat("yyyy-LL-dd");

			const newEntryStartTime = DateTime.fromISO(date + "T" + formData.startTime);
			const newEntryEndTime = formData.endTime ? DateTime.fromISO(date + "T" + formData.endTime) : undefined;

			if (newEntryEndTime && newEntryStartTime > newEntryEndTime) {
				setStartTimeWarningType(WarningType.Invalid);
			}

			const localTime = im.timeSource.GetLocalTime();

			im.dataLayer.TimeEntries.GetSetDaysEntries(
				localTime.minus({ days: timelineContext.state.currentDayOffset }),
				timelineContext.state.timelineStartTimeOffsetHours
			).forEach((te: ITimeEntry) => {
				if (!newEntryEndTime) return;
				if (newEntryStartTime > im.timeSource.GetLocalTime() || newEntryEndTime > localTime) {
					// Checks if start or end time is after current time
					if (newEntryStartTime > localTime) {
						setStartTimeWarningType(WarningType.AfterCurrent);
					}
					if (newEntryEndTime > localTime) {
						setEndTimeWarningType(WarningType.AfterCurrent);
					}
				} else if (newEntryStartTime > newEntryEndTime) {
					setStartTimeWarningType(WarningType.Invalid);
				} else if (newEntryEndTime < newEntryStartTime) {
					setStartTimeWarningType(WarningType.Invalid);
				} else if (te.endedWhen && newEntryStartTime < te.startedWhen && newEntryEndTime > te.endedWhen) {
					// Checks for removes
					setEndTimeWarningType(WarningType.Remove);
					setStartTimeWarningType(WarningType.Remove);
				} else if (te.endedWhen && newEntryStartTime > te.startedWhen && newEntryEndTime < te.endedWhen) {
					// Checks for splits
					setStartTimeWarningType(WarningType.Split);
					setEndTimeWarningType(WarningType.Split);
				} else if (te.endedWhen && newEntryStartTime < te.endedWhen && newEntryEndTime > te.endedWhen) {
					// Checks for end time adjustments
					setStartTimeWarningType(WarningType.Adjusted);
				} else if (newEntryStartTime < te.startedWhen && newEntryEndTime > te.startedWhen) {
					// Checks for start time adjustments
					setEndTimeWarningType(WarningType.Adjusted);
				}
			});

			console.log("new start time: ", newEntryStartTime.toFormat("hh:mm A dddd Do MMM "));
			console.log("new end time: ", newEntryEndTime && newEntryEndTime.toFormat("hh:mm A dddd Do MMM "));
		}

		return "";
	}

	const form = useForm({
		onSubmit: async (formData, valid) => {
			if (!valid) return;
			const newTimeEntry = await CreateNewEntry(formData.startTime, formData.endTime);
			if (newTimeEntry) {
				if (linkToTask) {
					modalContext.dispatch({
						type: ModalContextDispatchActionType.SetModalComponent,
						payload: <TasklistModal timeEntrySetGuid={newTimeEntry.timeEntrySetGuid} />,
					});
				} else {
					CloseModal();
				}
			}
		},
	});

	const startTimeField = useField("startTime", form, {
		defaultValue: undefined,
		validations: [
			(formData) => ValidateTime(formData),
			(formData) => {
				return formData.startTime.length === 0 && "Missing start time";
			},
		],
		fieldsToValidateOnChange: [],
	});

	const endTimeField = useField("endTime", form, {
		defaultValue: undefined,
		validations: [(formData) => ValidateTime(formData)],
		fieldsToValidateOnChange: [],
	});

	function CloseModal() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.CloseModal,
			payload: undefined,
		});
	}

	const newCommentField = useField("comment", form, {
		defaultValue: "",
		validations: [
			// () => {
			//     return newComment.length === 0 && "";
			// }
			// // Doesn't work for whatever reason
		],
		fieldsToValidateOnChange: [],
	});

	function ToggleLinkToTask() {
		setLinkToTask(!linkToTask);
	}

	// function GetTaskName() {
	// 	const myGroup = Groups.Get(props.timeEntry.timeEntrySetGuid)
	// 	if(myGroup){
	// 		if(myGroup.taskIntegrationGuid){
	// 			const taskGuid = KeyHelper.GetTimeEntrySetTaskKey(myGroup)
	// 			const task = Tasks.Get(taskGuid)
	// 			if(task){
	// 				return task.name
	// 			}
	// 		}
	// 		if(myGroup.name){
	// 			return myGroup.name
	// 		}
	// 	}
	// 	// TODO: Handle this issue
	// 	return "Unassigned"
	// }

	return (
		<section className="add-time-entry-modal">
			<div className="newTimeEntryContainer">
				{/*TODO - Add Date and current task */}
				<div className="Todays-Date">01 Mar 0000</div>
				<div className="add-time-entry-label">Temp Task Name</div>

				<form className="set-day-time" onSubmit={form.onSubmit}>
					<div className="TimeInfo"></div>
					{startTimeWarningType === WarningType.Remove ||
					startTimeWarningType === WarningType.AfterCurrent ||
					startTimeWarningType === WarningType.Invalid
						? "!!! "
						: startTimeWarningType === WarningType.Split
						? "!! "
						: startTimeWarningType === WarningType.Adjusted
						? "! "
						: ""}

					<div className="TimeEntryRow">
						<Field
							type="time"
							label="START TIME"
							formSubmitted={form.submitted}
							className="starting-hour-input"
							fieldType={FieldTypes.input}
							{...startTimeField}
						/>
						<br />
						{endTimeWarningType === WarningType.Remove ||
						endTimeWarningType === WarningType.AfterCurrent ||
						endTimeWarningType === WarningType.Invalid
							? "!!! "
							: endTimeWarningType === WarningType.Split
							? "!! "
							: endTimeWarningType === WarningType.Adjusted
							? "! "
							: ""}

						<Field
							type="time"
							label="END TIME"
							fieldType={FieldTypes.input}
							{...endTimeField}
							formSubmitted={form.submitted}
							className="ending-hour-input"
						/>

						<div className="Duration">
							{/* TODO Calculate difference between endTimeField and newTimeField, output duration.*/}
							{/*Temporary for Layout testing*/}
							<Field
								type="Duration"
								label="DURATION"
								fieldType={FieldTypes.textarea}
								{...endTimeField}
								formSubmitted={form.submitted}
								className="duration"
							/>
						</div>
					</div>
					<Field
						type="Comment"
						label="COMMENT (optional)"
						placeholder="Comment..."
						fieldType={FieldTypes.textarea}
						{...newCommentField}
						formSubmitted={form.submitted}
						className="Comment"
					/>
					<div
						className={
							"warning-message" +
							(startTimeWarningType === WarningType.Remove || endTimeWarningType === WarningType.Remove
								? WarningType.Remove
								: startTimeWarningType === WarningType.Adjusted || endTimeWarningType === WarningType.Adjusted
								? WarningType.Adjusted
								: WarningType.None)
						}
					>
						{warningMessage ? warningMessage : undefined}
					</div>

					<div className="link-to-task">
						<input type="checkbox" className="link-checkbox" onChange={ToggleLinkToTask} />
						<label className="Link-to-task-checkbox">Link to task after adding time</label>
					</div>
					<div className="AddCancelButtons">
						<button className="cancel" onClick={CloseModal}>
							<i className={`fas fa-times crossIcon`} />
							Cancel
						</button>
						{/*TODO - Comment needs to be added to the new time entry*/}
						<button
							className="add-time"
							type="submit"
							disabled={
								endTimeWarningType === WarningType.AfterCurrent ||
								startTimeWarningType === WarningType.AfterCurrent ||
								startTimeWarningType === WarningType.Invalid ||
								endTimeWarningType === WarningType.Invalid
							}
						>
							<span className="tickIcon">
								<i className="fas fa-check" />
							</span>
							Add
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};
