import React, { useContext } from "react";
import { SearchContextModel } from "./SearchContextModel";
import { SearchContextAction } from "./SearchContextActions";
import { SearchContextDispatchActionType } from "./SearchContextDispatchActionType";
import { SearchContextProviderProps } from "./SearchContext.Interface";
import { ISearchResult } from "../../Data/Models/ISearchResult";
import { TaskHelper } from "../../Data/TaskHelper";

import { KeyHelper } from "../../Data/KeyHelper";
import { SpecialCause } from "../../Data/SpecialCause";
import { TaskCardSizes } from "./TaskCardSizes";
import { FilterModes } from "./FilterModes";
import { ImContext } from "../DbContext/DbContext";
import { InstanceManager } from "../../Data/InstanceManager";

function updateTasksFromSearchResults(im:InstanceManager, results?: ISearchResult[]) {
	if (Array.isArray(results)) {
		for (const result of results) {
			const existingTask = im.dataLayer.Tasks.Get(KeyHelper.GetSearchResultTaskKey(result));
			if (existingTask) {
				void im.taskHelper.TaskifySearchResult(result, SpecialCause.AutoTaskifySearchResult);
			}
		}
	}
}

const SearchReducer = (im:InstanceManager)=> (state: SearchContextModel, action: SearchContextAction): SearchContextModel => {
	switch (action.type) {
		case SearchContextDispatchActionType.receiveResults:
			updateTasksFromSearchResults(im, action.payload);
			return { ...state, searchResults: action.payload as ISearchResult[], isSearching: false };
		case SearchContextDispatchActionType.showSearching:
			return { ...state, searchResults: [], isSearching: true };
		case SearchContextDispatchActionType.receiveNewResults:
			updateTasksFromSearchResults(im, action.payload);
			return { ...state, newResults: action.payload as ISearchResult[], isSearching: false };
		case SearchContextDispatchActionType.showFetchingNew:
			return { ...state, newResults: [], isSearching: true };
		case SearchContextDispatchActionType.setTaskCardSize:
			return { ...state, taskCardSize: action.payload as TaskCardSizes };
		case SearchContextDispatchActionType.setSearchFilter:
			return { ...state, filterMode: action.payload as FilterModes };
		case SearchContextDispatchActionType.setSearchTerm:
			return { ...state, searchTerm: action.payload as string };
		case SearchContextDispatchActionType.setActiveTaskKey:
			return { ...state, activeTaskKey: action.payload as string };
		default:
			return state;
	}
};

export const SearchContext = React.createContext({
	state: new SearchContextModel(),
	dispatch: {} as React.Dispatch<SearchContextAction>,
} as SearchContextType);

export interface SearchContextType {
	state: SearchContextModel;
	dispatch: React.Dispatch<SearchContextAction>;
}

function dispatchMiddleware(im:InstanceManager, dispatch: (action: SearchContextAction) => void) {
	return (action: SearchContextAction) => {
		switch (action.type) {
			case SearchContextDispatchActionType.search:
				dispatch({ type: SearchContextDispatchActionType.showSearching });
				dispatch({ type: SearchContextDispatchActionType.setSearchTerm, payload: action.payload as string });
				void im.int.Search(action.payload).then((results) => {
					dispatch({ type: SearchContextDispatchActionType.receiveResults, payload: results });
				});
				break;
			case SearchContextDispatchActionType.setSearchFilter:
				dispatch({ type: SearchContextDispatchActionType.setSearchFilter, payload: action.payload as FilterModes });
				if (action.payload === FilterModes.new) {
					dispatch({ type: SearchContextDispatchActionType.showFetchingNew });
					void im.int.NewTasks().then((results) => {
						dispatch({ type: SearchContextDispatchActionType.receiveNewResults, payload: results });
					});
				}
				break;
			default:
				return dispatch(action);
		}
	};
}

// This context provider uses a middleware wrapper to call the async search
// Meaning that the dispatchMiddleware always gets called first (simply calling the dispatch method if there's no async stuff to start)
// When the search results come back, it calls the regular dispatch method which is responsible for mutating the state
export const SearchContextProvider = (props: SearchContextProviderProps) => {
	const im = useContext(ImContext);
	const [state, dispatch] = React.useReducer(SearchReducer(im), {
		searchTerm: undefined,
		searchResults: [],
		isSearching: false,
		taskCardSize: TaskCardSizes.medium,
		filterMode: FilterModes.noFilter,
		activeTaskKey: undefined,
		newResults: [],
	});

	const value = { state, dispatch: dispatchMiddleware(im, dispatch) };

	return <SearchContext.Provider value={value}>{props.children}</SearchContext.Provider>;
};
