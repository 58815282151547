import { Subscriber, SubscriberCallback } from "./Subscriber";
import { Source } from "./Source";
import { Map, List, Set } from "immutable";
import { DateTime } from "luxon";
import { SpecialCause } from "./SpecialCause";
import nameof from "ts-nameof.macro";
import { ISubscribableCollection, SubscribableKey } from "./Subscribable";
import { IChronometricTable } from "./IChronometricDB";

export class SubscribableCollectionWithBackingStore<TModel> implements ISubscribableCollection<TModel> {
	protected static inBatch = 0;
	protected static notificationBatch: Map<
		string,
		{
			callback: SubscriberCallback<SubscribableKey>;
			action: DataActionEnum;
			keys: Set<SubscribableKey>;
			source: Source;
		}
	> = Map();
	/**
	 * StartTransaction
	 *
	 */
	public static StartTransaction() {
		this.inBatch++;
	}

	/**
	 * FinishTransaction
	 */
	public static FinishTransaction() {
		this.inBatch--;

		if (this.inBatch < 0)
			throw new Error(
				`${nameof(this.FinishTransaction)} called without corresponding call to ${nameof(this.StartTransaction)}`
			);

		if (this.inBatch === 0) {
			for (const item of this.notificationBatch.values()) {
				console.debug(`Notifying ${item.source}`);

				item.callback(item.action, item.keys);
			}
			this.notificationBatch = Map();
		}
	}
	protected LastUpdatedWhen: DateTime;

	constructor(
		private backingStore: IChronometricTable<TModel>,
		protected Subscribers: Set<Subscriber<string>> = Set<Subscriber<string>>(),
		protected Values: Map<string, TModel> = Map()
	) {
		this.LastUpdatedWhen = DateTime.utc();
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public ClearCaches(source?: Source): void {}

	public All(): List<TModel> {
		return this.Values.toList();
	}
	public Get(key: string): TModel | undefined {
		return this.Values.get(key, undefined);
	}
	public Set(key: string, value: TModel, source: Source, cause?: SpecialCause): void {
		void this.backingStore.put(value, key);
	}

	public Load(key: string, value: TModel) {
		this.Values = this.Values.set(key, value);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches();
		const isUpdate = this.Values.has(key);
		this.Notify(null, isUpdate ? DataActionEnum.Update : DataActionEnum.Create, Set([key]));
	}

	public Delete(key: string) {
		this.Values = this.Values.delete(key);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches();
		this.Notify(null, DataActionEnum.Delete, Set([key]));
	}

	public Remove(key: string, source: Source, cause?: SpecialCause): void {
		console.warn(`PROPER DELETION of key: ${key.toString()} initiated by source: ${source}`);
		this.Values = this.Values.delete(key);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		this.Notify(source, DataActionEnum.Delete, Set([key]), cause);
	}
	public RemoveAll(source?: Source): void {
		this.Values = Map();
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		if (source) this.Notify(source, DataActionEnum.DeleteAll);
	}

	public BulkSet(data: Map<string, TModel>, source?: Source, notify?: boolean, specialCause?: SpecialCause) {
		const keys = data.keySeq().toSet();
		if (keys.count() === 0) return;
		this.Values = this.Values.merge(data);
		this.LastUpdatedWhen = DateTime.utc();
		this.ClearCaches(source);
		if (source && notify) {
			this.Notify(source, DataActionEnum.BulkSet, keys, specialCause);
		}
	}

	public Subscribe(subscriber: Subscriber<string>) {
		this.Subscribers = this.Subscribers.add(subscriber);

		this.scheduleDuplicateSourceCheck();
	}

	private duplicateSourceCheckTimeout: number | undefined;
	private scheduleDuplicateSourceCheck() {
		//		clearTimeout(this.duplicateSourceCheckTimeout);
		//	this.duplicateSourceCheckTimeout = setTimeout(() => {
		const duplicateSources = this.Subscribers.filter((a) =>
			this.Subscribers.some((b) => a !== b && a.source === b.source)
		);
		if (duplicateSources.count() > 0) {
			console.error(
				`Multiple subscribers to ${this.constructor.name} with the same source. This can lead to stale data being used if they aren't the same instance`,
				duplicateSources.map((x) => x.source).toArray()
			);
		}
		//});
	}

	public UnSubscribe(toUnSubscribe: Subscriber<string>) {
		this.Subscribers = this.Subscribers.delete(toUnSubscribe);
	}
	public Notify(
		source: Source | null,
		action: DataActionEnum,
		keys: Set<string> = Set<string>(),
		specialCause?: SpecialCause
	) {
		this.Subscribers.forEach((subscriber) => {
			if (
				(source === null || subscriber.source !== source) &&
				(!Array.isArray(source) || !source.includes(subscriber.source)) &&
				(!subscriber.key || keys?.includes(subscriber.key)) &&
				(specialCause === undefined ||
					subscriber.allCauses ||
					subscriber.includeSpecialCauses?.includes(specialCause)) &&
				(!specialCause || !subscriber.excludeSpecialCauses?.includes(specialCause))
			) {
				if (SubscribableCollectionWithBackingStore.inBatch)
					SubscribableCollectionWithBackingStore.notificationBatch = SubscribableCollectionWithBackingStore.notificationBatch.set(
						subscriber.source,
						{
							callback: subscriber.callback,
							action,
							keys,
							source: subscriber.source,
						}
					);
				else {
					console.debug(`Notifying ${subscriber.source}`);
					subscriber.callback(action, keys);
				}
			}
		});
	}
}

export enum DataActionEnum {
	Create,
	Update,
	Delete,
	DeleteAll,
	BulkSet,
}
