import React, { useEffect, useContext, useState, useRef } from "react";
import { TaskRepeaterProps } from "./TaskRepeater.Interface";
import "./TaskRepeater.scss";
import { Task } from "../../Components/Library/Task/Task";
import nameof from "ts-nameof.macro";
import { useGroups } from "../../Hooks/useGroups";
import { SearchContext } from "../../Context/SearchContext/SearchContext";
import { FilterModes } from "../../Context/SearchContext/FilterModes";
import { TaskCardSizes } from "../../Context/SearchContext/TaskCardSizes";
import { ISearchResult } from "../../Data/Models/ISearchResult";

import { KeyHelper } from "../../Data/KeyHelper";
import { TheHook } from "../../Hooks/TheHook/TheHook";
import { ITaskMetadata } from "../../Data/Models/ITaskMetadata";
import { ModalContext } from "../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../Context/ModalContext/ModalContextDispatchActionType";
import { useTimeControls } from "../../Hooks/useTimeControls";
import { SearchContextDispatchActionType } from "../../Context/SearchContext/SearchContextDispatchActionType";
import { TopLevelSpinner } from "../../Components/Library/TopLevelSpinner";
import { v4 } from "uuid";
import { usePlayingTime } from "../../Components/Library/Playhead/usePlayingTime";
import { ImContext } from "../../Context/DbContext/DbContext";

export enum TasklistFuntion {
	link = "LINK",
	play = "PLAY",
}

// Make more flexible...
// Define parameters (days), then it adds the header between
// If current task (value) is appropriate day, and next task in array is not in appropriate day, create header for that appropriate day (with appropriate ref).

export const TaskRepeater = (props: TaskRepeaterProps) => {
	const theHook = TheHook(nameof(TaskRepeater));
	const im = useContext(ImContext);
	const Tasks = im.dataLayer.Tasks; //useTasks(nameof(TaskRepeater));
	const TimeEntries = im.dataLayer.TimeEntries; //useTimeEntries(nameof(TaskRepeater));
	const searchContext = useContext(SearchContext);
	const modalContext = useContext(ModalContext);
	const timeControls = useTimeControls(nameof(TaskRepeater));

	const firstSeparatorRef = useRef<HTMLDivElement>(null);
	const secondSeparatorRef = useRef<HTMLDivElement>(null);
	const thirdSeparatorRef = useRef<HTMLDivElement>(null);
	// const fourthSeparatorRef = useRef(null);
	//const fifthSeparatorRef = useRef(null);

	const [actingGroupGuid, setActingGroupGuid] = useState<string | undefined>(props.timeEntrySetGuid);

	const [groupedSortedSearchResults, setGroupedSortedSearchResults] = useState<JSX.Element[] | undefined>(undefined); // acts as a buffer to stop the tasklist from updating as you use it

	const { currentTimeEntryGUID } = usePlayingTime() || {};

	const Groups = im.dataLayer.Groups;
	useGroups(`${nameof(TaskRepeater)}_${actingGroupGuid || v4()}`, actingGroupGuid);
	useGroups(`${nameof(TaskRepeater)}_${currentTimeEntryGUID || v4()}`, currentTimeEntryGUID);

	useEffect(() => {
		const sortedSearchResults = GetSortedSearchResults();
		if (sortedSearchResults) {
			setGroupedSortedSearchResults(GetGroupedSortedSearchResults(sortedSearchResults));
			//setSortedSearchResults(sortedSearchResults);
		}
	}, [searchContext.state.filterMode, searchContext.state.isSearching]);

	useEffect(() => {
		if (actingGroupGuid) {
			const group = Groups.Get(actingGroupGuid);

			if (group) {
				SetActiveTaskGuid(KeyHelper.GetTimeEntrySetTaskKey(group));
			}
		} else if (props.linkingTimeEntries) {
			const currentGroup = Groups.Get(props.linkingTimeEntries[0].timeEntrySetGuid);
			if (currentGroup) {
				SetActiveTaskGuid(KeyHelper.GetTimeEntrySetTaskKey(currentGroup));
			}
		} else if (currentTimeEntryGUID) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);
			if (currentTimeEntry) {
				const currentGroup = Groups.Get(currentTimeEntry.timeEntrySetGuid);

				if (currentGroup) {
					SetActiveTaskGuid(KeyHelper.GetTimeEntrySetTaskKey(currentGroup));
				}
			}
		} else {
			SetActiveTaskGuid(undefined);
		}
	}, []);

	function SetActiveTaskGuid(taskGuid: string | undefined) {
		searchContext.dispatch({
			type: SearchContextDispatchActionType.setActiveTaskKey,
			payload: taskGuid,
		});
	}

	function CloseModal() {
		if (props.modalVersion) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.CloseModal,
				payload: undefined,
			});
		}
	}

	async function TaskifySearchResults(searchResult: ISearchResult) {
		if (!Tasks.Get(KeyHelper.GetSearchResultTaskKey(searchResult))) {
			await im.taskHelper.TaskifySearchResult(searchResult);
		}
	}

	async function LinkTo(searchResult: ISearchResult) {
		const promise = await TaskifySearchResults(searchResult);
		const taskKey = KeyHelper.GetSearchResultTaskKey(searchResult);

		if (actingGroupGuid) {
			const group = Groups.Get(actingGroupGuid);

			if (group) {
				if (taskKey === KeyHelper.GetTimeEntrySetTaskKey(group)) {
					if (props.linkingTimeEntries) {
						const newActingGroup = await theHook.UnlinkTimeEntriesFromTask(props.linkingTimeEntries, taskKey);
						setActingGroupGuid(newActingGroup ? newActingGroup.timeEntrySetGuid : undefined);
					} else {
						theHook.UnlinkGroup(actingGroupGuid);
					}
					SetActiveTaskGuid(undefined);
				} else {
					if (props.linkingTimeEntries) {
						const newActingGroup = await theHook.LinkTimeEntriesToTask(props.linkingTimeEntries, taskKey);
						setActingGroupGuid(newActingGroup ? newActingGroup.timeEntrySetGuid : undefined);
					} else {
						await theHook.LinkGroup(group.timeEntrySetGuid, taskKey);
					}
					SetActiveTaskGuid(taskKey);
					CloseModal();
				}
			}
		}
		return promise;
	}

	async function SwitchToThisTask(searchResult: ISearchResult) {
		await TaskifySearchResults(searchResult);

		await timeControls.SwitchTo(KeyHelper.GetSearchResultTaskKey(searchResult));

		SetActiveTaskGuid(KeyHelper.GetSearchResultTaskKey(searchResult));
	}

	async function FavouriteTask(searchResult: ISearchResult) {
		await theHook.FavouriteSearchResult(searchResult);
	}

	async function UnfavouriteTask(searchResult: ISearchResult) {
		await theHook.UnfavouriteSearchResult(searchResult);
	}

	// Could use this hook setup to pass the exact y's to the context, instead of refs.
	const scrollRef = React.useRef(null);

	function GetSortedSearchResults(): ISearchResult[] {
		switch (searchContext.state.filterMode) {
			case FilterModes.new:
				return searchContext.state.newResults;
			case FilterModes.dueDate:
				return Tasks.All()
					.filter((t) => t.dueWhen)
					.sortBy((x) => x.dueWhen)
					.map((x) => im.taskHelper.SearchResultifyTask(x))
					.toArray();
			case FilterModes.favourites:
				return theHook
					.GetFavouriteTasks()
					.filter((x) => im.taskHelper.ObjectIsTask(x))
					.map((x) => im.taskHelper.SearchResultifyTask(x!))
					.toArray();
			case FilterModes.mostUsed: {
				const metadatasWithAdjustedUsageCount = im.dataLayer.TaskMetadatas.All().map((x) => {
					return { ...x, usageCount: GetAdjustedUsageCount(x) };
				});
				// This gets updated live as the users links, which is unwanted.
				const mostUsedIds = metadatasWithAdjustedUsageCount
					.sortBy((x) => x.usageCount)
					.reverse()
					.slice(0, 20)
					.map((x) => KeyHelper.GetTaskKey(x));
				const mostUsedTasks = mostUsedIds.map((id) => Tasks.All().find((t) => KeyHelper.GetTaskKey(t) === id));
				return mostUsedTasks
					.filter((x) => im.taskHelper.ObjectIsTask(x))
					.map((x) => im.taskHelper.SearchResultifyTask(x!))
					.toArray();
			}
			case FilterModes.recent: {
				const recentTimeEntries = TimeEntries.AllNotDeleted()
					.sortBy((x) => x.lastUpdatedWhen)
					.reverse()
					.slice(0, 100);
				const recentGroups = recentTimeEntries
					.map((x) => x.timeEntrySetGuid)
					.toOrderedSet()
					.map((x) => Groups.Get(x));
				const uniqTaskKeys = recentGroups
					.filter((x) => !!x)
					.map((x) => KeyHelper.GetTimeEntrySetTaskKey(x!))
					.toOrderedSet();
				return uniqTaskKeys
					.map((x) => Tasks.Get(x))
					.filter((x) => im.taskHelper.ObjectIsTask(x))
					.map((x) => im.taskHelper.SearchResultifyTask(x!))
					.toArray();
			}
			case FilterModes.noFilter:
			default:
				return searchContext.state.searchResults;
		}
	}
	function GoToFirstSeparator() {
		if (firstSeparatorRef && firstSeparatorRef.current) {
			firstSeparatorRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});

			//window.scrollTo(0, firstSeparatorRef.current.offsetTop)
		}
	}
	function GoToSecondSeparator() {
		if (secondSeparatorRef && secondSeparatorRef.current) {
			secondSeparatorRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});

			//window.scrollTo(0, secondSeparatorRef.current.offsetTop)
		}
	}

	function GoToThirdSeparator() {
		if (thirdSeparatorRef && thirdSeparatorRef.current) {
			thirdSeparatorRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});

			//window.scrollTo(0, thirdSeparatorRef.current.offsetTop)
		}
	}
	// function GoToFourthSeparator() {
	// 	searchContext.dispatch({
	// 		type: SearchContextDispatchActionType.setNumberToShow,
	// 		payload: NumberToShowFilters.all,
	// 	});
	// }
	// function GoToFifthSeparator() {
	// 	searchContext.dispatch({
	// 		type: SearchContextDispatchActionType.setNumberToShow,
	// 		payload: NumberToShowFilters.all,
	// 	});
	// }

	function SetTaskCardSizeSmall() {
		searchContext.dispatch({
			type: SearchContextDispatchActionType.setTaskCardSize,
			payload: TaskCardSizes.small,
		});
	}
	function SetTaskCardSizeMedium() {
		searchContext.dispatch({
			type: SearchContextDispatchActionType.setTaskCardSize,
			payload: TaskCardSizes.medium,
		});
	}

	function SetTaskCardSizeLarge() {
		searchContext.dispatch({
			type: SearchContextDispatchActionType.setTaskCardSize,
			payload: TaskCardSizes.large,
		});
	}

	// TODO: Figure out a faster way to adjust usage counts
	const GetAdjustedUsageCount = (x: ITaskMetadata) => {
		const newerTimeEntrySets = Groups.AllNotDeleted().filter(
			(g) =>
				g.taskIntegrationGuid === x.integrationGuid &&
				g.taskExternalId === x.externalId &&
				(x.usageCountCalculatedWhen === undefined || g.lastUpdatedWhen > x.usageCountCalculatedWhen)
		);
		return x.usageCount + newerTimeEntrySets.count();
	};

	function FindSearchResultLastUsedDate(searchResult: ISearchResult) {
		// NEW CODE: Attempt to make last used date reflect when the groups's effective time. Currently not being used by the sorting code so it won't work
		// const timeEntries:ITimeEntry[] = [];

		// const thisSearchResultsGroups = Groups.All().filter((group) => KeyHelper.GetTimeEntrySetTaskKey(group) === KeyHelper.GetTaskKey(searchResult));

		// thisSearchResultsGroups.forEach((group)=>{
		// 	const thisGroupsTimeEntries = TimeEntries.All().filter((te) => te.timeEntrySetGuid === group.timeEntrySetGuid)
		// 	thisGroupsTimeEntries.forEach((te)=>{
		// 		timeEntries.push(te);
		// 	})
		// })

		// const sortedTimeEntries = _.sortBy(timeEntries.filter((te) => te.startedWhen),["startedWhen"]).reverse()

		// return sortedTimeEntries && sortedTimeEntries[0]
		// 	? sortedTimeEntries[0].startedWhen
		// 	: undefined;

		// OLD CODE, using last updated when

		const allGroupsOfThisSearchResult = Groups.All()
			.filter((g) => g.lastUpdatedWhen)
			.sortBy((x) => x.lastUpdatedWhen)
			.reverse()
			.filter((group) => KeyHelper.GetTimeEntrySetTaskKey(group) === KeyHelper.GetTaskKey(searchResult));

		return !allGroupsOfThisSearchResult.isEmpty()
			? allGroupsOfThisSearchResult.first(undefined)?.lastUpdatedWhen
			: undefined;
	}

	// ----   Add in separator refs (use the breakpoints index to determine which one, then check if the ref exists to disable buttons, will need function)  -----

	const SEARCHRESULT_SEPARATOR_BREAKPOINTS = [3, 7, 14]; // This array determines what days are considered breaks for the search results
	// NOTE: First value can't be 0!

	// TODO: Make this have way less duplicate code.
	function GetGroupedSortedSearchResults(sortedSearchResults: ISearchResult[]) {
		let separator: JSX.Element | undefined = undefined;
		let breakpointsIndex = 0;
		const time = im.timeSource.GetLocalTime();

		switch (searchContext.state.filterMode) {
			case FilterModes.recent:
				return sortedSearchResults.map((searchResult, i) => {
					separator = undefined;
					const lastUsedDateTime = FindSearchResultLastUsedDate(searchResult);
					if (
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex] &&
						lastUsedDateTime &&
						time.diff(lastUsedDateTime.toLocal().startOf("day"), "days").days >=
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]
					) {
						// If this search result isn't valid for the breakpoint, iterrate through breakpoints until it finds one it is valid for.
						for (let t = breakpointsIndex + 1; t < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length; t++) {
							if (
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t] &&
								time.startOf("day").diff(lastUsedDateTime.toLocal().startOf("day"), "days").days >=
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t]
							) {
								breakpointsIndex = t;
								break;
							}
						}

						if (breakpointsIndex < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length) {
							separator = (
								<div
									className="grouped-entries-label"
									ref={
										breakpointsIndex === 0
											? firstSeparatorRef
											: breakpointsIndex === 1
												? secondSeparatorRef
												: breakpointsIndex === 2
													? thirdSeparatorRef
													: undefined
									}
								>
									--- Last Used: {SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]}+ Days ago ---
								</div>
							);

							// This will also hopefully take the breakpointsIndex above the legnth of the search results breakpoints and end the creation of new separators
							breakpointsIndex++;
						}
					}

					return (
						<React.Fragment key={KeyHelper.GetTaskKey(searchResult)}>
							{i === 0 && !separator ? (
								<div className="grouped-entries-label">--- Last Used: Today / Last Used: 2+ Days ago (TODO) ---</div>
							) : (
									<></>
								)}
							{separator}
							<Task
								task={searchResult}
								LinkTo={LinkTo}
								// activeTaskGUID={activeTaskGuid}
								SwitchToThisTask={SwitchToThisTask}
								FavouriteTask={FavouriteTask}
								UnFavouriteTask={UnfavouriteTask}
								theHook={theHook}
								// taskCardSize={searchContext.state.taskCardSize}
								tasklistFunction={actingGroupGuid ? TasklistFuntion.link : TasklistFuntion.play}
								CloseModal={CloseModal}
							/>
						</React.Fragment>
					);
				});
			case FilterModes.dueDate:
				return sortedSearchResults.map((searchResult, i) => {
					separator = undefined;
					const dueWhenDateTime = searchResult.dueWhen && searchResult.dueWhen;
					if (
						dueWhenDateTime &&
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex] &&
						time.startOf("day").diff(dueWhenDateTime.toLocal().startOf("day"), "days").days >=
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]
					) {
						// If this search result isn't valid for the breakpoint, iterrate through breakpoints until it finds one it is valid for.

						for (let t = breakpointsIndex; t < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length; t++) {
							if (
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t] &&
								time.startOf("day").diff(dueWhenDateTime.toLocal().startOf("day"), "days").days >=
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t]
							) {
								breakpointsIndex = t;
							}
						}

						if (breakpointsIndex < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length) {
							separator = (
								<div
									className="grouped-entries-label"
									ref={
										breakpointsIndex === 0
											? firstSeparatorRef
											: breakpointsIndex === 1
												? secondSeparatorRef
												: breakpointsIndex === 2
													? thirdSeparatorRef
													: undefined
									}
								>
									--- Due: In {SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]}+ Days ---
								</div>
							);
							if (breakpointsIndex === SEARCHRESULT_SEPARATOR_BREAKPOINTS.length - 1) {
								breakpointsIndex++;
							}
						}
					}

					return (
						<React.Fragment key={KeyHelper.GetTaskKey(searchResult)}>
							{i === 0 && !separator ? (
								<div className="grouped-entries-label">--- Due: Today / Overdue (TODO) / Due: In 2 Days (TODO) ---</div>
							) : (
									<></>
								)}
							{separator}
							<Task
								task={searchResult}
								LinkTo={LinkTo}
								// activeTaskGUID={activeTaskGuid}
								SwitchToThisTask={SwitchToThisTask}
								FavouriteTask={FavouriteTask}
								UnFavouriteTask={UnfavouriteTask}
								theHook={theHook}
								// taskCardSize={searchContext.state.taskCardSize}
								tasklistFunction={actingGroupGuid ? TasklistFuntion.link : TasklistFuntion.play}
								CloseModal={CloseModal}
							/>
						</React.Fragment>
					);
				});

			case FilterModes.new:
				return sortedSearchResults.map((searchResult, i) => {
					separator = undefined;
					if (
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex] &&
						time.startOf("day").diff(searchResult.externalCreatedWhen.toLocal().startOf("day"), "days").days >=
						SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]
					) {
						// If this search result isn't valid for the breakpoint, iterrate through breakpoints until it finds one it is valid for.

						for (let t = breakpointsIndex; t < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length; t++) {
							if (
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t] &&
								time.startOf("day").diff(searchResult.externalCreatedWhen.toLocal().startOf("day"), "days").days >=
								SEARCHRESULT_SEPARATOR_BREAKPOINTS[t]
							) {
								breakpointsIndex = t;
							}
						}

						if (breakpointsIndex < SEARCHRESULT_SEPARATOR_BREAKPOINTS.length) {
							separator = (
								<div
									className="grouped-entries-label"
									ref={
										breakpointsIndex === 0
											? firstSeparatorRef
											: breakpointsIndex === 1
												? secondSeparatorRef
												: breakpointsIndex === 2
													? thirdSeparatorRef
													: undefined
									}
								>
									--- Created: {SEARCHRESULT_SEPARATOR_BREAKPOINTS[breakpointsIndex]}+ Days ago ---
								</div>
							);
							if (breakpointsIndex === SEARCHRESULT_SEPARATOR_BREAKPOINTS.length - 1) {
								breakpointsIndex++;
							}
						}
					}

					return (
						<React.Fragment key={KeyHelper.GetTaskKey(searchResult)}>
							{i === 0 && !separator ? (
								<div className="grouped-entries-label">--- Created: Today / Created: 2 Days Ago (TODO) ---</div>
							) : (
									<></>
								)}
							{separator}
							<Task
								task={searchResult}
								LinkTo={LinkTo}
								// activeTaskGUID={activeTaskGuid}
								SwitchToThisTask={SwitchToThisTask}
								FavouriteTask={FavouriteTask}
								UnFavouriteTask={UnfavouriteTask}
								theHook={theHook}
								// taskCardSize={searchContext.state.taskCardSize}
								tasklistFunction={actingGroupGuid ? TasklistFuntion.link : TasklistFuntion.play}
								CloseModal={CloseModal}
							/>
						</React.Fragment>
					);
				});

			default:
				return sortedSearchResults.map((searchResult) => {
					return (
						<Task
							key={KeyHelper.GetTaskKey(searchResult)}
							task={searchResult}
							LinkTo={LinkTo}
							// activeTaskGUID={activeTaskGuid}
							SwitchToThisTask={SwitchToThisTask}
							FavouriteTask={FavouriteTask}
							UnFavouriteTask={UnfavouriteTask}
							theHook={theHook}
							// taskCardSize={searchContext.state.taskCardSize}
							tasklistFunction={actingGroupGuid ? TasklistFuntion.link : TasklistFuntion.play}
							CloseModal={CloseModal}
						/>
					);
				});
		}
	}

	const [firstSeparatorExists, setFirstSeparatorExists] = useState<boolean>(false);
	const [secondSeparatorExists, setSecondSeparatorExists] = useState<boolean>(false);
	const [thirdSeparatorExists, setThirdSeparatorExists] = useState<boolean>(false);
	// const [fourthSeparatorExists, setFourthSeparatorExists]  = useState<boolean>(false);
	// const [fifthSeparatorExists, setFifthSeparatorExists]  = useState<boolean>(false);

	useEffect(() => {
		setFirstSeparatorExists(!!(firstSeparatorRef && firstSeparatorRef.current));
		setSecondSeparatorExists(!!(secondSeparatorRef && secondSeparatorRef.current));
		setThirdSeparatorExists(!!(thirdSeparatorRef && thirdSeparatorRef.current));
		// setFourthSeparatorExists(!!(fourthSeparatorRef && fourthSeparatorRef.current))
		// setFifthSeparatorExists(!!(fifthSeparatorRef && fifthSeparatorRef.current))
	});

	return (
		<section className={"task-list-container" + (props.modalVersion ? " modal-version" : "")} ref={scrollRef}>
			<div className="task-list-header">
				<div className={"list-title-wrapper " + searchContext.state.filterMode}>
					<span className="icon"></span>
					<h2>
						{searchContext.state.filterMode === FilterModes.search ? (
							<>
								<span className="search-description-label">Search&nbsp;</span>
								<span className="search-description-details">
									Showing Results For: {searchContext.state.searchTerm}
								</span>
							</>
						) : (
								<></>
							)}
						{searchContext.state.filterMode === FilterModes.recent ? "Recent" : ""}
						{searchContext.state.filterMode === FilterModes.favourites ? "Favourites" : ""}
						{searchContext.state.filterMode === FilterModes.mostUsed ? "Most Used" : ""}
						{searchContext.state.filterMode === FilterModes.dueDate ? "Due Date" : ""}
						{searchContext.state.filterMode === FilterModes.new ? "New" : ""}
					</h2>
				</div>
				{searchContext.state.filterMode === FilterModes.recent ||
					searchContext.state.filterMode === FilterModes.dueDate ||
					searchContext.state.filterMode === FilterModes.new ? (
						<div className="select-number-to-show-wrapper">
							<button className={"number-to-show-button"} onClick={GoToFirstSeparator} disabled={!firstSeparatorExists}>
								{searchContext.state.filterMode === FilterModes.dueDate ? "In 3+ Days" : "3+ Days ago"}
							</button>
							<button className={"number-to-show-button"} onClick={GoToSecondSeparator} disabled={!secondSeparatorExists}>
								{searchContext.state.filterMode === FilterModes.dueDate ? "In 7+ Days" : "7+ Days ago"}
							</button>
							<button className={"number-to-show-button"} onClick={GoToThirdSeparator} disabled={!thirdSeparatorExists}>
								{searchContext.state.filterMode === FilterModes.dueDate ? "In 14+ Days" : "14+ Days Ago"}
							</button>
						</div>
					) : (
						<></>
					)}
				<div className="size-toggle-wrapper">
					<button
						className={
							"size-button small" + (searchContext.state.taskCardSize === TaskCardSizes.small ? " active" : "")
						}
						onClick={SetTaskCardSizeSmall}
					>
						S
					</button>
					<button
						className={
							"size-button medium" + (searchContext.state.taskCardSize === TaskCardSizes.medium ? " active" : "")
						}
						onClick={SetTaskCardSizeMedium}
					>
						M
					</button>
					<button
						className={
							"size-button large" + (searchContext.state.taskCardSize === TaskCardSizes.large ? " active" : "")
						}
						onClick={SetTaskCardSizeLarge}
					>
						L
					</button>
				</div>
			</div>
			{searchContext.state.isSearching ? (
				<div className="spinner-container">
					<TopLevelSpinner />
				</div>
			) : groupedSortedSearchResults && groupedSortedSearchResults.length > 0 ? (
				groupedSortedSearchResults
			) : (
						<div className="no-results">No results</div>
					)}
		</section>
	);
};
