import { ISubscribableCollection, SubscribableCollection, SubscribableKey } from "./Subscribable";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { List } from "immutable";
import { Source } from "./Source";
import { SubscribableCollectionWithBackingStore } from "./SubscribableWithBackingStore";
import { IChronometricTable } from "./IChronometricDB";
import { TimeSource } from "./TimeSource";
import { Subscriber } from "./Subscriber";

export class GroupSubscribable
	extends SubscribableCollectionWithBackingStore<ITimeEntrySet>
	implements IGroupSubscribable {

	constructor(backingStore: IChronometricTable<ITimeEntrySet>, private readonly timeSource:TimeSource) {
		super(backingStore);
	}

	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw `Unable to load time entry set with key '${key}' to remove`;

		item.lastUpdatedWhen = this.timeSource.GetUtcTime();
		item.deletedWhen = this.timeSource.GetLocalTime();
		super.Set(key, item, source);
	}

	public ClearCaches(source?: Source) {
		console.debug(`GroupSubscribable ClearCaches triggered by ${source || ""}`);
		this.AllNotDeletedCache = undefined;
	}

	private AllNotDeletedCache: List<ITimeEntrySet> | undefined;

	public AllNotDeleted(): List<ITimeEntrySet> {
		if (this.AllNotDeletedCache) return this.AllNotDeletedCache;
		return super.All().filter((te) => !te.deletedWhen);
	}
}

export class GroupTempSubscribable extends SubscribableCollection<ITimeEntrySet> implements IGroupSubscribable {
	constructor(backingStore: Subscriber<string>[], private readonly timeSource:TimeSource) {
		super(backingStore);
	}

	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw `Unable to load time entry set with key '${key}' to remove`;

		item.lastUpdatedWhen = this.timeSource.GetUtcTime();
		item.deletedWhen = this.timeSource.GetLocalTime();
		super.Set(key, item, source);
	}

	public ClearCaches(source?: Source) {
		console.debug(`GroupSubscribable ClearCaches triggered by ${source || ""}`);
		this.AllNotDeletedCache = undefined;
	}

	private AllNotDeletedCache: List<ITimeEntrySet> | undefined;

	public AllNotDeleted(): List<ITimeEntrySet> {
		if (this.AllNotDeletedCache) return this.AllNotDeletedCache;
		return super.All().filter((te) => !te.deletedWhen);
	}
}

export interface IGroupSubscribable extends ISubscribableCollection<ITimeEntrySet> {
	AllNotDeleted(): List<ITimeEntrySet>;
}
