export class AlertContextModel {
	alertComponent?: JSX.Element | JSX.Element[];
	showAlert?: boolean;
	preventClose?: boolean;
	customCloseCallback?: () => boolean; // Checks for return true to account for checks before closing modal
	customClassName?: string;
	constructor(mcm?: Partial<AlertContextModel>) {
		Object.assign(this, mcm);
	}
}
