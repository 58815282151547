import { EndpointManager } from "./EndpointManager";
import { Guid } from "./Guid";
import { User, Auth0ContextInterface } from "@auth0/auth0-react";
import { Instant } from "./Instant";

export class AuthManager {
	token: string | null = null;
	userInfo: IUserInfo | null = null;
	constructor(private readonly em: EndpointManager) { }

	private auth0Obj: Auth0ContextInterface<User> | null = null;
	public setAuth0(auth0Obj: Auth0ContextInterface<User>) {
		console.debug("AuthManager setAuth0", auth0Obj)
		this.auth0Obj = auth0Obj;
	}

	public async GetIntegrations() {
		const newLocal = await this.GetUserInfo();
		const data = newLocal;

		return data?.userIntegration
	}

	private async GetUserInfo() {
		if (this.userInfo) return this.userInfo;
		const token = await this.GetApiToken()
		if (token === null) return null;
		const userinfoResp = await fetch(this.em.AuthApiBase + '/userinfo', { headers: { Authorization: `Bearer ${token}` } });
		const data = await userinfoResp.json() as IUserInfo;
		this.userInfo = data;
		return data;
	}

	public async GetApiToken() {
		if (this.token) return this.token;
		if (this.auth0Obj === null) return null;
		const token = await this.auth0Obj.getAccessTokenWithPopup({
			audience: "https://chronometric.app"
		});
		this.token = token;
		return token;
	}

	public async GetUserId() {
		return (await this.GetUserInfo())?.user_id;
	}
}

export interface ITokenIntegrationModel {
	integrationGuid: Guid;
	integrationUrl: string;
}

export interface IUserInfo {
	user_id: number;
	userIntegration: IUserIntegration[];
}
export interface IUserIntegration {
	userId: number;
	startedWhen: Instant;
	endedWhen?: Instant;

	integration: IIntegration;
}

export interface IIntegration {
	integrationGuid: string;
	organisationGuid: string;
	displayName: string;

}
