import React, { useContext, useRef } from "react";
import { MenuProps } from "./Menu.Interface";
import "./Menu.scss";
import { MenuOption } from "./MenuOption/MenuOption";
import { versionNum } from "../../../version-number";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { useEvent } from "react-use";
import { useForceUpdate } from "../../../Hooks/useForceUpdate";
import { DateTime } from "luxon";
import { useTime } from "../../../Hooks/useTime";
import { ImContext } from "../../../Context/DbContext/DbContext";
import { useAuth0 } from "@auth0/auth0-react";

export const Menu = (props: MenuProps) => {
	const viewContext = useContext(ViewContext);
	const menuRef = useRef(null);
	const im = useContext(ImContext);
	// useOutsideAlerter(menuRef, props.CloseMenu); // Used for when the menu isn't full screen.

	//Just a consistent list of options, the css will entirely determine the look, depending on if it's in the dashboard or the playhead.

	function test() {
		console.log("menu option clicked");
	}

	const {logout} = useAuth0();

	function OpenRoundingSettings() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.RoundingSettingsView,
		});
		props.CloseMenu();
	}

	const forceUpdate = useForceUpdate();
	useEvent("offsetUpdate", forceUpdate);
	const time = useTime();

	return (
		<div className="menu-wrapper" ref={menuRef}>
			<div className="menu-container">
				{/* <MenuOption
					label={globalSettings.state.compactComments ? "Turn on Detailed Comments" : "Turn on Compact Comments"}
					icon="ICON HERE"
					onClick={ToggleCommentsMode}
				/> */}
				<MenuOption label="Settings" icon="ICON HERE" onClick={test} />
				<MenuOption label="Change Rounding Settings" icon="ICON HERE" onClick={OpenRoundingSettings} />
				<MenuOption label="Undo" icon="ICON HERE" onClick={test} />
				<MenuOption label="Redo" icon="ICON HERE" onClick={test} />
				<MenuOption label="Save The Day" icon="ICON HERE" onClick={test} />
				<MenuOption label="About Chronometric" icon="ICON HERE" onClick={test} />
				<MenuOption label="Sign Out" icon="ICON HERE" onClick={logout} />
				<MenuOption label={`Chronometric Version: ${versionNum || ""}`} icon="ICON HERE" onClick={test} />
				<MenuOption
					label={"Current offset: " + im.timeSource.CurrentOffsetMs.toFixed(2) + "ms"}
					icon="ICON HERE"
					onClick={test}
				/>
				<MenuOption
					label={"Current device time: " + DateTime.local().toFormat("HH:mm:ss.SSS")}
					icon="ICON HERE"
					onClick={test}
				/>
				<MenuOption
					label={"Current adjusted time: " + time.toLocal().toFormat("HH:mm:ss.SSS")}
					icon="ICON HERE"
					onClick={test}
				/>
			</div>
		</div>
	);
};
