import { RefObject } from "react";
import { Dictionary } from "../../Data/Dictionary";

export class TreeContextModel {
	public timeEntryRefs?: Dictionary<RefObject<HTMLElement>> | null;
	public activeTaskRef?: RefObject<HTMLElement> | null;

	constructor(mcm?: Partial<TreeContextModel>) {
		Object.assign(this, mcm);
	}
}
