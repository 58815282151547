import { Source } from "../Data/Source";

import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { ITag } from "../Data/Models/ITag";
import { InstanceManager } from "../Data/InstanceManager";
import { useContext } from "react";
import { ImContext } from "../Context/DbContext/DbContext";
export interface IUseTags extends IUseSubscribableCollectionType<ITag> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */

export enum JiraTagTypeCodeNames {
	project = "PROJECT",
	customer = "CUSTOMER", // Not sure if this one is correct
	fixver = "FIXVER",
}

export function useTags(source: Source) {
	const im = useContext(ImContext);
	const subscribable = useSubscribableCollection(im.dataLayer.Tags, source);
	return subscribable;
}
