import { useContext } from "react";
import { ITimeEntry } from "../Data/Models/ITimeEntry";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { TheHook } from "./TheHook/TheHook";
import { KeyHelper } from "../Data/KeyHelper";
import { List } from "immutable";
import { ImContext } from "../Context/DbContext/DbContext";

export const useTimeEntryChecker = (source: string) => {
	const timelineContext = useContext(TimelineContext);
	const theHook = TheHook(source);
	const im = useContext(ImContext);

	// Use to clean up multiple running time entries in emergency. This is only until proper merge logic is implemented
	// useEffect(()=>{
	// 	TimeEntries.AllNotDeleted().forEach((te)=>{
	// 		if(!te.endedWhen){
	// 			theHook.RemoveTimeEntry(te.timeEntryGuid)
	// 		}
	// 	})
	// },[])

	function CheckTodaysEntries() {
		console.log("Checking for forgotten entries");

		const forgottenTimeEntries = CheckForForgottenTimeEntry(
			im.dataLayer.TimeEntries.GetTodaysEntries(timelineContext.state.timelineStartTimeOffsetHours)
		);
		// Do we need to account for multiple potential forgotten entries? There shouldn't be more than 1 timed entry at a time, even across devices.
		if (forgottenTimeEntries.count() > 0) {
			if (forgottenTimeEntries.count() === 1) {
				return forgottenTimeEntries.first(undefined);
			} else {
				throw new Error(
					"Should not be possible to have multiple [" +
					forgottenTimeEntries.count().toString() +
					"] forgotten time entries."
				);
			}
		}

		return undefined;
	}

	// Currently does not check for multiple forgotten time entries.
	// I should not be an issue since there should only ever be one, since not more then one time entry can be recorded at a time. But it would still be good redundancy.
	function CheckForForgottenTimeEntry(myTimeEntries: List<ITimeEntry>): List<ITimeEntry> {
		const forgottenEntries: ITimeEntry[] = [];
		if (myTimeEntries) {
			const startOfDay = im.timeSource
				.GetLocalTime()
				.startOf("day")
				.plus({ hours: timelineContext.state.timelineStartTimeOffsetHours });

			for (const te of myTimeEntries.toArray()) {
				// Only entries that are still running are considered 'forgotten'
				if (!te.endedWhen && te.startedWhen < startOfDay) {
					forgottenEntries.push(te);
				}
			}
		}

		return List(forgottenEntries);
	}

	// 'runover' time entries are ones that have crossed over the designated end of day time without being split.
	async function CheckForRunnoverTimeEntries() {
		const myTimeEntries = im.dataLayer.TimeEntries.AllNotDeleted();

		for (const te of myTimeEntries) {
			const endOfDay = te.startedWhen.endOf("day").plus({ hours: timelineContext.state.timelineStartTimeOffsetHours });

			if (te.endedWhen && te.endedWhen > endOfDay) {
				const group = im.dataLayer.Groups.Get(te.timeEntrySetGuid);

				const endOfOriginalDay = te.startedWhen
					.toLocal()
					.endOf("day")
					.plus({ hours: timelineContext.state.timelineStartTimeOffsetHours });

				const originalTimeEntry: ITimeEntry = {
					...te,
					endedWhen: endOfOriginalDay.toLocal(),
				};

				theHook.SetTimeEntry(originalTimeEntry);

				await theHook.NewTimeEntry(
					endOfDay.plus({ second: 1 }),
					undefined,
					undefined,
					group && group.taskExternalId ? KeyHelper.GetTimeEntrySetTaskKey(group) : undefined
				);
			}
		}
	}

	function GetAllRunningTimeEntries() {
		const runningTimeEntries: ITimeEntry[] = [];
		for (const te of im.dataLayer.TimeEntries.AllNotDeleted().toArray()) {
			// Only entries that are still running are considered 'forgotten'
			if (!te.endedWhen) {
				runningTimeEntries.push(te);
			}
		}

		return runningTimeEntries;
	}

	return {
		CheckTodaysEntries,
		CheckForForgottenTimeEntry,
		CheckForRunnoverTimeEntries,
		GetAllRunningTimeEntries,
	};
};
