import { Source } from "../Data/Source";

import { IIntegrationSetting } from "../Data/Models/IIntegrationSetting";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { useContext } from "react";
import { ImContext } from "../Context/DbContext/DbContext";
export interface IUseIntegrationSettings extends IUseSubscribableCollectionType<IIntegrationSetting> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */

export const DEFAULTROUNDINGINTEVAL = 6 * 60;
export const DEFAULTROUDNINGTHRESHOLD = 2 * 60;

export function useIntegrationSettings(source: Source) {
	const im = useContext(ImContext);
	const subscribable = useSubscribableCollection(im.dataLayer.IntegrationSettings, source);
	return subscribable;
}
