import { Source } from "../Data/Source";

import { useSubscribableCollection, IUseSubscribableCollectionType } from "./useSubscribable";
import { ITimeEntry } from "../Data/Models/ITimeEntry";
import { Guid } from "../Data/Guid";
import { ITimeEntrySubscribable } from "../Data/TimeEntrySubscribable";
import { useContext } from "react";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { List } from "immutable";
import { DateTime } from "luxon";
import { ImContext } from "../Context/DbContext/DbContext";

export interface IUseTimeEntries extends IUseSubscribableCollectionType<ITimeEntry> {
	AllNotDeleted(): List<ITimeEntry>;
	GetTodaysLatestEntry(): ITimeEntry | undefined;
	GetRunningEntry(): ITimeEntry | undefined;
	GetEarliestEntry(): ITimeEntry | undefined;
	GetTodaysEntries(): List<ITimeEntry>;
	GetSetDaysEntries(date: DateTime): List<ITimeEntry>;
	GetForGroup(timeEntrySetGuid: Guid): List<ITimeEntry>;
	GetFirstInGroup(timeEntrySetGuid: Guid): ITimeEntry | undefined;
}

/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTimeEntries(
	source: Source,
	key?: string,
	dataSource?: ITimeEntrySubscribable
): IUseTimeEntries {
	const im = useContext(ImContext);
	const ds = dataSource ?? im.dataLayer.TimeEntries;

	const subscribable = useSubscribableCollection(ds, source, key);
	const timelineContext = useContext(TimelineContext);

	const offsetHours = timelineContext.state.timelineStartTimeOffsetHours || 0;
	return Object.assign(
		{
			// Task-specific functions can be added here
			AllNotDeleted: () => ds.AllNotDeleted(),
			GetRunningEntry: () => ds.GetRunningEntry(offsetHours),
			GetEarliestEntry: () => ds.GetTodaysEarliestEntry(offsetHours),
			GetTodaysEntries: () => ds.GetTodaysEntries(offsetHours),
			GetSetDaysEntries: (date: DateTime) => ds.GetSetDaysEntries(date, offsetHours),
			GetForGroup: (timeEntrySetGuid: Guid) => ds.GetForGroup(timeEntrySetGuid),
			GetTodaysLatestEntry: () => ds.GetTodaysLatestEntry(offsetHours),
			GetFirstInGroup: (timeEntrySetGuid: Guid) => ds.GetFirstInGroup(timeEntrySetGuid),
		},
		subscribable
	);
}
