import { useContext } from "react";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { useTime } from "./useTime";
import { usePlayingTime } from "../Components/Library/Playhead/usePlayingTime";
import { ImContext } from "../Context/DbContext/DbContext";

export function useCalculatedTimes() {
	const { currentTimeEntrySetGUID } = usePlayingTime() || {};
	const timelineContext = useContext(TimelineContext);
	const im = useContext(ImContext);
	useTime();

	const groupTotalTime = currentTimeEntrySetGUID
		? im.timeCalculationHelpers.GetTotalGroupTime(
				currentTimeEntrySetGUID,
				timelineContext.state.timelineStartTimeOffsetHours
		  )
		: undefined;
	const todayTotalTime = im.timeCalculationHelpers.GetTotalDayTime(0, timelineContext.state.timelineStartTimeOffsetHours);
	const weekTotalTime = im.timeCalculationHelpers.GetTotalWeekTime(timelineContext.state.timelineStartTimeOffsetHours);
	return { groupTotalTime, todayTotalTime, weekTotalTime };
}
