import React, { useState, useEffect, useContext, useRef } from "react";
import { GroupExportRecordProps } from "./GroupExportRecord.Interface";
import "./GroupExportRecord.scss";
import { DateTime } from "luxon";
import { ExportContext } from "../../../Context/ExportContext/ExportContext";
import { JiraTagTypeCodeNames } from "../../../Hooks/useTags";
import nameof from "ts-nameof.macro";
import { Timer } from "../Timer/Timer";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { ContextMenu, useContextMenuParams } from "../ContextMenu/ContextMenu";
import { LiveTimeLabelForSets } from "../LiveTimeLabel/LiveTimeLabelForSets";
import { GetRelativeClickCoordinates } from "../../../GlobalUtils/PositionUtils";
import { ContextMenuOrigin } from "../ContextMenu/ContextMenu.interface";
import { useGroups } from "../../../Hooks/useGroups";
import { ITimeEntrySet } from "../../../Data/Models/ITimeEntrySet";
import { formatDuration } from "../LiveTimeLabel/useGroupTime";
import { Instant } from "../../../Data/Instant";
import { ItemSpinner } from "../ItemSpinner";
import { TaskExternalLink } from "../GroupHeader/taskExternalLink";
import { SpecialCause } from "../../../Data/SpecialCause";
import { usePlayingTime } from "../Playhead/usePlayingTime";
import { ImContext } from "../../../Context/DbContext/DbContext";

enum exportingEnum {
	exporting = "EXPORTING",
	exported = "EXPORTED",
	notExporting = "NOT",
}

export const GroupExportRecord = (props: GroupExportRecordProps) => {
	const source = `${nameof(GroupExportRecord)}_${props.MyTimeEntrySetGuid}`;
	const TimeEntrySets = useGroups(source, props.MyTimeEntrySetGuid, [SpecialCause.UpdateLastSyncedWhen]);
	const im = useContext(ImContext);
	// Needs a live value to know when errors arrise.
	// const [liveTimeEntrySet, setLiveTimeEntrySet] = useState<ITimeEntrySet>(props.MyTimeEntrySet)

	const exportContext = useContext(ExportContext);
	// const [inExportList, setInExportList] = useState(false);
	// const [isExporting, setIsExporting] = useState<exportingEnum>(exportingEnum.notExporting);
	const [projectName, setProjectName] = useState("");
	const [customerName, setCustomerName] = useState("");
	const TimeEntries = useTimeEntries(source);
	const timeControls = useTimeControls(source);

	const myTimeEntrySet: ITimeEntrySet | undefined = TimeEntrySets.Get(props.MyTimeEntrySetGuid);

	if (!myTimeEntrySet) throw Error("Unable to get time entry set!");

	const isExporting: exportingEnum = GetExportingStatus();

	const inExportList = exportContext.state.selectedExportRecords.has(props.MyTimeEntrySetGuid);

	const isSyncing =
		myTimeEntrySet &&
		myTimeEntrySet.queuedForExportWhen &&
		myTimeEntrySet.queuedForExportWhen >
			(myTimeEntrySet.lastSyncedWhen || Instant.fromObject({ year: 1970, month: 1, day: 1 }));

	useEffect(() => {
		let project = "";
		let customer = "";

		props.tags.forEach((tag) => {
			// should use an ENUM for this for now.
			if (tag.tagTypeCodeName === JiraTagTypeCodeNames.project) {
				project = tag.value;
			} else if (tag.tagTypeCodeName === JiraTagTypeCodeNames.customer) {
				customer = tag.value;
			}
		});

		setProjectName(project);
		setCustomerName(customer);
	}, [props.tags]);

	// useEffect(()=>{
	// 	console.log("TEST TEST TEST")
	// 	const myTimeEntrySet = TimeEntrySets.Get(myTimeEntrySet.timeEntrySetGuid)
	// 	setLiveTimeEntrySet(myTimeEntrySet ? myTimeEntrySet : myTimeEntrySet)

	// 	setIsExporting(GetExportingStatus());
	// },[TimeEntrySets.Get(myTimeEntrySet.timeEntrySetGuid)?.lastExportErrorOccurredWhen])

	// useEffect(() => {
	// 	setInExportList(CheckIsInSelectedList());
	// }, [exportContext.state.selectedExportRecords]);

	function GetExportingStatus() {
		if (myTimeEntrySet) {
			if (
				myTimeEntrySet.lastExportedWhen &&
				(!myTimeEntrySet.queuedForExportWhen || myTimeEntrySet.lastExportedWhen > myTimeEntrySet.queuedForExportWhen) &&
				(!myTimeEntrySet.lastExportErrorData || myTimeEntrySet.lastExportedWhen > myTimeEntrySet.lastExportedWhen) &&
				myTimeEntrySet.lastExportedWhen >= myTimeEntrySet.lastUpdatedWhen
			) {
				return exportingEnum.exported;
			} else if (
				myTimeEntrySet.queuedForExportWhen &&
				(!myTimeEntrySet.lastExportedWhen || myTimeEntrySet.lastExportedWhen < myTimeEntrySet.queuedForExportWhen) &&
				(!myTimeEntrySet.lastExportErrorOccurredWhen ||
					myTimeEntrySet.lastExportErrorOccurredWhen < myTimeEntrySet.queuedForExportWhen)
			) {
				// Since the export tries a few times after an error, might be worth keeping the exporting up until after the number of attempts has failed.
				return exportingEnum.exporting;
			}
		}
		return exportingEnum.notExporting;
	}

	const { isPlaying } = usePlayingTime() || {};

	function GetGroupsDayOffset() {
		const timeEntry = TimeEntries.GetForGroup(props.MyTimeEntrySetGuid)
			.sortBy((x) => x.startedWhen)
			.first(undefined);

		// Returns -1 if fail since the checks that will use this groups day offset will check every day when given -1
		return timeEntry ? im.timeSource.GetLocalTime().diff(timeEntry.startedWhen, "days").days : -1;
	}

	function GetGroupTotalTime() {
		const dayOffset = GetGroupsDayOffset();
		return timeControls.GetTotalGroupTime(props.MyTimeEntrySetGuid, dayOffset);
	}

	function ToggleSelected() {
		if (myTimeEntrySet) {
			if (inExportList) {
				props.UnselectExportRecord(myTimeEntrySet.timeEntrySetGuid);
			} else {
				props.SelectExportRecord(myTimeEntrySet.timeEntrySetGuid);
			}
		}
	}

	function inputSRText() {
		if (myTimeEntrySet) {
			if (
				isPlaying &&
				props.currentTimeEntry &&
				props.currentTimeEntry.timeEntrySetGuid !== myTimeEntrySet.timeEntrySetGuid
			) {
				return (
					(inExportList ? "Remove '" : "Add '") +
					props.MyTask.name +
					(inExportList ? "' from" : "' to") +
					" export list"
				);
			} else if (
				isPlaying &&
				props.currentTimeEntry &&
				props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid
			) {
				return "Pause running task to enable export";
			}
		}
		return;
	}

	function TogglePlay() {
		return timeControls.TogglePlay();
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams(
		ContextMenuOrigin.TopRight
	);
	const scrollRef = useRef<HTMLDivElement>(null);

	function OpenContextMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();

		if (!scrollRef.current) return;

		const groupTotalTime = formatDuration(GetGroupTotalTime());

		const menuCoords = GetRelativeClickCoordinates(e.nativeEvent, scrollRef.current);
		const menuOptions = [
			{
				display: "Export " + (groupTotalTime !== "" ? "(" + groupTotalTime + ")" : ""),
				callback: ExportGroup,
				disabled:
					isPlaying &&
					myTimeEntrySet &&
					props.currentTimeEntry &&
					props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid,
			},
			{ display: "Jump to day", callback: JumpToDay },
			{ display: "Mark as exported", callback: MarkAsExported },
		];

		setMenuCoords(menuCoords);
		setMenuOptions(menuOptions);
		setShowContextMenu(true);
	}
	function ExportGroup() {
		if (myTimeEntrySet) {
			props.ExportThisGroup(myTimeEntrySet.timeEntrySetGuid);
		}
	}

	function JumpToDay() {
		if (myTimeEntrySet) {
			props.OpenCommentsViewAtDate(myTimeEntrySet.timeEntrySetGuid);
		}
	}

	function MarkAsExported() {
		if (myTimeEntrySet) {
			props.MarkGroupAsExported(myTimeEntrySet.timeEntrySetGuid);
		}
	}

	const spinnerText = isSyncing ? "Queueing..." : isExporting ? "Exporting..." : "This text should never show";

	return (
		<div
			ref={scrollRef}
			className={
				"task-export-record-wrapper " +
				(inExportList ? " selected" : "") +
				(isExporting === exportingEnum.exporting ? " exporting" : "") +
				(isPlaying &&
				props.currentTimeEntry &&
				myTimeEntrySet &&
				props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid
					? " is-playing"
					: "")
			}
		>
			{isExporting === exportingEnum.exporting ? (
				<>
					<div className="spinner-wrapper">
						<ItemSpinner />
						<span className="spinner-label">{spinnerText}</span>
					</div>
				</>
			) : isExporting === exportingEnum.exported ? (
				<div className="exported-wrapper">
					<span className="exported-label">Exported</span>
				</div>
			) : (
				<></>
			)}
			<div className="checkbox-container primary">
				<input
					type="checkbox"
					className="task-record-checkbox"
					id={"input-" + myTimeEntrySet?.timeEntrySetGuid}
					checked={inExportList}
					onChange={ToggleSelected}
					title={inputSRText()}
					disabled={
						isExporting === exportingEnum.exporting ||
						isExporting === exportingEnum.exported ||
						(isPlaying &&
							myTimeEntrySet &&
							props.currentTimeEntry &&
							props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid)
					}
				></input>
				<label htmlFor={"input-" + myTimeEntrySet?.timeEntrySetGuid}>
					<span className="sr-only">{inputSRText()}</span>
				</label>
			</div>
			<div className="task-details-wrapper">
				<div className="task-name task-detail">
					<h2>{props.MyTask.name}</h2>
					<TaskExternalLink task={props.MyTask} />
				</div>

				{isExporting !== exportingEnum.exported && projectName ? (
					<>
						<div className="task-project-name task-detail">
							<span className="icon" title="Project Name"></span>
							{projectName}
						</div>
					</>
				) : (
					<></>
				)}

				{isExporting !== exportingEnum.exported && customerName ? (
					<>
						<div className="task-customer-name task-detail">
							<span className="icon" title="Customer Name"></span>
							{customerName}
						</div>
					</>
				) : (
					<></>
				)}

				<div className="task-total-time">
					{myTimeEntrySet ? (
						isPlaying &&
						myTimeEntrySet &&
						props.currentTimeEntry &&
						props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid ? (
							<>
								<Timer getTimeToDisplay={GetGroupTotalTime} className={"export-record-timer"} />
							</>
						) : (
							<>
								<LiveTimeLabelForSets
									timeEntrySetGuid={myTimeEntrySet.timeEntrySetGuid}
									groupsDayOffset={GetGroupsDayOffset()}
								/>
							</>
						)
					) : (
						<></>
					)}
				</div>

				{isExporting !== exportingEnum.exported && props.comment ? (
					<>
						<div className="task-record-comments">
							<div className="comments-heading-wrapper task-detail">
								<span className="icon" title="Comments"></span>Notes
							</div>
							<p dangerouslySetInnerHTML={{ __html: props.comment.replace(/\n/g, "<br/>") }}></p>
						</div>
					</>
				) : (
					<></>
				)}

				{isExporting !== exportingEnum.exported && myTimeEntrySet && myTimeEntrySet.lastExportErrorOccurredWhen ? (
					<div className="current-entry-warning">
						{myTimeEntrySet.lastExportErrorOccurredWhen.toLocaleString(DateTime.DATE_MED)}
						<br />
						{myTimeEntrySet.lastExportErrorData}
					</div>
				) : (
					<></>
				)}
				{isExporting !== exportingEnum.exported &&
				props.currentTimeEntry &&
				myTimeEntrySet &&
				props.currentTimeEntry.timeEntrySetGuid === myTimeEntrySet.timeEntrySetGuid &&
				isPlaying ? (
					<div className="warning current-entry-warning">
						<span className="warning-icon"></span>
						<span className="warning-label">Pause timer to enable export </span>
						<button onClick={TogglePlay} className="secondary pill outline sz-1" title="Pause task">
							<span className="icon"></span>Pause
						</button>
					</div>
				) : (
					<></>
				)}
			</div>
			<button
				className="time-entry-context-menu"
				onClick={OpenContextMenu}
				disabled={!(isExporting === exportingEnum.notExporting)}
			>
				...
			</button>
			{ContextMenu(contextMenuParams)}
		</div>
	);
};
