import React, { useContext } from "react";
import "./Modal.scss";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";

enum showHideClassName {
	showModal = "modal display-block",
	hideModal = "modal display-none",
}

export const Modal = () => {
	const modalContext = useContext(ModalContext);

	function CloseModal() {
		if (!modalContext.state.preventClose) {
			if (modalContext.state.customCloseCallback) {
				if (modalContext.state.customCloseCallback()) {
					CleanupModalContext();
				}
			} else {
				CleanupModalContext();
			}
		}
	}

	function CleanupModalContext() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.CloseModal,
			payload: undefined,
		});
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomCloseCallback,
			payload: undefined,
		});
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomClassName,
			payload: "",
		});
	}

	return (
		// TO DO - Implement ability to add variable classname to modal wrapper
		<div
			className={`${modalContext.state.showModal ? showHideClassName.showModal : showHideClassName.hideModal} ${
				modalContext.state.customClassName || ""
			}`}
		>
			<div className="click-off-close" onClick={CloseModal} />
			<section className="modal-main-wrapper">
				<section className="modal-main-container">
					{modalContext.state.modalComponent}
					<button className="close-button icon-only circle sz-1" title="Close modal" onClick={CloseModal}>
						<span className="sr-only">Close modal</span>
					</button>
				</section>
			</section>
		</div>
	);
};
