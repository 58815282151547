import React from "react";
import { CommentsSummaryProps } from "./CommentsSummary.Interface";
import "./CommentsSummary.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { DateTime } from "luxon";
import nameof from "ts-nameof.macro";
import { TimeEntryComment } from "../TimeEntryComment/TimeEntryComment";
import { List } from "immutable";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";

export const CommentsSummary = (props: CommentsSummaryProps) => {
	const source = `${nameof(CommentsSummary)}_${props.TimeEntrySetGuid || ""}`;
	const TimeEntries = useTimeEntries(source);

	function FormatTime(time?: DateTime) {
		return time ? time.toLocaleString(DateTime.TIME_SIMPLE) : "NOW";
	}

	return (
		<section className="group-comments-summary" ref={props.ref}>
			{(props.TimeEntrySetGuid
				? TimeEntries.GetForGroup(props.TimeEntrySetGuid).sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
				: List<ITimeEntry>()
			).map((timeEntry) => {
				return (
					<>
						<div className="time-entry-wrapper" key={`Time-Entry-Comment-${timeEntry.timeEntryGuid || ""}`}>
							<button className="time-wrapper">
								<div className={"start-end-time"}>
									<span className="start-time">
										{FormatTime(timeEntry.startedWhen)} - {FormatTime(timeEntry.endedWhen)}
									</span>
								</div>
							</button>
							<TimeEntryComment MyTimeEntry={timeEntry} editable={true} />
						</div>
					</>
				);
			})}
		</section>
	);
};
