import React, { ChangeEvent, HTMLAttributes } from "react";
import TextareaAutosize from "react-textarea-autosize";

export enum FieldTypes {
	input = "input",
	textarea = "textarea",
}

interface FieldProps extends HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	fieldType?: FieldTypes;
	label?: string;
	name: string;
	value?: string;
	errors: string[];
	setErrors: (errors: string[]) => void;
	pristine: boolean;
	validating: boolean;
	validate: () => void;
	formSubmitted: boolean;
	type?: string;
	disabled?: boolean;
}

export function Field({
	fieldType = FieldTypes.input,
	label,
	name,
	value,
	errors,
	setErrors,
	pristine,
	validating,
	validate,
	formSubmitted,
	placeholder,
	type,
	disabled,
	...other
}: FieldProps) {
	const showErrors = (!pristine || formSubmitted) && !!errors.length;

	// TODO: Make ctrl+enter optional
	function HandleKeyPress(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
		if (e.ctrlKey && e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			(e.target as HTMLTextAreaElement).form!.dispatchEvent(new Event("submit", { bubbles: false, cancelable: true }));
		}
	}

	return (
		<div className="field">
			<label htmlFor={name}>{label}</label>
			{fieldType && fieldType === FieldTypes.textarea ? (
				<TextareaAutosize
					id={name}
					placeholder={placeholder ? placeholder : ""}
					value={value}
					onBlur={() => !pristine && validate()}
					onKeyPress={HandleKeyPress}
					disabled={disabled}
					{...other}
				/>
			) : (
				<input
					id={name}
					value={value}
					onBlur={() => !pristine && validate()}
					type={type}
					disabled={disabled}
					{...other}
					onKeyPress={HandleKeyPress}
				/>
			)}
			<div>{showErrors && errors.map((errorMsg: string) => <div key={errorMsg}>{errorMsg}</div>)}</div>
		</div>
	);
}
