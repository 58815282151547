import React, { useContext, Fragment } from "react";
import "./Timescale.scss";
import { TimelineContext, defaultScale, END_OF_DAY_BUFFER_HOURS } from "../../../Context/TimelineContext/TimelineContext";
import { useTime } from "../../../Hooks/useTime";
import { Range } from "immutable";
import { CalcTimePosition } from "../../../GlobalUtils/TimeUtils";
import { DateTime } from "luxon";

export function Timescale() {
	const timelineContext = useContext(TimelineContext);
	const currentTime = useTime();

	const firstHourToDisplay = timelineContext.state.timelineStartTimeOffsetHours || 0;
	const lastHourToDisplay =
		timelineContext.state.currentDayOffset === 0
			? currentTime.hour - firstHourToDisplay + END_OF_DAY_BUFFER_HOURS
			: firstHourToDisplay + 24;

	const scale = timelineContext.state.currentScale ? timelineContext.state.currentScale : defaultScale;
	const dayStart = currentTime.startOf("day");

	return (
		<section className="time-indicators-wrapper">
			<section className="hours-label-container">
				{Range(firstHourToDisplay, lastHourToDisplay).map((hour) => (
					<Fragment key={hour}>
						<span
							className="hour"
							style={{
								top: `${CalcTimePosition(dayStart.plus({ hours: hour }), firstHourToDisplay, scale, 0)}px`,
							}}
						/>
						<span
							className="hour-label"
							style={{
								top: `${CalcTimePosition(dayStart.plus({ hours: hour }), firstHourToDisplay, scale, 0)}px`,
							}}
						>
							{dayStart.plus({ hours: hour }).toLocaleString(DateTime.TIME_SIMPLE)}
						</span>
						<span
							className="quarter-hour"
							style={{
								top: `${CalcTimePosition(dayStart.plus({ hours: hour, minutes: 15 }), firstHourToDisplay, scale, 0)}px`,
							}}
						/>
						<span
							className="half-hour"
							style={{
								top: `${CalcTimePosition(dayStart.plus({ hours: hour, minutes: 30 }), firstHourToDisplay, scale, 0)}px`,
							}}
						/>
						<span
							className="quarter-hour"
							style={{
								top: `${CalcTimePosition(dayStart.plus({ hours: hour, minutes: 45 }), firstHourToDisplay, scale, 0)}px`,
							}}
						/>
					</Fragment>
				))}
			</section>
		</section>
	);
}
