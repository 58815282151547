import { Source } from "../Data/Source";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { ITaskTagLink } from "../Data/Models/ITaskTagLink";
import { InstanceManager } from "../Data/InstanceManager";
import { ImContext } from "../Context/DbContext/DbContext";
import { useContext } from "react";
export interface IUseTaskTagLinks extends IUseSubscribableCollectionType<ITaskTagLink> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTaskTagLinks(source: Source) {
	const im = useContext(ImContext);
	const subscribable = useSubscribableCollection(im.dataLayer.TaskTagLinks, source);
	return subscribable;
}
