import React, { useState } from "react";
import { GroupNotesDropdownProps } from "./GroupNotesDropdown.Interface";
import "./GroupNotesDropdown.scss";
import { GroupSummary } from "../GroupSummary/GroupSummary";
import nameof from "ts-nameof.macro";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { KeyHelper } from "../../../Data/KeyHelper";
import { useTasks } from "../../../Hooks/useTasks";
import { GroupHeader } from "../GroupHeader/GroupHeader";
import { TheHook } from "../../../Hooks/TheHook/TheHook";

export function GroupNotesDropdown(props: GroupNotesDropdownProps) {
	const source = `${nameof(GroupNotesDropdown)}_${props.myGroup.timeEntrySetGuid}`;
	const theHook = TheHook(source);
	const Tasks = useTasks(source, KeyHelper.GetTimeEntrySetTaskKey(props.myGroup));
	const TimeEntries = useTimeEntries(source, props.myGroup.timeEntrySetGuid);
	const [showGroupSummary, setShowGroupSummary] = useState<boolean>(false);

	function ToggleShowGroupSummary() {
		setShowGroupSummary(!showGroupSummary);
	}

	function GetTask() {
		return Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(props.myGroup));
	}

	function GetTimeEntries() {
		return TimeEntries.GetForGroup(props.myGroup.timeEntrySetGuid);
	}

	return (
		<>
			<div className="orderby-group-header-wrapper">
				<GroupHeader
					MyTask={GetTask()}
					MyGroup={props.myGroup}
					theHook={theHook}
					consecutiveTimeEntries={[GetTimeEntries().toArray()]}
				/>
				<button
					className={"expand-time-entries " + (showGroupSummary ? "expanded" : "collapsed")} // TODO: button should be blue when group is linked, and orange when unlinked
					onClick={ToggleShowGroupSummary}
					title={showGroupSummary ? "Hide time entries" : "Show time entries"}
					aria-label={showGroupSummary ? "Hide time entries" : "Show time entries"}
				></button>
			</div>
			{showGroupSummary ? (
				<GroupSummary
					TimeEntrySetGuid={props.myGroup.timeEntrySetGuid}
					DayOffset={props.DayOffset}
					hideHeaders={true}
				/>
			) : (
				<></>
			)}
		</>
	);
}
