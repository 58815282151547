import React, { useContext } from "react";
import "./AlertBox.scss";
import { AlertBoxProps } from "./AlertBox.Interface";
import { AlertContext } from "../../../Context/AlertContext/AlertContext";
import { AlertContextDispatchActionType } from "../../../Context/AlertContext/AlertContextDispatchActionType";


enum showHideClassName {
	showAlert = "alert-box display-block",
	hideAlert = "alert-box display-none",
}

export const AlertBox = () => {
	const alertContext = useContext(AlertContext);

	function CloseAlert() {
		if (!alertContext.state.preventClose) {
			if (alertContext.state.customCloseCallback) {
				if (alertContext.state.customCloseCallback()) {
					// A positive customCloseCallback return triggers the defaul close in addition
					alertContext.dispatch({
						type: AlertContextDispatchActionType.CloseAlert,
						payload: undefined,
					});
				}
			} else {
				alertContext.dispatch({
					type: AlertContextDispatchActionType.CloseAlert,
					payload: undefined,
				});
			}
		}
	}

	return (
		<div
			className={`${alertContext.state.showAlert ? showHideClassName.showAlert : showHideClassName.hideAlert} ${alertContext.state.customClassName || ""}`}
		>
			<div className="alert-box-background" />
			<section className="alert-box-main-wrapper">
				<section className="alert-box-main-container">
					<section className="alert-box-content-wrapper" >
						{alertContext.state.alertComponent}
					</ section >
					<button className="close-button icon-only circle sz-1" title="Close alert-box" onClick={CloseAlert}>
						<span className="sr-only">Close alert-box</span>
					</button>
				</section>
			</section>
		</div>
	);
};
