import { ITask } from "./Models/ITask";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ISearchResult } from "./Models/ISearchResult";
import { ITag } from "./Models/ITag";
import { ISearchResultTag } from "./Models/ISearchResultTag";
import { Guid } from "./Guid";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { ITaskMetadata } from "./Models/ITaskMetadata";

export class KeyHelper {
	public static GetTaskKey(task: ITask | ISearchResult | ITaskMetadata): string {
		return `${task.integrationGuid}|${task.externalId}`;
	}
	public static GetTaskTagLinkTaskKey(task: ITaskTagLink): string {
		return `${task.integrationGuid}|${task.taskExternalId}`;
	}
	public static GetSearchResultTaskKey(result: ISearchResult): string {
		return KeyHelper.GetTaskKey(result);
	}
	public static GetTimeEntrySetTaskKey(timeEntrySet: ITimeEntrySet): string {
		return `${timeEntrySet.taskIntegrationGuid || ""}|${timeEntrySet.taskExternalId || ""}`;
	}
	public static GetTagKey(tag: ITag): string {
		return `${tag.integrationGuid}|${tag.externalId}`;
	}
	public static GetSearchResultTagKey(result: ISearchResultTag, integrationGuid: Guid): string {
		return `${integrationGuid}|${result.externalId}`;
	}
	public static GetTaskTagLinkKey(result: ITaskTagLink): string {
		return `${result.integrationGuid}|${result.taskExternalId}|${result.tagExternalId}`;
	}
	public static GetTagKeyFromTaskTagLink(taskTagLink: ITaskTagLink): string {
		return `${taskTagLink.integrationGuid}|${taskTagLink.tagExternalId}`;
	}
}

export class DbKeyHelper {
	public static GetTaskKey(task: ITask | ISearchResult | ITaskMetadata): [Guid, string] {
		return [task.integrationGuid, task.externalId];
	}
}
